import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PB, {SimplePB} from '@/libs/simplePB';

import {getNodeDisplayTitle, myVis} from '@/constants/vis.defaultDefine.1';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import MicroServiceDataProvider from '@/components/common/dataProvider/common.dataProvider.microService';
import {MicroServiceUIConfig} from '@/components/common/view/microService/shape/common.view.microService.shape.uiConfig';
import { isNull } from 'lodash';

/**
 * @description 微服务操作目标数据项
 * @typedef {Object} TMicroServiceTargetItem
 * @property {any} value - 操作目标值
 */

/**
 * @description 微服务操作目标数据
 * @typedef {Object.<string, TMicroServiceTargetItem>} TMicroServiceTarget
 * @property {TMicroServiceTargetItem} $target - 操作目标最终数据
 * @property {TMicroServiceTargetItem} $type - 操作目标类型
 * @property {TMicroServiceTargetItem} $param - 操作目标动态处理参数
 */

/**
 * @description 微服务请求状态数据
 * @typedef {Object} TMicroServiceRequestStatus
 * @property {boolean} autoLoadMore - 是否自动发起下一次请求
 * @property {boolean} loading - 是否正在请求中
 * @property {boolean} lastLoadingFailed - 上次请求是否失败
 * @property {boolean} hasMoreResult - 上次请求完毕后，是否还有更多数据可以获取
 * @property {number} targetPos - 即将或正在发起的请求所提交的操作目标在操作目标列表中的位置
 * @property {number} start - 即将或正在发起的请求所提交的操作结果起始位置
 */

/**
 * @description 微服务结果对象状态数据
 * @typedef {Object} TMicroServiceResponseObjectStatus
 * @property {string} type - 对象对应的动作类型
 * @property {string} [operation] - 对象对应的操作类型
 * @property {'idle'|'selected'|'saved'|'invalid'} status - 对象状态
 */

/**
 * @description 微服务结果统计汇总数据
 * @typedef {Object} TMicroServiceResponseStatisticsSummary
 * @property {number} total - 总数
 * @property {number} idle - 未勾选数量
 * @property {number} selected - 已勾选数量
 * @property {number} saved - 已保存数量
 * @property {number} invalid - 已失效数量
 */

/**
 * @description 微服务按操作处理后的结果数据
 * @typedef {Object} TMicroServiceResponseDataByOperation
 * @property {string} status - 处理状态
 * @property {any} data - 处理结果
 */

/**
 * @description 微服务结果统计数据
 * @typedef {Object} TMicroServiceResponseStatistics
 * @property {Object.<string, string[]>} objectIdListByOperation - 按操作归类的对象ID列表
 * @property {Object.<string, TMicroServiceResponseObjectStatus>} objectStatus - 对象状态数据
 * @property {Object.<string, TMicroServiceResponseStatisticsSummary>} statisticsByType - 按类型的统计汇总
 */

/**
 * @description 微服务主数据
 * @typedef {Object} TMicroServiceData
 * @property {boolean} locked - 操作目标及参数是否锁定
 * @property {TMicroServiceTarget} targetMap - 操作目标数据
 * @property {Object} parameterMap - 操作参数数据
 * @property {TMicroServiceRequestStatus} requestStatus - 请求状态数据
 * @property {TMicroServiceResponseStatistics} responseStatistics - 响应统计数据
 */

/**
 * @description 获取微服务初始化数据
 *
 * @return {TMicroServiceData}
 */
const getInitialServiceData = () => ({
  locked: false,

  ui: {},

  targetMap: {},
  parameterMap: {},

  requestStatus: {
    autoLoadMore: false,
    loading: false,
    lastLoadingFailed: false,
    hasMoreResult: false,

    targetPos: 0,
    start: 0,
  },

  responseStatistics: {
    objectIdListByOperation: {},
    objectStatus: {},
    statisticsByType: {},
  },
});

const filterFnParam = {
  getNodeType: myVis.getNodeTypeFn,
};

const theDayBeforeYesterdayMoment  = moment().subtract(2, 'd');
const yesterdayMoment = moment().subtract(1, 'd');
const todayMoment = moment();
const theDayBeforeYesterday = theDayBeforeYesterdayMoment.format('YYYY-MM-DD');
const yesterday = yesterdayMoment.format('YYYY-MM-DD');
const today = todayMoment.format('YYYY-MM-DD');
let idMap ={};

const customFilterEx = (node, customFilter,custom) => {
  let filterString = customFilter.toString();
  let nodeTime = moment(node.updateTime || node['createTime'], 'YYYY-MM-DD HH:mm:ss')
            .format('YYYY-MM-DD');
  return filterString.includes('individual') && idMap['individual'] && Object.keys(idMap['individual']).includes(node.id) || 
    filterString.includes('connectedToMine') && idMap['connectedToMine'] && Object.keys(idMap['connectedToMine']).includes(node.id) || 
    filterString.includes('selected') && idMap['selected'] && Object.keys(idMap['selected']).includes(node.id) || 
    filterString.includes('today') && nodeTime===today || filterString.includes('yesterday') && nodeTime===yesterday || filterString.includes('dby') && nodeTime===theDayBeforeYesterday;
}

const nodeFilterFn = (node, customFilter) => !customFilter || customFilter(node, filterFnParam) === true || customFilterEx(node, customFilter,customFilter(node, filterFnParam));

class MicroServiceLogicCall extends React.PureComponent {
  /**
   * 微服务数据
   *
   * @type {Object.<string, TMicroServiceData>}
   */
  serviceDataMap = {};

  serviceTargetBackgroundUpdaterMap = {};

  /**
   * @description 配置列表加载并解析完成后回调，增加初始数据
   * @param {string} projectId 项目ID
   * @param {TMicroServiceUIConfigWithId[]} configList
   */
  onConfigListLoaded = (projectId, configList) => {
    let me = this;

    (configList || []).forEach(config => {
      me.serviceDataMap[config.id] = getInitialServiceData();
      config.operations.forEach(op => {
        me.serviceDataMap[config.id].responseStatistics.objectIdListByOperation[op.type] = [];
      });
    });
    me.props.bus.emit('relation', 'micro_service.node.get', {viewId:me.props.viewDataProvider.viewId});
  };

  onLoadMoreData = (serviceId, dataType, serviceInfo) => {
    let me = this, serviceData = me.serviceDataMap[serviceId]||{requestStatus:{},targetMap:{}};
    if ((serviceData.requestStatus.loading || !me.props.viewDataProvider) && dataType !== 1) {
      return;
    }

    let viewId = me.props.viewDataProvider.viewId,
      /** @type {TMicroServiceUIConfig} */ config = me.props.microServiceConfigMap[serviceId],
      target = dataType !== 1 && serviceData.targetMap && serviceData.targetMap.$target && serviceData.targetMap.$target.value||undefined,
      targetStart = serviceData.requestStatus.targetPos||0,
      targetLimit = dataType !== 1 && config.request.targetLimit || 10,
      start = dataType !== 1 && serviceData.requestStatus.start||0,
      limit = dataType !== 1 && config.request.resultLimit || 100,
      popularity = 1,
      hasMoreTarget = false,
      requestTarget = {};

    /*
     * 支持更多种类目标时，这里需要同步修改
     */
    if (['nodes', 'textOrUrls', 'files'].findIndex(key => {
      if (dataType !== 1 && target && target[key]) {
        requestTarget[key] = target[key].slice(targetStart, targetStart + targetLimit);
        popularity = requestTarget[key].length;
        hasMoreTarget = targetStart + targetLimit < target[key].length;
        if(key == 'nodes' && target['node_list'] && target['node_list'].length>0){
          requestTarget['nodes'] = target['node_list'].slice(targetStart, targetStart + targetLimit);
        }
        return true;
      }
    }) >= 0) {
      // nodes、textOrUrls、files已处理
    } else if (dataType !== 1 && target && target['viewId']) {
      requestTarget.viewId = target['viewId'];
      popularity = 20;
      hasMoreTarget = false;
    }

    me.props.bus.emit('view', 'micro_service.service.do_load_more',
      {start, limit, viewId, serviceId, autoLoadMore: serviceData.requestStatus.autoLoadMore});
    serviceData.requestStatus.loading = true;
    serviceData.locked = true;
    let loadFn;
    if(dataType===1){
      let nodeIdList = serviceInfo.content.nodeIds; //.slice(0,100)
      loadFn = () => MicroServiceDataProvider.getRelationNodeListByIds(serviceInfo.viewId, nodeIdList);
    }else{
      loadFn = () => MicroServiceDataProvider.callByView(
        serviceId, viewId, {target: requestTarget, parameters: serviceData.parameterMap, start, limit, popularity,version:serviceInfo.version,serviceInfo}
      )
    }

    loadFn().then(({data, hasMore}) => {
      if(dataType===1){
        let _data = [], edges = [];
        if (me.props.currentNodeId) {
          //let node_0 = me.props.viewDataProvider.getNode(me.props.currentNodeId);
          let node = {
            id:serviceInfo.id,
            fname:serviceInfo.title,
            description:serviceInfo.description
          }
          data.forEach((d, idx) => {
            edges.push({
              "userConfirmed":true,
              "version":"E07",
              "meta":{
                  "userMarkedInvisible":1
              },
              "fromIndex":0,
              "toIndex":idx+1
            });
          });
          _data.push({
            id:me.props.currentNodeId,
            type:'node',
            msg: null,
            msgStatus: 0,
            calcMsg: null,
            calcStatus: 0,
            //info:{...node},
            //nodes:data,
            //edges:[{from:me.props.currentNodeId,to:serviceInfo.id}]
            graphs:[{
              id: null,
              nodes:[node,...data],
              edges:edges
            }]
          });
        }else{
          let node = {
            id:serviceInfo.id,
            fname:serviceInfo.title,
            description:serviceInfo.description
          }
          _data.push({
            id:serviceInfo.id,
            type:'node',
            msg: null,
            msgStatus: 0,
            calcMsg: null,
            calcStatus: 0,
            info:{...node},
            nodes:data
          });
          /*
          data.forEach((d, idx) => {
            d.fixed = false;
            _data.push({
              id:d.id,
              type:'node',
              msg: null,
              msgStatus: 0,
              calcMsg: null,
              calcStatus: 0,
              info:{...d},
              //nodes:data
            });
          });
          */
        }
        data = _data;
        hasMore = false;
      }
      serviceData.requestStatus.start += limit;
      serviceData.requestStatus.hasMoreResult = (hasMore === true);
      if (!serviceData.requestStatus.hasMoreResult) {
        // 当前目标已无新数据，检查是否还有新的目标可以计算
        serviceData.requestStatus.targetPos += targetLimit;
        serviceData.requestStatus.start = 0;
        serviceData.requestStatus.hasMoreResult = hasMoreTarget;
      }
      serviceData.requestStatus.loading = false;
      serviceData.requestStatus.lastLoadingFailed = false;
      me.props.bus.emit('view', 'micro_service.service.raw_data_loaded',
        {data, start, limit, autoLoadMore: serviceData.requestStatus.autoLoadMore, hasMoreResult: serviceData.requestStatus.hasMoreResult,
          viewId, serviceId, dataType});
    }).catch(({code, msg}) => {
      serviceData.requestStatus.loading = false;
      serviceData.requestStatus.lastLoadingFailed = true;
      me.props.bus.emit('view', 'micro_service.service.failed_to_load',
        {code, msg, viewId, serviceId});
    });
  };

  clearResult = serviceId => {
    let me = this, serviceData = me.serviceDataMap[serviceId],
      /** @type {TMicroServiceUIConfig} */ config = me.props.microServiceConfigMap[serviceId];

    if (serviceData.requestStatus.loading || !me.props.viewDataProvider) {
      return;
    }

    let viewId = me.props.viewDataProvider.viewId;

    serviceData.locked = false;

    serviceData.ui = {};

    serviceData.requestStatus.autoLoadMore = false;
    serviceData.requestStatus.loading = false;
    serviceData.requestStatus.lastLoadingFailed = false;
    serviceData.requestStatus.hasMoreResult = false;
    serviceData.requestStatus.targetPos = 0;
    serviceData.requestStatus.start = 0;

    config.operations.forEach(op => {
      serviceData.responseStatistics.objectIdListByOperation[op.type] = [];
    });

    serviceData.responseStatistics.objectStatus = {};
    serviceData.responseStatistics.statisticsByType = {};

    me.props.bus.emit('view', 'micro_service.service.result_cleared', {viewId, serviceId});
  };

  resetTarget = serviceId => {
    let me = this, targetMap = me.serviceDataMap[serviceId].targetMap, targetParam = {},
      /** @type {TMicroServiceUIConfig} */ config = me.props.microServiceConfigMap[serviceId];

    targetMap.$type = {value: undefined};
    targetMap.$param = {value: targetParam};
    targetMap.$target = {value: undefined};

    if (config.target.items.find(i => i.type === 'view')) {
      let viewInfo = me.props.viewDataProvider.viewInfo, viewId = me.props.viewDataProvider.viewId;
      if (viewInfo) {
        targetParam.view = {
          displayTitle6: viewInfo.name.length > 6 ? `${viewInfo.name}`.substr(0, 5) + '...' : viewInfo.name,
          viewId,
          viewName: viewInfo.name,
        };
        if ((config.target.default || config.target.items[0].type) === 'view') {
          targetMap.$type.value = 'view';
          targetMap.$target.value = {viewId};
        }
      }
    }

    if (config.target.items.find(i => i.type === 'node') && me.props.currentNodeId) {
      let node = me.props.viewDataProvider.getNode(me.props.currentNodeId);
      if (node) {
        targetParam.node = {
          displayTitle6: getNodeDisplayTitle(node, 6),
        };
        if ((config.target.default || config.target.items[0].type) === 'node') {
          targetMap.$type.value = 'node';
          targetMap.$target.value = {nodes: [me.props.currentNodeId],node_list:[node]};
        }
      }
    }

    let filteredNodesCfg = config.target.items.find(i => i.type === 'filteredNodes');
    if (filteredNodesCfg) {
      let allNodes = me.props.viewDataProvider.getNode();

      let nodes = me.props.viewDataProvider.getNode({
        filter: node => nodeFilterFn(node, filteredNodesCfg.filter),
      });
      targetParam.filteredNodes = {
        ids: nodes.map(n => n.id),
        nodesLength: nodes.length,
      };
      if ((config.target.default || config.target.items[0].type) === 'filteredNodes' || targetMap.$type.value === undefined) {
        targetMap.$type.value = 'filteredNodes';
        targetMap.$target.value = {nodes: [...targetParam.filteredNodes.ids],node_list:nodes};
      }
    }

    if (config.target.items.find(i => i.type === 'textOrUrl')) {
      targetParam.textOrUrl = {};
      targetMap.textOrUrl = {value: ''};
      if ((config.target.default || config.target.items[0].type) === 'textOrUrl' || targetMap.$type.value === undefined) {
        targetMap.$type.value = 'textOrUrl';
        targetMap.$target.value = {textOrUrls: ['']};
      }
    }

    if (config.target.items.find(i => i.type === 'files')) {
      targetParam.files = {};
      targetMap.files = {fileList: [], fileUploadSource: {}, fileInfoMap: {}, value: []};
      if ((config.target.default || config.target.items[0].type) === 'files' || targetMap.$type.value === undefined) {
        targetMap.$type.value = 'files';
        targetMap.$target.value = {files: []};
      }
    }
  };

  onResponseStatisticsByOperationUpdated = (serviceId, operation, objectIds, objectStatus, updatedObjectStatus) => {
    let me = this, serviceData = me.serviceDataMap[serviceId];

    if (objectIds !== undefined) {
      serviceData.responseStatistics.objectIdListByOperation[operation] = [...objectIds];
    }

    if (objectStatus !== undefined) {
      Object.keys(serviceData.responseStatistics.objectStatus).forEach(id => {
        if (serviceData.responseStatistics.objectStatus[id].operation === operation) {
          delete serviceData.responseStatistics.objectStatus[id];
        }
      });
      Object.keys(objectStatus).forEach(id => {
        serviceData.responseStatistics.objectStatus[id] = {
          status: objectStatus[id].status,
          type: objectStatus[id].type,
          operation,
        };
      });
    } else if (updatedObjectStatus !== undefined) {
      Object.keys(updatedObjectStatus).forEach(id => {
        if (!serviceData.responseStatistics.objectStatus[id]) {
          serviceData.responseStatistics.objectStatus[id] = {
            status: updatedObjectStatus[id].status,
            type: updatedObjectStatus[id].type,
            operation,
          };
        } else {
          serviceData.responseStatistics.objectStatus[id].status = updatedObjectStatus[id].status;
          serviceData.responseStatistics.objectStatus[id].type = updatedObjectStatus[id].type;
        }
      });
    }

    serviceData.responseStatistics.statisticsByType = {};
    Object.values(serviceData.responseStatistics.objectStatus).forEach(statusData => {
      if (statusData.type && statusData.status) {
        if (!serviceData.responseStatistics.statisticsByType[statusData.type]) {
          serviceData.responseStatistics.statisticsByType[statusData.type] = {
            total: 0,
            idle: 0,
            selected: 0,
            saved: 0,
            invalid: 0,
          };
        }
        if (serviceData.responseStatistics.statisticsByType[statusData.type][statusData.status] !== undefined) {
          serviceData.responseStatistics.statisticsByType[statusData.type].total++;
          serviceData.responseStatistics.statisticsByType[statusData.type][statusData.status]++;
        }
      }
    });

    me.props.bus.emit('view', 'micro_service.service.broadcast_statistics',
      {viewId: me.props.viewDataProvider.viewId, serviceId});
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(
      me
    ).subscribe('view', 'micro_service.config.data_loaded', ({projectId, configList}) => {
      me.onConfigListLoaded(projectId, configList);
    }).subscribe('view', 'micro_service.service.on_load_more', ({viewId, serviceId, autoLoadMore = true, dataType, serviceInfo}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && ((me.serviceDataMap[serviceId] && dataType!==1) || dataType===1)) {
        if(dataType!==1){
          me.serviceDataMap[serviceId].requestStatus.autoLoadMore = autoLoadMore;
          if (!me.serviceDataMap[serviceId].locked) {
            me.serviceDataMap[serviceId].locked = true;
          }
        }
        me.props.bus.emit('view', 'micro_service.service.broadcast_data', {viewId, serviceId});
        me.onLoadMoreData(serviceId, dataType, serviceInfo);
      }
    }).subscribe('view', 'micro_service.service.clear_result', ({viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.clearResult(serviceId);
      }
    }).subscribe('view', 'micro_service.service.stop_auto_load', ({viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.serviceDataMap[serviceId].requestStatus.autoLoadMore = false;
        me.props.bus.emit('view', 'micro_service.service.broadcast_data', {viewId, serviceId});
      }
    }).subscribe('view', 'micro_service.service.raw_data_loaded', ({viewId, serviceId, dataType, serviceInfo}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        if (me.serviceDataMap[serviceId].requestStatus.autoLoadMore
          && me.serviceDataMap[serviceId].requestStatus.hasMoreResult) {

          requestAnimationFrame(() => me.onLoadMoreData(serviceId, dataType, serviceInfo));
        }
      }
    }).subscribe('view', 'micro_service.service.ui.set', ({viewId, serviceId, key, value}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.serviceDataMap[serviceId].ui[key] = value;
      }
    }).subscribe('view', 'micro_service.service.target.set', ({viewId, serviceId, name, key, value}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        if (me.serviceDataMap[serviceId].locked) return;
        me.serviceDataMap[serviceId].targetMap[name] = me.serviceDataMap[serviceId].targetMap[name] || {};
        me.serviceDataMap[serviceId].targetMap[name][key] = value;
      }
    }).subscribe('view', 'micro_service.service.parameter.set', ({viewId, serviceId, name, key, value}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        if (me.serviceDataMap[serviceId].locked) return;
        me.serviceDataMap[serviceId].parameterMap[name] = me.serviceDataMap[serviceId].parameterMap[name] || {};
        me.serviceDataMap[serviceId].parameterMap[name][key] = value;
      }
    }).subscribe('view', 'micro_service.service.current_operation_statistics', ({viewId, serviceId, operation, objectIds, objectStatus}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onResponseStatisticsByOperationUpdated(serviceId, operation, objectIds, objectStatus);
      }
    }).subscribe('view', 'micro_service.service.operation_statistics_updated', ({viewId, serviceId, operation, updatedObjectStatus}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onResponseStatisticsByOperationUpdated(serviceId, operation, undefined, undefined, updatedObjectStatus);
      }
    }).subscribe('view', 'micro_service.service.broadcast_data', ({viewId, serviceId, preferResetTarget}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        // 当locked为false时，处理targets
        if (!me.serviceDataMap[serviceId].locked && preferResetTarget) {
          me.resetTarget(serviceId);
          me.serviceDataMap[serviceId].parameterMap = {};
          me.serviceDataMap[serviceId].ui = {};
        }
        me.props.bus.emit('view', 'micro_service.service.current_data', {
          viewId,
          serviceId,
          ui: {...me.serviceDataMap[serviceId].ui},
          targets: {...me.serviceDataMap[serviceId].targetMap},
          parameters: {...me.serviceDataMap[serviceId].parameterMap},
          locked: me.serviceDataMap[serviceId].locked,
          currentPos: me.serviceDataMap[serviceId].requestStatus.start,
          hasMoreResult: me.serviceDataMap[serviceId].requestStatus.hasMoreResult,
          loadingResult: me.serviceDataMap[serviceId].requestStatus.loading,
          lastLoadingFailed: me.serviceDataMap[serviceId].requestStatus.lastLoadingFailed,
          autoLoadMore: me.serviceDataMap[serviceId].requestStatus.autoLoadMore,
        });
      }
    }).subscribe('view', 'micro_service.service.broadcast_statistics', ({viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.props.bus.emit('view', 'micro_service.service.current_statistics', {
          viewId,
          serviceId,
          statisticsByType: {...me.serviceDataMap[serviceId].responseStatistics.statisticsByType},
        });
      }
    }).subscribe('view', 'micro_service.service.target.background_updater.get', ({viewId, serviceId, callback}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        if (!me.serviceTargetBackgroundUpdaterMap[serviceId]) {
          me.serviceTargetBackgroundUpdaterMap[serviceId] = {
            getTarget: (name, key) => {
              return me.serviceDataMap[serviceId].targetMap[name]
                ? me.serviceDataMap[serviceId].targetMap[name][key] : undefined;
            },
            setTarget: (name, key, value) => {
              if (!me.serviceDataMap[serviceId].locked) {
                me.serviceDataMap[serviceId].targetMap[name] = me.serviceDataMap[serviceId].targetMap[name] || {};
                me.serviceDataMap[serviceId].targetMap[name][key] = value;
              }
            },
          };
        }
        callback(me.serviceTargetBackgroundUpdaterMap[serviceId]);
      }
    }).subscribe('micro_service', 'relation.node.get', ({viewId,nedes}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        idMap = nedes;
      }
    });

    filterFnParam['canModify'] = node => {
      const enableCrossEdit = me.props.viewDataProvider.viewInfo && me.props.viewDataProvider.viewInfo['teamworkMetaJSON']
        && me.props.viewDataProvider.viewInfo['teamworkMetaJSON']['enable_cross_edit'] === 1;
      return me.props.currentUserId === me.props.viewDataProvider.viewInfo['userId']
        || me.props.currentUserId === node.userId || enableCrossEdit;
    }
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    return null;
  }
}

MicroServiceLogicCall.defaultProps = {
  bus: PB,
};

MicroServiceLogicCall.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  microServiceConfigMap: PropTypes.objectOf(PropTypes.shape(MicroServiceUIConfig)).isRequired,
  currentUserId: PropTypes.number.isRequired,
  currentNodeId: PropTypes.string,
  bus: PropTypes.instanceOf(SimplePB),
};

export default MicroServiceLogicCall;