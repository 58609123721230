import React from 'react';
import { Input, Modal, Form, Radio, message, Row, Col, Popover, Button } from 'antd';

import PB from '@/libs/simplePB';
import Icon from '@/components/common/common.icon';
import { IconFont } from '@/components/common/common.fonticon';
import style from '@/style/components/main.toolbar.printing.less';
import { IconTypes } from '@/constants/common';
import wfImage from '@/assets/wf.png';

@Form.create()
class Printing extends React.Component {

	canPrinting = true;
	constructor(props) {
		super(props);
		this.state = {
			visible: false, // 显示反馈窗口
			confirmBtnText: props.userInfo && props.userInfo.email ? '提交' : '绑定邮箱',
			showTel: false
		};
	}


	componentWillReceiveProps(nextProps) {
		if (nextProps.printingResult === 'success' && this.canPrinting) {
			message.info('提交成功！谢谢', 1, () => {
				//this.setState({visible: false, fileList: []});
				this.setState({showTel: true, fileList: []});
				this.canPrinting = false;
				//this.props.form.resetFields();
			});
		}
		if (nextProps.printingResult === 'failed' && this.canPrinting) {
			message.info('提交失败！请重试', 1, () => {
				this.setState({showTel: true, fileList: []});
				this.canPrinting = false;
				this.props.form.resetFields();
			});
		}
	}

	componentDidMount() {
		PB.sub(this, 'printing', 'new', () => {
			this.setState({visible: true});
		});
	}

	componentWillUnmount() {
		PB.remove(this);
	}

	handleOk = () => {
		this.props.form.validateFieldsAndScroll( async (err, values) => {
			if (!err) {
				// console.log('Received values of form: ', values);
				const { size, background, number} = values;
				let sizeDesc = {
					'A1': 'A1幅面（约840mm * 594mm）',
					'A0': 'A0幅面（约1189mm * 841mm）',
					'large': '超大幅面（约2380mm * 1190mm）',
					'custom': '定制尺寸（客服联系客户确定尺寸）',
				};
				let description = `尺寸：${sizeDesc[size]}；背景底色：${background}；份数：${number}份；附赠：双面不干胶，尽快邮件联系`;
				const params = {
					userId: parseInt(localStorage.getItem('userId'), 10),
					feedback: description,
				};

				this.canPrinting = true;
				await this.props.submitPrintingAction(params);

				/*
				setTimeout(() => {
					if (this.props.userInfo.email) {
						this.setState({visible: false});
					} else {
						message.info('为了更方便与您取得联系，请您绑定邮箱！');
						PB.emit('account', 'change_email.show_modal');
					}
				}, 1000);
				*/
			}
		});

	};
	handleCancel = () => {
		this.setState({visible: false, showTel: false});
		this.props.form.resetFields();
	};

	render() {
		const {getFieldDecorator} = this.props.form;

		return !this.state.showTel?(
			<Modal
				title={<div><IconFont type="icon-yinshua" style={{marginRight: 6}}/> 定制印刷</div>}
				centered={true}
				visible={this.state.visible}
				confirmLoading={this.props.printingLoading}
				okText={this.state.confirmBtnText}
				wrapClassName={style['printing-form-modal']}
				width={808}
				bodyStyle={{padding: '24px 24px 0 36px'}}
				onCancel={this.handleCancel}
				footer={[
					(
						<div className={style['email-tips']}
							key={'email-tips'}
						>
							<Icon type={IconTypes.ICON_FONT}
							      name="icon-email1"
							      style={{fontSize: '1.3rem', marginRight: 6}}
							/>
							提交后我们会尽快与您邮件联系
						</div>
					),
					(<Button key={'close'} onClick={this.handleCancel}>取消</Button>),
					(
						<Button
							type={'primary'}
							key={'ok'}
							onClick={this.handleOk}
						>
							提交
						</Button>
					),
				]}
			>
				{/* <p>感谢您的使用和支持，有任何疑问和建议请发送邮件到：help@joinmap.ai</p> */}

				<Form layout='vertical'
				      /*className={style['printing-form']}*/
				      onSubmit={() => {
				      }}
				>
					<Form.Item
						label={'请选择印刷尺寸（身高170cm的人物与图纸尺寸的大致对比）：'}
					>
						{getFieldDecorator('size', {
							initialValue: 'A0',
							rules: [{message: '请选择印刷尺寸', whitespace: true}],
						})(
							<Radio.Group
								name={'size'}
							>
								<div className={style['print-size']}>
									<div className={style['img-box']}>
										<img src={'/assets/print-demo-img01.jpg'} alt={'A1幅面'}/>
									</div>
									<Radio value="A1">
										<span>
											A1幅面：<br/>
											约840mm * 594mm<br/>
											<span className={style['text-tips']}>参考节点数量500~1000个</span>
										</span>
									</Radio>
								</div>

								<div className={style['print-size']}>
									<div className={style['img-box']}>
										<img src={'/assets/print-demo-img02.jpg'} alt={'A1幅面'}/>
									</div>
									<Radio value="A0">
										<span>
											A0幅面：<br/>
											约1189mm * 841mm<br/>
											<span className={style['text-tips']}>参考节点数量1500个左右</span>
										</span>
									</Radio>
								</div>
								<div className={style['print-size']}>
									<div className={style['img-box']}>
										<img src={'/assets/print-demo-img03.jpg'} alt={'A1幅面'}/>
									</div>
									<Radio value="larger">
										<span>
											超大幅面：<br/>
											约2380mm * 1190mm<br/>
											<span className={style['text-tips']}>参考节点数量2000个以上</span>
										</span>
									</Radio>
								</div>
								<div className={style['print-size']}>
									<div className={style['img-box']} style={{width: 140, paddingLeft: 16}}>
										<span className={style['custom-size']}>自定义</span>
									</div>
									<Radio value="custom">
										<span>
											定制尺寸<br/>
											客服将与您取得联系
										</span>
									</Radio>
								</div>
							</Radio.Group>
						)}
					</Form.Item>
					<Row style={{paddingTop: 36}}>
						<Col span={8}>
							<Form.Item label={'请选择背景底色：'}>
								{getFieldDecorator('background', {
									initialValue: '暗色',
								})(
									<Radio.Group name="background">
										<Radio value={'暗色'} style={{display: 'inline-flex', alignItems: 'center', marginBottom: 6}}>暗色</Radio>
										<Radio value={'白色'} style={{display: 'inline-flex', alignItems: 'center'}}>白色</Radio>
									</Radio.Group>
								)}
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label={'请输入份数：'}>
								{getFieldDecorator('number',{
									initialValue: 1,
								})(
									<div>
										<Input
											type={'number'}
											defaultValue={1}
											style={{width: 80}}
										/> 份
									</div>
								)}
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label={'附赠：'}>
								{getFieldDecorator('addition', {
									initialValue: 'addition',
								})(
									<Radio.Group name="background">
										<Radio value={'addition'} style={{display: 'inline-flex', alignItems: 'center', marginBottom: 6}}>
											双面不干胶（货到可立即张贴）
										</Radio>
									</Radio.Group>
								)}
							</Form.Item>
						</Col>
					</Row>
					<div className="clearfix"></div>
				</Form>
			</Modal>
		):(
			<Modal
				title={<div><IconFont type="icon-yinshua" style={{marginRight: 6}}/> 定制印刷</div>}
				centered={true}
				visible={this.state.visible}
				confirmLoading={this.props.printingLoading}
				okText={this.state.confirmBtnText}
				wrapClassName={style['printing-form-modal']}
				width={808}
				bodyStyle={{padding: '24px 24px 0 36px'}}
				onCancel={this.handleCancel}
				footer={[
					(<Button key={'close'} onClick={this.handleCancel}>确定</Button>)
				]}
			>
				{/* <p>感谢您的使用和支持，有任何疑问和建议请发送邮件到：help@joinmap.ai</p> */}

				<Form layout='vertical'
				      /*className={style['printing-form']}*/
				      onSubmit={() => {
				      }}
				>
					<Form.Item
						label={'为了更好的为你提供服务，您也可以直接通过下面的方式与我们联系：'}
					>
						<div className={style['tel-box']}>
							<img src={wfImage} alt={'联系人微信'}/>
							<p>
								联系人：王帆<br/>
								职　位：战略合作部 主任<br/>
								手机号：18061858060<br/>
								邮　箱：wangf@joinmap.ai<br/>
							</p>
						</div>
					</Form.Item>
					
					<div className="clearfix"></div>
				</Form>
			</Modal>
		);
	}
}

export default Printing;