import React from 'react';
import { withRouter } from 'react-router-dom';
import Icon from "@/components/common/common.icon";
import PropTypes from 'prop-types';
import {IconTypes} from '@/constants/common';
import PB, {SimplePB} from '@/libs/simplePB';
import InputSearchFromDB from "@/components/extendedFunctionality/generatReport/common.explore.search.input";
import style from '@/style/components/extendedFunctionality/generatReport.less';
import { Modal,Row, Col, Table, Input, InputNumber, Popconfirm, Form, Select, Divider,Tooltip, Button,message ,Steps  } from 'antd';
import {API_aiGenerateReport,API_aiGetTextKey,API_getReportTemplateList,API_saveReportTemplate,API_deleteReportTemplate } from "@/api/map_v2";
import { template } from 'lodash';
import Highlighter from "react-highlight-words";
const { Step } = Steps;
const { Option } = Select;
const { TextArea } = Input;
const stepStyle = {
  padding: 30,
  paddingBottom:0
};

const add_template_chapter_list_demo = [
  {
    title: ``,
    wordage: 300,
    description:``,
  },
  {
    title: ``,
    wordage: 500,
    description:``,
  },
  {
    title: ``,
    wordage: 800,
    description:``,
  }]

const base_template_list = [
  {
    id: -1,
    name: '行业分析',
    chapter_list: [
      {
        title: '1. 引言/摘要',
        wordage: 300,
        description:`研究背景：简述行业分析的起因、目的和重要性。研究范围与方法：明确分析的行业范围、时间跨度、主要数据来源及采用的分析方法。关键发现与结论：概括性地总结报告的核心观点、主要发现和结论。`,
      },
      {
        title: '2. 行业概况',
        wordage: 500,
        description:`行业定义与分类：明确行业的定义、边界及主要细分领域。
发展历程：回顾行业的历史演变，包括重要事件、政策变化等。
产业链分析：描述行业的上下游关系，分析各环节的价值分布。`,
      },
      {
        title: `3. 市场规模与增长`,
        wordage: 800,
        description:`市场规模：基于数据估算当前市场规模，包括总量和细分市场规模。
增长趋势：分析市场规模的历史增长率，预测未来增长潜力及驱动因素。
区域市场：探讨不同地区市场的特点、差异及发展趋势。`,
      },
      {
        title: `4. 市场竞争格局`,
        wordage: 800,
        description:`竞争格局概述：分析行业内主要企业的市场份额、竞争地位。
竞争策略分析：探讨企业的市场进入策略、产品差异化、定价策略等。
新兴势力与潜在进入者：评估新兴企业、跨界竞争者及潜在进入者的威胁。`,
      },
      {
        title: `5. 市场驱动因素与阻碍`,
        wordage: 800,
        description:`驱动因素：分析推动行业发展的关键因素，如技术进步、政策支持、市场需求等。
阻碍因素：识别限制行业增长的主要障碍，如政策限制、技术瓶颈、经济环境等。`,
      },
      {
        title: `6. 消费者行为与需求分析`,
        wordage: 800,
        description:`消费者画像：描绘目标消费群体的特征、偏好及需求。
需求趋势：分析消费者需求的变化趋势，预测未来需求热点。
购买行为与决策过程：解析消费者的购买决策过程，识别影响购买的关键因素。`,
      },
      {
        title: `7. 技术与创新趋势`,
        wordage: 800,
        description:`技术发展现状：概述行业内的主要技术进展、应用情况。
创新趋势：预测未来技术发展方向、创新热点及可能带来的变革。
技术壁垒与专利分析：评估技术壁垒对行业竞争的影响，分析重要专利布局。`,
      },
      {
        title: `8. 政策法规与监管环境`,
        wordage: 800,
        description:`政策环境：梳理与行业相关的政策法规，分析其对行业发展的影响。
监管趋势：预测未来监管政策的变化方向，评估其潜在影响。
合规风险：识别企业在运营过程中可能面临的合规风险及应对措施。`,
      },
      {
        title: `9. 风险与挑战`,
        wordage: 800,
        description:`市场风险：分析市场需求波动、竞争加剧等带来的风险。
财务风险：评估行业融资环境、成本上升等财务层面的风险。
其他风险：如技术风险、供应链风险、环保风险等。`,
      },
      {
        title: `10. 机遇与建议`,
        wordage: 800,
        description:`市场机遇：指出行业发展的机遇点，如新兴市场、新兴技术等。
发展战略建议：为企业制定发展战略、优化资源配置提供建议。
投资与合作机会：分析投资潜力，提出合作建议。`,
      },
      {
        title: `11. 结论与展望`,
        wordage: 800,
        description:`总结：回顾报告的主要发现和结论。
未来展望：基于当前分析，对行业未来发展趋势进行预测和展望。`,
      }]
  },{
    id: -2,
    name: '事件分析',
    chapter_list: [
      {
        title: '1. 引言/摘要',
        wordage: 300,
        description:`背景介绍：简述事件发生的环境、背景、目的或重要性。
报告目的：明确编写本报告的目的和预期读者。
摘要：概括报告的主要内容、结论及建议，使读者快速了解报告核心。`,
      },
      {
        title: `2. 事件概述`,
        wordage: 800,
        description:`事件基本信息：时间、地点、涉及主体、直接损失等。
事件经过：详细叙述事件发生的全过程，包括起始、发展、高潮、结束等阶段。
影响范围：分析事件对人员、财产、环境、声誉等方面的影响。`,
      },
      {
        title: `3. 事件原因分析`,
        wordage: 800,
        description:`直接原因：导致事件发生的直接因素，如操作失误、设备故障等。
间接原因：更深层次的原因，如管理不善、制度缺陷、培训不足等。
根本原因：挖掘并指出导致事件发生的根本原因，即“为什么”会发生这样的错误或事故。`,
      },
      {
        title: `4. 应急处置与救援`,
        wordage: 800,
        description:`应急响应：描述事件发生后，各方（包括组织内部和外部）的应急响应措施。
救援行动：详细记录救援过程，包括救援力量投入、救援方法、救援效果等。
效果评估：对应急处置与救援工作的效果进行评估，指出存在的问题和不足。`,
      },
      {
        title: `5. 影响评估`,
        wordage: 800,
        description:`直接损失评估：包括人员伤亡、财产损失等可量化的损失。
间接损失评估：如业务中断、市场机会丧失、品牌形象受损等难以量化的损失。
长期影响预测：基于当前情况，预测事件可能带来的长期影响。`,
      },
      {
        title: `6. 改进措施与建议`,
        wordage: 800,
        description:`短期措施：针对事件暴露出的问题，提出立即实施的改进措施。
长期规划：制定预防类似事件再次发生的长期规划，包括制度完善、流程优化、人员培训等。
管理建议：向管理层提出加强风险管理、提升应急能力等方面的建议。`,
      },
      {
        title: `7. 结论`,
        wordage: 800,
        description:`总结：对事件分析报告的主要内容进行总结，强调关键发现和结论。
强调点：突出事件中的关键教训和需要特别注意的方面。`,
      }]
    },{
      id: -3,
      name: '技术分析',
      chapter_list: [
        {
          title: '1. 引言/摘要',
          wordage: 300,
          description:`背景介绍：简述技术分析报告的背景，包括技术发展的历史、当前市场需求、技术创新的驱动力等。
报告目的与范围：明确报告的目的、研究范围、主要分析对象及预期成果。
关键术语定义：对报告中涉及的专业术语进行解释和定义`,
        },
        {
          title: `2. 技术现状分析`,
          wordage: 800,
          description:`技术发展概况：概述该技术领域的整体发展状况，包括主要技术流派、关键里程碑事件等。
国内外对比：比较国内外在该技术领域的发展水平、应用情况、市场规模等。
技术趋势预测：基于当前技术动态和市场趋势，预测未来技术的发展方向。`,
        },
        {
          title: `3. 技术细节剖析`,
          wordage: 800,
          description:`技术原理与架构：深入解析技术的核心原理、系统架构、关键组件及其相互作用关系。
性能指标分析：评估技术的性能指标，如效率、稳定性、安全性、兼容性等，并与其他相关技术进行比较。
应用场景与案例：介绍技术的典型应用场景，结合具体案例说明其在实际应用中的表现。`,
        },
        {
          title: `4. 优势与局限性`,
          wordage: 800,
          description:`技术优势：总结该技术相对于其他技术的独特优势，如成本效益、创新性、用户体验等。
局限性分析：客观分析技术的局限性，包括技术瓶颈、应用限制、潜在风险等。`,
        },
        {
          title: `5. 市场与竞争分析`,
          wordage: 800,
          description:`市场需求：分析该技术满足的市场需求，包括目标客户群体、市场规模、增长潜力等。
竞争格局：评估市场中的竞争对手，分析他们的技术实力、市场份额、竞争策略等。
市场机会与挑战：基于市场需求和竞争格局，识别市场机会和面临的挑战。`,
        },
        {
          title: `6. 风险评估与应对策略`,
          wordage: 800,
          description:`技术风险：识别技术发展过程中可能遇到的风险，如技术失败、知识产权纠纷等。
市场风险：分析市场变化对技术发展的影响，如市场需求变化、政策调整等。
应对策略：针对识别出的风险，提出具体的应对策略和预防措施。`,
        },
        {
          title: `7. 建议与展望`,
          wordage: 800,
          description:`发展建议：基于技术现状分析、市场需求和风险评估，提出技术发展的建议，包括研发方向、市场推广策略等。
未来展望：预测技术的未来发展趋势，展望其对社会、经济、环境等方面的潜在影响。`,
        },
        {
          title: `8. 结论`,
          wordage: 800,
          description:`总结：对技术分析报告的主要内容进行总结，强调关键发现和结论。
强调点：突出技术分析报告中的重点内容和重要观点。`,
        }]
    },{
      id: -4,
      name: '人才分析',
      chapter_list: [
        {
          title: '1. 引言/摘要',
          wordage: 300,
          description:`背景介绍：简述进行人才分析报告的背景和目的，如市场趋势、企业战略调整、组织发展需求等。
报告范围与目标：明确报告的研究范围、分析对象及预期达到的目标。
关键术语定义：对报告中涉及的专业术语进行解释和定义。`,
        },
        {
          title: `2. 人才现状概览`,
          wordage: 800,
          description:`人才总量与结构：分析当前人才总量、各岗位/层级分布、学历层次、年龄结构等。
人才流动情况：考察人才流入流出比例、离职率、招聘周期等关键指标。
人才质量评估：基于能力、绩效、潜力等维度，对现有人才质量进行综合评价。`,
        },
        {
          title: `3. 人才需求分析`,
          wordage: 800,
          description:`业务发展需求：根据企业战略规划、业务发展目标，分析未来人才需求预测。
关键岗位与技能缺口：识别关键岗位及对应的技能、经验要求，分析当前存在的技能缺口。
外部人才市场分析：对比行业内外部人才市场，了解人才供应情况、竞争态势及薪资水平。`,
        },
        {
          title: `4. 人才发展策略`,
          wordage: 800,
          description:`人才培养计划：制定针对不同层级、岗位的人才培养计划，包括培训课程、导师制度、实践项目等。
人才引进与保留：提出人才引进渠道、激励机制及保留策略，以吸引和留住优秀人才。
人才梯队建设：构建合理的人才梯队，确保关键岗位有合适的接班人选。`,
        },
        {
          title: `5. 人才管理优化`,
          wordage: 800,
          description:`绩效管理体系：评估现有绩效管理体系的有效性，提出改进建议，确保绩效目标与组织战略一致。
职业发展路径：设计清晰的职业发展路径，为员工提供成长空间和晋升机会。
组织文化与氛围：分析组织文化对员工满意度、忠诚度的影响，提出营造积极组织氛围的建议。`,
        },
        {
          title: `6. 风险与挑战`,
          wordage: 800,
          description:`人才流失风险：识别可能导致人才流失的因素，如薪资竞争力不足、职业发展受限等，并提出应对措施。
技能过时风险：分析技术变革对行业技能需求的影响，提出预防技能过时的策略。
外部环境变化：评估宏观经济、政策法规等外部环境变化对人才管理的影响及应对策略。`,
        },
        {
          title: `7. 结论与建议`,
          wordage: 800,
          description:`总结发现：对人才分析报告的主要内容进行总结，概括关键发现和结论。
策略建议：基于分析结果，提出针对性的策略建议，以指导企业未来的人才管理工作。`,
        },
        {
          title: `8. 附录`,
          wordage: 800,
          description:`数据来源与参考文献：列出报告中引用的数据、文献、调查报告等资料的来源。
图表与数据：提供报告中使用的图表、表格、数据等补充信息。
访谈记录与调研问卷：如适用，可附上访谈记录、调研问卷等原始资料。`,
        }]  
    }

]

class GeneratReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys:[],
      article_title: '',
      chapter_title: '',
      chapter_desc: '',
      reference: '',
      word_count: 500,
      report_content:'',
      report_content_info:'',
      highlighter_words:[],
      visible_report:false,
      loading: false,
      template_list: [],
      current_template_index: 0,
      current_chapter_index: 0,
      is_edit_template: false,
      add_template_name: '',
      add_template_chapter_list: add_template_chapter_list_demo,
      report_list:[],
      fname:'',
      edit_flag:false,
      ai_generate_stop_flag:false,
      modal_do_content_list: []
    };
  }

  modal_do = undefined;

  columns = [
    {
      title: 'title',
      dataIndex: 'id',
      key:'id',
      ellipsis: true,
      render: (text, record) => (
        <div>
          <p style={{fontSize:'0.95rem', overflow: 'hidden', whiteSpace: 'nowrap',textOverflow: 'ellipsis',marginBottom:'0.6rem'}}><span>{record.title}</span><span>({record.wordage}字)</span></p>
          <p style={{fontSize:'0.85rem', overflow: 'hidden', whiteSpace: 'nowrap',textOverflow: 'ellipsis',marginBottom:'0.6rem'}} title={record.description}>{record.description}</p>
        </div>
    )},
    {
      title: 'operation',
      dataIndex: 'operation',
      width: 140,
      render: (text, record, index) => {
        return (
          <span style={{width:'140',float:'right',lineHeight:'2.2'}}>
            <a onClick={e => {this.doChapterGenerateReport(e,record,index);}} style={{marginRight:'0.3rem'}}><Icon name={'plus'} /></a>|
            {this.state.report_list && this.state.report_list[index] && this.state.report_list[index]!='' &&
            <span className={style['table-action']}>
            <a onClick={(e) => {this.editChapterGenerateReport(e,record,index);}}><Icon name={'edit'}/></a>|
            <Popconfirm title="确定删除吗?" cancelText="取消" okText="确定" onConfirm={(e) => {this.deleteChapterGenerateReport(e,record,index);}}>
              <a><Icon name={'delete'}/></a>
            </Popconfirm>|
            <a onClick={e => {this.showChapterGenerateReport(e,record,index);}}><Icon name={'icon-watch'} type={IconTypes.ICON_FONT}/></a>
            </span>
            }
            {!(this.state.report_list && this.state.report_list[index] && this.state.report_list[index]!='') &&
            <span className={style['table-action-disable']}>
            <Icon name={'edit'}/>|
            <Icon name={'delete'}/>|
            <Icon name={'icon-watch'} type={IconTypes.ICON_FONT}/>
            </span>
            }
          </span>
        )
      },
    },
  ];

  onSearchChange = e => {
    let me = this;
    me.setState({article_title: e.target.value});
  }

  onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  doChapterGenerateReport = (e, record,idx) => {
    e.stopPropagation();
    this.setState({ 
      current_chapter_index:idx,
      chapter_title:record.title,
      chapter_desc:record.description,
      word_count:record.wordage,
      edit_flag:false,
      visible_report:true,
      report_content_info:'加载中...'
    }, () => {
      this.getAIReport();
    });
  };

  showChapterGenerateReport = (e, record,idx) => {
    e.stopPropagation();
    let me = this;
    let report_content = me.state.report_list[idx];
    this.setState({
      visible_report:true,
      current_chapter_index:idx,
      chapter_title:record.title,
      chapter_desc:record.description,
      word_count:record.wordage,
      report_content,
      report_content_info:'',
      edit_flag:false
    });
    if(report_content != ' ' && report_content != ''){
      me.getAITextKey(report_content);
    }
  };

  editChapterGenerateReport = (e, record,idx) => {
    e.stopPropagation();
    let me = this;
    let report_content = me.state.report_list[idx];
    this.setState({
      visible_report:true,
      current_chapter_index:idx,
      chapter_title:record.title,
      chapter_desc:record.description,
      word_count:record.wordage,
      report_content,
      report_content_info:'',
      edit_flag:true
    });
  };

  deleteChapterGenerateReport = (e, record,idx) => {
    e.stopPropagation();
    let me = this;
    let report_content = me.state.report_list[idx];
    this.setState({
      current_chapter_index:idx,
      chapter_title:record.title,
      chapter_desc:record.description,
      word_count:record.wordage,
      report_content,
      report_content_info:'',
      edit_flag:true
    }, () => {
      this.handleModalReportDelt();
    });
  };

  getAIReport = async () => {
    let me = this;
    let params ={
      article_title: me.state.article_title||'',
      chapter_title: me.state.chapter_title,
      chapter_desc: me.state.chapter_desc,
      reference: me.state.reference,
      word_count: me.state.word_count
    }
    API_aiGenerateReport(params).then((response) => {
      if(response && response.data && response.data.code === 0 && response.data.data ){
        let data = response.data.data;
        if(data.length>0){
          let report_list = me.state.report_list;
          if(report_list.length==0){
            me.state.template_list[me.state.current_template_index].chapter_list.forEach((item, i) => {
              report_list.push("");
            })
          }
          let report_content = data[0]||' ';
          report_list[me.state.current_chapter_index] = report_content;
          this.setState({report_content:report_content,report_content_info:'',report_list});
          if(report_content != ' ' && report_content != ''){
            me.getAITextKey(report_content);
          }
        }else{
          this.setState({report_content_info:'未获取到数据，请稍后再试'});
        }
      }else{
        this.setState({report_content_info:'加载数据失败，请稍后再试'});
        message.info('加载数据失败，请稍后再试');
      }
    }).catch((error) => {
      console.warn('API_aiGenerateReport:',error);
      this.setState({report_content_info:'加载数据失败，请稍后再试'});
      message.info('加载数据失败，请稍后再试');
    });
  }

  getAITextKey = async (text) => {
    let me = this;
    let params ={
      text: text
    }
    API_aiGetTextKey(params).then((response) => {
      if(response && response.data && response.data.code === 0 && response.data.data ){
        let data = response.data.data;
        let area = data.area;
        let kws = data.kws;
        let unit = data.unit;
        let who = data.who;
        let highlighter_words = [...area,...kws,...unit,...who];
        this.setState({highlighter_words});
      }else{
        console.warn('API_aiGenerateReport:',response.data.code,response.data.data);
      }
    }).catch((error) => {
      console.warn('API_aiGenerateReport:',error);
      this.setState({report_content_info:'加载数据失败，请稍后再试'});
      message.info('加载数据失败，请稍后再试');
    });
  }

  getReportTemplateList = async () => {
    let me = this;
    let params ={}
    API_getReportTemplateList(params).then((response) => {
      if(response && response.data && response.data.code === 0 && response.data.data ){
        let data = response.data.data;
        let template_list = base_template_list;
        data.forEach((item, i) => {
          if(item){
            let _chapter_list = [];
            let chapter_list_arr = item.chapter.split('|||');
            chapter_list_arr.forEach((chapter_str, j) => {
              let chapter_arr = chapter_str.split('###');
              let _chapter ={
                id: chapter_arr[0],
                title: chapter_arr[1],
                wordage: chapter_arr[3],
                description:chapter_arr[2],
                key: j
              }
              _chapter_list.push(_chapter);
            })
            let _template={
              id: item.id,
              name: item.name,
              chapter_list: _chapter_list
            }
            template_list.push(_template);
          }
        })
        me.setState({template_list})
        
      }else{
        message.info('加载数据失败，请稍后再试');
      }
    }).catch((error) => {
      console.warn('API_getReportTemplateList:',error)
      message.info('加载数据失败，请稍后再试');
    });
  }

  handleModalAddTemplate = (flag) => {
    let me = this;
    me.setState({ 
      visible_template: true, 
      is_edit_template:flag,
      add_template_name:flag?me.state.template_list[me.state.current_template_index].name:"",
      add_template_chapter_list: flag?me.state.template_list[me.state.current_template_index].chapter_list:add_template_chapter_list_demo 
    });
  };

  handleModalCancel = () => {
    this.setState({ visible_report: false,visible_template:false,loading: false });
  };

  handleModalOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ visible_report: false,visible_template:false,loading: false });
    }, 1000);
  };

  handleModalReportEdit = () => {
    this.setState({ edit_flag: true });
  };

  onChangeReport= ({ target: { value } }) => {
    this.setState({ report_content:value });
  };

  handleModalReportOk = () => {
    let me = this;
    this.setState({ loading: true });
    setTimeout(() => {
      let report_list = me.state.report_list;
      report_list[me.state.current_chapter_index] = me.state.report_content;
      this.setState({ visible_report: false,loading: false,report_content_info:'',report_list });
      message.info('保存成功');
    }, 500);
  };

  handleModalReportDelt = () => {
    let me = this;
    this.setState({ loading: true });
    setTimeout(() => {
      let report_list = me.state.report_list;
      report_list[me.state.current_chapter_index] = '';
      this.setState({ visible_report: false,loading: false,report_content_info:'已删除',report_content:'',report_list });
      message.info('删除成功');
    }, 500);
  };

  handleChangeTemplate = (value ,option) => {
    let report_list = [];
    this.state.template_list[value].chapter_list.forEach((item, i) => {
      report_list.push("");
    })
    this.setState({ current_template_index: value,current_chapter_index: 0, report_list:report_list});
  };

  onChangeTemplateName = e => {
    let me = this;
    me.setState({add_template_name: e.target.value});
  }

  onChangeChapter = (e,index,key) => {
    let me = this;
    let add_template_chapter_list = me.state.add_template_chapter_list;
    add_template_chapter_list[index][key] = e.target.value;
    me.setState({add_template_chapter_list});
  }

  handleAddChapter = () => {
    let me = this;
    let add_template_chapter_list = me.state.add_template_chapter_list;
    add_template_chapter_list.push({
      title: ``,
      wordage: 800,
      description:``,
    });
    me.setState({add_template_chapter_list});
  }

  handleModalTemplateSave = () => {
    let me = this;
    me.setState({loading: true}, () => {
      let params ={
        id: me.state.is_edit_template?me.state.template_list[me.state.current_template_index].id:0,
        name: me.state.add_template_name,
        chapter_list: me.state.add_template_chapter_list
      }
      API_saveReportTemplate(params).then((response) => {
        if(response && response.data && response.data.code === 0 ){
          me.setState({loading: false}, () => {
            message.success('操作成功！');
            this.getReportTemplateList();
          });
        }else{
          me.setState({loading: false}, () => {
            message.info('加载数据失败，请稍后再试');
          });
        }
      }).catch((error) => {
        console.warn('API_aiGenerateReport:',error)
        me.setState({loading: false}, () => {
          message.info('加载数据失败，请稍后再试');
        });
      });
      me.handleModalCancel();
    })
  }

  handleDeleteTemplate = () => {
    let me = this;
    let params ={
      id:me.state.template_list[me.state.current_template_index].id
    }
    API_deleteReportTemplate(params).then((response) => {
      if(response && response.data && response.data.code === 0 ){
        me.setState({loading: false}, () => {
          message.success('操作成功！');
          this.getReportTemplateList();
        });
      }else{
        me.setState({loading: false}, () => {
          message.info('加载数据失败，请稍后再试');
        });
      }
    }).catch((error) => {
      console.warn('API_aiGenerateReport:',error)
      me.setState({loading: false}, () => {
        message.info('加载数据失败，请稍后再试');
      });
    });
    me.handleModalCancel();
  }

  downloadFile = (fileName,fileContent) => {
    // 创建一个Blob实例，包含文件内容
    const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
    // 创建一个指向Blob的URL
    const url = URL.createObjectURL(blob);
    // 创建一个a标签
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    // 触发下载
    link.click();
    // 清理
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  updateModalDo = (modal_do_content_list,finish=false) => {
    let modal_do_content_text = modal_do_content_list.join("\n");
    this.modal_do.update({
      title:finish?'报告生成完成':'报告生成中',
      content: (<p style={{lineHeight:'2rem',fontSize:'0.95rem',whiteSpace:'pre-line'}}>{modal_do_content_text}</p>),
    });
  }

  doAIReport = async (current_chapter_idx) => {
    let me = this;
    let chapter_list = me.state.template_list[me.state.current_template_index].chapter_list;
    let current_chapter = chapter_list[current_chapter_idx];
    let modal_do_content_list = me.state.modal_do_content_list;
    modal_do_content_list.push(current_chapter.title + " 生成中...");
    me.updateModalDo(modal_do_content_list);
    this.setState({ 
      current_chapter_index:current_chapter_idx,
      chapter_title:current_chapter.title,
      chapter_desc:current_chapter.description,
      word_count:current_chapter.wordage,
      modal_do_content_list: modal_do_content_list
    }, () => {
      let params ={
        article_title: me.state.article_title||'',
        chapter_title: me.state.chapter_title,
        chapter_desc: me.state.chapter_desc,
        reference: me.state.reference,
        word_count: me.state.word_count
      }
      API_aiGenerateReport(params).then((response) => {
        if(response && response.data && response.data.code === 0 && response.data.data ){
          let data = response.data.data;
          if(data.length>0){
            let report_list = me.state.report_list;
            if(report_list.length==0){
              me.state.template_list[me.state.current_template_index].chapter_list.forEach((item, i) => {
                report_list.push("");
              })
            }
            let report_content = data[0]||' ';
            report_list[me.state.current_chapter_index] = report_content;
            modal_do_content_list[modal_do_content_list.length-1] = current_chapter.title + " 生成报告成功";
            me.updateModalDo(modal_do_content_list);
            this.setState({report_list,modal_do_content_list}, () => {
              if(!me.state.ai_generate_stop_flag && current_chapter_idx<chapter_list.length-1){
                me.doAIReport(current_chapter_idx+1);
              }else if(current_chapter_idx == chapter_list.length-1){
                modal_do_content_list.push("生成报告完成");
                me.updateModalDo(modal_do_content_list,true);
                this.setState({modal_do_content_list});
                setTimeout(() => {
                  me.modal_do.destroy();
                }, 3000);
              }
            });
          }else{
            modal_do_content_list[modal_do_content_list.length-1] = current_chapter.title + " 生成报告失败";
            me.updateModalDo(modal_do_content_list);
            this.setState({modal_do_content_list}, () => {
              if(!me.state.ai_generate_stop_flag && current_chapter_idx<chapter_list.length-1){
                me.doAIReport(current_chapter_idx+1);
              }else if(current_chapter_idx == chapter_list.length-1){
                modal_do_content_list.push("生成报告完成");
                me.updateModalDo(modal_do_content_list,true);
                this.setState({modal_do_content_list});
                setTimeout(() => {
                  me.modal_do.destroy();
                }, 3000);
              }
            });
          }
        }else{
          modal_do_content_list[modal_do_content_list.length-1] = current_chapter.title + " 生成报告失败";
          me.updateModalDo(modal_do_content_list);
          this.setState({modal_do_content_list}, () => {
            if(!me.state.ai_generate_stop_flag && current_chapter_idx<chapter_list.length-1){
              me.doAIReport(current_chapter_idx+1);
            }else if(current_chapter_idx == chapter_list.length-1){
              modal_do_content_list.push("生成报告完成");
              me.updateModalDo(modal_do_content_list,true);
              this.setState({modal_do_content_list});
              setTimeout(() => {
                me.modal_do.destroy();
              }, 3000);
            }
          });
        }
      }).catch((error) => {
        console.warn('API_aiGenerateReport:',error);
        modal_do_content_list[modal_do_content_list.length-1] = current_chapter.title + " 生成报告失败";
        me.updateModalDo(modal_do_content_list);
        this.setState({modal_do_content_list});
        if(!me.state.ai_generate_stop_flag && current_chapter_idx<chapter_list.length){
          me.doAIReport(current_chapter_idx+1);
        }
      });
    });
  }

  doAllAIReport = () => {
    let me = this;
    me.setState({ai_generate_stop_flag:false,modal_do_content_list: []}, () => {
      me.modal_do = Modal.info({
        title: '报告生成中',
        icon:(<Icon name="loading"/>),
        content: (''),
        okText:"停止生成",
        onOk() {
          me.setState({ai_generate_stop_flag:true});
          me.modal_do.destroy();
        },
      });
      me.doAIReport(0);
    });
  };

  onChangeSteps = current => {
    if(current==2){
      this.doAllAIReport();
    }else if(current==3){
      this.props.bus.emit('report', 'download.report',{});
    }
  };
  
  componentDidMount() {
    let me = this;
    const queryParams = new URLSearchParams(me.props.history.location.search);
    const vname = queryParams.get('m');
    const fname = queryParams.get('n');
    PB.sub(me, 'report', 'article.title.changer', (data) => {
      me.setState({article_title: data});
    });
    PB.sub(me, 'report', 'reference.material.changer', (data) => {
      this.setState({ reference:data.material_list.join('\n') });
    });
    PB.sub(me, 'report', 'generate.report', () => {
      if(me.state.selectedRowKeys.length==0){
        message.info('请先选择章节！');
      }else{
        let chapter_list = me.state.template_list[me.state.current_template_index].chapter_list;
        let current_chapter_idx = me.state.selectedRowKeys[0];
        let current_chapter = chapter_list[current_chapter_idx];
        this.setState({ 
          current_chapter_index:current_chapter_idx,
          chapter_title:current_chapter.title,
          chapter_desc:current_chapter.description,
          word_count:current_chapter.wordage,
          visible_report:true,
          report_content_info:'加载中...'
        }, () => {
          this.getAIReport()
        });
      }
    });
    PB.sub(me, 'report', 'download.report', () => {
      let fileName = me.state.article_title||vname||'';
      let fileContent = fileName;
      let chapter_list = me.state.template_list[me.state.current_template_index].chapter_list;
      me.state.report_list.forEach((item, i) => {
        if(item!=''){
          fileContent+='\n\n\n\n'+item;
        }else{
          let chapter = chapter_list[i];
          fileContent+='\n\n\n\n'+chapter.title+'\n'+chapter.description;
        }
      })
      me.downloadFile(fileName,fileContent);
    });
    me.setState({ article_title:vname||'',fname:fname});
    me.getReportTemplateList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }
  

  render() {
    let me = this;
    const viewId = me.props.viewId||me.props.match.params.viewId;
    const viewName =  me.state.article_title;  //me.state.article_title||decodeURI(this.props.history.location.search.slice(3)||'');
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };
    const rowSelection = {
      type: "radio",
      selectedRowKeys:me.state.selectedRowKeys,
      onChange: me.onSelectChange,
    };
    const options = me.state.template_list.map((d,idx)=> <Option key={d.id} value={idx} >{d.name}</Option>);
    return (
      <div>
        <Steps style={stepStyle} current={-1} onChange={this.onChangeSteps}>
          <Step status="process" disabled title="选择模板"/>
          <Step status="process" disabled title="选择素材"/>
          <Step status="process" title={<a>生成报告</a>}/>
          <Step status="process" title={<a>导出报告</a>}/>
        </Steps>
        <Row gutter={[32, 32]} style={{margin:'1rem'}}>
          <Col span={7} >
            {false && <div className={style['market-drawer-header']}>
              <div className={style['market-drawer-title']} style={{flex:'auto',fontSize:'0.95rem'}}>
                <Icon name="icon-edit" theme="outlined" type={IconTypes.ICON_FONT} className={style['icon-img-right']}/>编辑模板
              </div>
              <div className={style['market-drawer-right']}>
              </div>
            </div>}
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className={style['template_from']}>
              <div className={style['market-drawer-input-box']}>
                <div style={{lineHeight:'2.2rem',height:'2.2rem',overflow:'hidden'}}>
                  <span style={{fontSize:'0.95rem'}}>标题名称：</span>
                  <span style={{float:'right'}} title=''></span>
                </div>
                <Input
                  placeholder={'请输入标题名称'}
                  value={viewName}
                  onChange={me.onSearchChange}
                  style={{height:'2.39rem'}}
                />
              </div>
              <div className={style['market-drawer-input-select-box']}>
                <div style={{lineHeight:'2.2rem',height:'2.2rem',overflow:'hidden'}}>
                  <span style={{fontSize:'0.95rem'}}>章节模板：</span>
                  <span style={{float:'right',fontSize:'0.95rem'}} title=''><a onClick={()=>{this.handleModalAddTemplate(false)}}>添加模板</a></span>
                </div>
                {me.state.template_list && me.state.template_list.length>0 && 
                  <Select placeholder="请选择模板" style={{ height:'2.39rem',fontSize:'0.95rem'}} defaultValue={me.state.current_template_index} value={me.state.current_template_index} onChange={this.handleChangeTemplate}>
                      {options}
                  </Select>}
              </div>
            </Form>
            {me.state.template_list && me.state.template_list.length>0 &&
            <div className={style['market-drawer-header-template-list']}>
              <div className={style['market-drawer-title']} style={{flex:'auto',fontSize:'0.95rem'}}>
                {me.state.template_list[me.state.current_template_index].name}
              </div>
              {me.state.template_list[me.state.current_template_index].id >0 &&
              <div className={style['market-drawer-right']}>
                <a onClick={()=>{this.handleModalAddTemplate(true)}}>编辑模板</a>
                <Divider type="vertical" />
                <Popconfirm title="确定删除当前模板吗?" okText="确定" cancelText="取消" onConfirm={() => this.handleDeleteTemplate()}>
                  <a>删除模板</a>
                </Popconfirm>
              </div>}
            </div>}
            {me.state.template_list && me.state.template_list.length>0 && 
            <div className={style['market-drawer-body']}>
              <div className={style['market-drawer-body-tabs']}>
                <div className={`${style['market-drawer-body-tab-content']} scrollbar scrollbar-none`}>
                  <Table rowSelection={rowSelection} columns={me.columns} dataSource={me.state.template_list[me.state.current_template_index].chapter_list} showHeader={false} bordered={false} pagination={false} />
                </div>
              </div>
            </div>}
          </Col>
          <Col span={17} >
            {viewId && viewName && <InputSearchFromDB visible={true} viewId={viewId} viewName={viewName} nodeName={me.state.fname}/>}
          </Col>
        </Row>
        <Modal
          visible={me.state.visible_report}
          title="生成报告"
          bodyStyle={{lineHeight:1.5,height:'50rem'}}
          width={1000}
          onOk={this.handleModalReportOk}
          onCancel={this.handleModalCancel}
          footer={[
            //<Button key="edit" onClick={this.handleModalReportEdit} disabled={me.state.report_content_info != '' || me.state.edit_flag}> 编辑 </Button>,
            //<Button key="delt" onClick={this.handleModalReportDelt} disabled={me.state.report_content_info != ''}> 删除 </Button>,
            <Button key="back" onClick={this.handleModalCancel}> 关闭 </Button>,
            <Button key="submit" type="primary" loading={me.state.loading} onClick={this.handleModalReportOk}> 保存 </Button>,
          ]}
        >
          {me.state.report_content_info && <p style={{margin:'5rem',fontSize:'1.1rem'}}>
            <center>
              {me.state.report_content_info=='加载中...' && <Icon name={'loading'} style={{marginRight: '0.5em'}} />}
              {me.state.report_content_info}
            </center></p>}
          {me.state.report_content_info=='' && !me.state.edit_flag &&  
            <p style={{whiteSpace:'pre-line',fontSize:'1.1rem'}}>
              <Highlighter highlightClassName={style["key-wd"]} searchWords={me.state.highlighter_words} autoEscape={true} textToHighlight={me.state.report_content}/>
            </p>}
          {me.state.report_content_info=='' && me.state.edit_flag &&  
            <p style={{whiteSpace:'pre-line',fontSize:'1.1rem'}}>
              <TextArea
                value={me.state.report_content}
                onChange={this.onChangeReport}
                autoSize={{ minRows: 8}}
              />
            </p>}
        </Modal>
        <Modal
          visible={me.state.visible_template}
          title={me.state.is_edit_template?"编辑模板":"添加模板"}
          bodyStyle={{lineHeight:1.5,height:'50rem'}}
          width={'80rem'}
          destroyOnClose={true}
          onOk={this.handleModalTemplateSave}
          onCancel={this.handleModalCancel}
          footer={[
            <Button key="back" onClick={this.handleModalCancel}>
              关闭
            </Button>,
            <Button key="submit" type="primary" loading={me.state.loading} onClick={()=>{this.handleModalTemplateSave()}}>
              保存
            </Button>,
          ]}
        >
          <Form {...formItemLayout} onSubmit={this.handleSubmit} className={style['template_from']}>
              <div className={style['market-drawer-input-box']}>
                <div style={{lineHeight:'2.2rem',height:'2.2rem',overflow:'hidden'}}>
                  <span>模板名称：</span>
                  <span style={{float:'right'}} title=''></span>
                </div>
                <Input
                  placeholder={'请输入模板名称'}
                  defaultValue={me.state.add_template_name}
                  onChange={me.onChangeTemplateName}
                  style={{height:'2.5rem'}}
                />
              </div>
              <div className={style['market-drawer-input-box']}>
                <div style={{lineHeight:'2.2rem',height:'2.2rem',overflow:'hidden'}}>
                  <span>章节：</span>
                  <span style={{float:'right'}} title=''><a onClick={()=>{this.handleAddChapter()}}>增加章节</a></span>
                </div>
                <div>
                  {me.state.add_template_chapter_list.map((item,idx) => (
                  <div style={{marginTop:'0.5rem',marginBottom:'2rem'}} key={idx}>
                    <p>
                      <span style={{width:'2rem',display:'inline-block'}}>{idx+1}、</span>
                      <span style={{marginRight:'1rem'}}>章节标题<Input placeholder={'请输入章节标题'} defaultValue={item.title} onChange={(e) => {e.stopPropagation();me.onChangeChapter(e,idx,'title')}} style={{height:'2.5rem',width:'58rem',marginLeft:'0.5rem'}}/></span>
                    </p>
                    <p>
                      <span style={{width:'2rem',display:'inline-block'}}> </span>
                      <span>章节描述<Input placeholder={'请输入章节描述'} defaultValue={item.description} onChange={(e) => {e.stopPropagation();me.onChangeChapter(e,idx,'description')}} style={{height:'2.5rem',width:'58rem',marginLeft:'0.5rem'}} /></span>
                    </p>
                    <p>
                      <span style={{width:'2rem',display:'inline-block'}}> </span>
                      <span>字数<Input type={'number'} defaultValue={item.wordage} onChange={(e) => {e.stopPropagation();me.onChangeChapter(e,idx,'wordage')}} style={{height:'2.5rem',width:'5rem',marginLeft:'0.5rem'}} /></span>
                    </p>
                  </div>))}
                </div>
              </div>
            </Form>
        </Modal>
      </div>
    );
  }
}

GeneratReport.defaultProps = {
  bus: PB,
};

GeneratReport.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
	url: PropTypes.string,
	viewId: PropTypes.string,
};

export default Form.create({ name: 'template_form_item' })(withRouter(GeneratReport));