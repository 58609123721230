import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Menu, Dropdown, Button, Modal, message} from 'antd';

import {DataSet} from '@/libs/vis';

import toolbarStyle from '@/style/components/main.toolbar.less';
import relationStyle from '@/style/components/main.relation.less';
import PB from '@/libs/simplePB';
import {IconTypes} from '@/constants/common';
import Icon from '@/components/common/common.icon';
import SysConfig from "@/constants/sys.config";

import StatisticsList from '@/components/mainView/main.toolbar.statistic';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

// ref 数据流
import {withReactStateHelper} from '@/libs/react-state-helper';
import {bindUtil} from '@/libs/core-decorators';

import {
    getNodeDisplayTitle,
} from '@/constants/vis.defaultDefine.1';
import {getToken} from '@/utils/HttpUtil';
import View, {overrideNextMessage} from '@/components/common/common.viewManager';
import JoinTypeModal from '@/components/common/view/common.view.config.joinTypeModal';
import SetViewInfoModal from '@/components/common/view/common.view.info.setInfoModal';
import ShareModal from '@/components/common/view/common.view.info.shareModal';
import ExportContentModal from '@/components/common/view/common.view.info.exportContentModal';
//import ExportJsonAllModal from '@/components/common/view/common.view.info.exportJsonAllModal';
import ViewUploadBackgroundComponent from '@/components/common/view/common.view.uploadBackgroundComponent';
import TeamworkTicketListModal from "@/components/common/view/teamwork/common.view.teamwork.ticketListModal";
import ViewManager from "@/components/common/common.viewManager";
import intl from 'react-intl-universal';

@withReactStateHelper
@bindUtil.asTargetClass
class WorkMenu extends React.PureComponent {
    state = {
        viewItemOptions: {},
        stop: false, // 按钮状态
        showStatistic: false,
        // 刷新组件
        refresh: false,
        showOutline: false, // 只显示主干节点状态

        // 图谱
        view: undefined,

        showSetViewInfoModal: false, // 设置图谱基本信息弹框显示隐藏
        showJoinTypeModal: false, // 访问设置弹框显示隐藏
        showShareModal: false, // 是否显示分享图谱对话框

        showTicketListModal: false, // 是否显示门票管理列表
        showExportContentModal: false, 
        showExportJsonAllModal: false
    };

    currentNodeId = undefined;

    /**
     * 切换显示主干节点，[显示统计信息]
     */
    toggleOutline(showOutline = false, withStatistic = true) {
        let showStatistic = false;
        if (withStatistic === true) {
            showStatistic = showOutline;
        }
        this.setState({
            refresh: !this.state.refresh,
            showStatistic,
            showOutline,
        }, () => {
            if (this.state.showOutline) {
                this.props.networkRef.hideNodes(node => {
                    return node && node.type !== 0;
                });
            } else {
                this.props.networkRef.showNodes(() => {
                    return true;
                });
            }
        });
    }

    toggleCrossEdit = (crossEditEnabled) => {
        let me = this;

        Modal.confirm({
            title: crossEditEnabled ? '禁止成员删改他人的节点' : '允许成员删改他人的节点',
            content: crossEditEnabled
              ? '除图谱作者外，其他成员将只能删改自己创建的节点，是否继续？'
              : '所有成员都将可以删改图谱内的任意节点，是否继续？',
            okText: '是',
            cancelText: '否',
            onOk: () => {
                overrideNextMessage('static::setViewTeamworkSettingsPartially', false);
                ViewManager.setViewTeamworkSettingsPartially(me.props.viewId, undefined, undefined, {
                    enable_cross_edit: crossEditEnabled ? 0 : 1,
                }).then(() => {
                    message.success('相互删改权限设置成功');
                    me.props.networkRef.loadViewInfo().then(() => me.forceUpdate());
                });
            },
        });
    };

    componentDidMount() {
        let me = this;

        View.getViewById(me.props.viewId).then(view => {
            me.setState({view});
        });
        // 保存联想配置
        PB.sub(me, 'grow', 'saveConfig', viewItemOptions => me.setState({viewItemOptions}));

        PB.sub(me, 'relation', 'node.single_selection_change', currentNodeId => {
            this.currentNodeId = currentNodeId;
        });

        PB.sub(me, 'view', 'view.info.update', ({viewId, viewInfo}) => {
            if (viewId === me.state.view.viewId) {
                let view = Object.assign(me.state.view, {
                    name: viewInfo.name,
                    desc: viewInfo.desc,
                    hasCover: viewInfo.hasCover,
                    up: viewInfo.up,
                    price: viewInfo.price,
                    lock: viewInfo.lock,
                });
                me.setState({view});
            }
        });
        PB.sub(me, 'view', 'view.info.setViewInfo', () => {
            me.setState({showSetViewInfoModal: true})
        }).sub(me, 'view', 'view.share.exportPicture', () => {
            me.setState({showShareModal: true})
        }).sub(me, 'view', 'view.share.exportLink', () => {
            View.copyUrlToBoard(me.state.view, this.props.callback);
        });
    }

    componentWillUnmount() {
        PB.remove(this);
        this.props.networkRef ? this.props.networkRef.unSubscribe(this) : null;
    }

    // 显示文件列表
    showFileList = () => {
        PB.emit('view', 'view.file_list_modal.show');
    };

    openExplorationView = (type, nodeId) => {
        window.open(`/explore/${this.props.networkRef.getData().viewId}/${type}/${nodeId}?from=menu`, '_blank');
    };

    onExplore = type => {
        let me = this;

        if (me.currentNodeId) {
            let node = me.props.networkRef.getNode(me.currentNodeId);
            Modal.confirm({
                content: (
                    <span>
                        <span>系统将计算与</span>
                        <span className={relationStyle['node-in-msg']}>{getNodeDisplayTitle(node)}</span>
                        <span>相关联的信息并进行训练，是否立即开始？</span>
                    </span>
                ),
                okText: '开始训练',
                cancelText: '取消',
                onOk: () => me.openExplorationView(type, node.id),
            });
        } else {
            let nodes = _.get(me.props.networkRef.getData(), 'data.nodes', new DataSet()).get();
            let rootNodes = nodes.filter(node => node.lev === 'root');
            if (rootNodes.length > 0) {
                let node = rootNodes[0];
                Modal.confirm({
                    content: (
                        <span>
                            <span>系统将计算与</span>
                            <span className={relationStyle['node-in-msg']}>{getNodeDisplayTitle(node)}</span>
                            <span>（默认中心节点，您可以选择任意节点）相关联的信息并进行训练，是否立即开始？</span>
                        </span>
                    ),
                    okText: '开始训练',
                    cancelText: '取消',
                    onOk: () => me.openExplorationView(type, node.id),
                });
            } else if (nodes.length > 0) {
                let node = nodes[Math.floor(Math.random() * nodes.length)];
                Modal.confirm({
                    content: (
                        <span>
                            <span>系统将计算与</span>
                            <span className={relationStyle['node-in-msg']}>{getNodeDisplayTitle(node)}</span>
                            <span>（未找到中心节点，系统随机选择，您也可以自行选择任意节点）相关联的信息并进行训练，是否立即开始？</span>
                        </span>
                    ),
                    okText: '开始训练',
                    cancelText: '取消',
                    onOk: () => me.openExplorationView(type, node.id),
                });
            } else {
                Modal.error({
                    title: '请先在图谱中添加节点。',
                    okText: '确认',
                });
            }
        }
    };

    lockView = () => {
        let me = this;

        Modal.confirm({
            title: '锁定图谱',
            content: '是否确认锁定图谱？锁定后所有成员不可编辑图谱。',
            okText: '确认锁定',
            cancelText: '取消',
            onOk: () => {
                me.props.networkRef.lock().then(() => {
                    message.success('图谱锁定成功');
                    me.props.networkRef.loadViewInfo().then(view => {
                        me.setState({view});
                        PB.emit('view', 'view.info.update', {viewId: me.props.viewId, viewInfo: view});
                    });
                });
            },
        });
    };

    unlockView = () => {
        let me = this;

        Modal.confirm({
            title: '解锁图谱',
            content: '是否确认解锁图谱？解锁后图谱恢复正常编辑功能。',
            okText: '确认解锁',
            cancelText: '取消',
            onOk: () => {
                me.props.networkRef.unlock().then(() => {
                    message.success('图谱解锁成功');
                    me.props.networkRef.loadViewInfo().then(view => {
                        me.setState({view});
                        PB.emit('view', 'view.info.update', {viewId: me.props.viewId, viewInfo: view});
                    });
                });
            },
        });
    };

    render() {
        let me = this;
        let view = me.state.view;
        const {callback} = this.props;
        let node_fname = '';
        if(me.props.currentNodeId){
            let node =  me.props.networkRef.getNode(me.props.currentNodeId);
            node_fname = node.fname;
        }
        
        // 登录用户id
        const currentUserId = parseInt(localStorage.getItem('userId'));
        // 判断是否是创建人
        const isOwner = currentUserId && view && (currentUserId === view.userId);

        // 判断是否是免费、收费
        const isFree = view && !!(view.up && view.up === 1 && !(view.price > 0)); // 是否免费

        // 按钮样式
        const btnOption = {
            size: 'default',
            className: toolbarStyle['cool-button'],
        };

        let permission = atob(me.props.userInfo.simplePermission || '');
        let permissionArray = [];
        for (let i = 0; i < permission.length; i++) {
            permissionArray.push(permission.charCodeAt(i));
        }

        let crossEditEnabled = me.props.networkRef && me.props.networkRef.viewInfo
          && me.props.networkRef.viewInfo['teamworkMetaJSON']
          && me.props.networkRef.viewInfo['teamworkMetaJSON']['enable_cross_edit'] === 1;

        // 简单权限控制说明：
        // permissionArray[0]: b'11000000' - 智能训练[可见,可用,-,-,-,-,-,-]
        // permissionArray[1]: b'11111111' - 资源推荐[探索1可见,探索1可用,探索2可见,探索2可用,探索3可见,探索3可用,探索4可见,探索4可用]
        // permissionArray[2]: b'11111111' - 资源推荐[探索5可见,探索5可用,探索6可见,探索6可用,探索7可见,探索7可用,探索8可见,探索8可用]
        // permissionArray[3]: b'11110000' - 资源推荐[探索9可见,探索9可用,探索a可见,探索a可用,-,-,-,-]
        // permissionArray[4]: b'11000000' - 系统运维[可见,可用,-,-,-,-,-,-] // 是指整个【系统运维】菜单
        // permissionArray[5]: b'10100000' - 基本发现[可见,可用],深度发现[可见,可用]

        // 2019-06-27
        // 普通会员：UPDATE `user` SET `simple_permission` = b'000000001110101010101110101000000000000010100000' WHERE `user_id` = ?;
        // 贵宾会员：UPDATE `user` SET `simple_permission` = b'000000001110101110101111111000000000000010100000' WHERE `user_id` = ?;
        // 专家会员：UPDATE `user` SET `simple_permission` = b'110000001111111111111111111100000000000011110000' WHERE `user_id` = ?;
        // 运维专家：UPDATE `user` SET `simple_permission` = b'110000001111111111111111111100001100000011110000' WHERE `user_id` = ?;

        return (
            <div className={this.props.className + ' ' + toolbarStyle['frame'] + ' dark-theme'}>
                <a className={toolbarStyle['frame-logo']} href='/'>
                    <img src='/assets/joinmap128.png' alt="logo" style={{marginTop: '2px'}}/>
                </a>

                <div className={toolbarStyle['divider-button']}>&nbsp;</div>

                {
                    (this.props.isViewer || !view) ? null : (
                        <React.Fragment>
                            <Dropdown
                                key={'view-manage'}
                                overlayClassName={'dark-theme'}
                                overlay={
                                    <Menu style={{textAlign: 'center'}}>
                                        <Menu.Item
                                            key="view-edit"
                                            disabled={!isOwner || me.props.readonly}
                                            style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                            onClick={() => me.setState({showSetViewInfoModal: true})}
                                        >
                                            {intl.get('Custom.menu.basicProperties')}
                                        </Menu.Item>
                                        <Menu.SubMenu
                                            key="view-background"
                                            popupClassName={'dark-theme'}
                                            style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                            title={(
                                                <span>{intl.get('Custom.menu.setUnderlay')}</span>
                                            )}
                                        >
                                            {intl.get('locale')==='zh-cn' && <Menu.SubMenu
                                              key="view-background-map"
                                              popupClassName={'dark-theme'}
                                              style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1', width: '130px'}}
                                              title={(
                                                <span>设置中国地图</span>
                                              )}
                                            >
                                                <Menu.Item
                                                  key={'view-background-map-xs'}
                                                  disabled={!isOwner || me.props.readonly}
                                                  style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                                  onClick={() => {
                                                      PB.emit('view', 'background.on_custom',
                                                        {viewId: me.props.viewId, key: 'ChinaMapXS'});
                                                  }}
                                                >
                                                    超小尺寸
                                                </Menu.Item>
                                                <Menu.Item
                                                  key={'view-background-map-sm'}
                                                  disabled={!isOwner || me.props.readonly}
                                                  style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                                  onClick={() => {
                                                      PB.emit('view', 'background.on_custom',
                                                        {viewId: me.props.viewId, key: 'ChinaMapSM'});
                                                  }}
                                                >
                                                    小号尺寸
                                                </Menu.Item>
                                                <Menu.Item
                                                  key={'view-background-map'}
                                                  disabled={!isOwner || me.props.readonly}
                                                  style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                                  onClick={() => {
                                                      PB.emit('view', 'background.on_custom',
                                                        {viewId: me.props.viewId, key: 'ChinaMap'});
                                                  }}
                                                >
                                                    推荐尺寸
                                                </Menu.Item>
                                                <Menu.Item
                                                  key={'view-background-map-lg'}
                                                  disabled={!isOwner || me.props.readonly}
                                                  style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                                  onClick={() => {
                                                      PB.emit('view', 'background.on_custom',
                                                        {viewId: me.props.viewId, key: 'ChinaMapLG'});
                                                  }}
                                                >
                                                    大号尺寸
                                                </Menu.Item>
                                                <Menu.Item
                                                  key={'view-background-map-xl'}
                                                  disabled={!isOwner || me.props.readonly}
                                                  style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                                  onClick={() => {
                                                      PB.emit('view', 'background.on_custom',
                                                        {viewId: me.props.viewId, key: 'ChinaMapXL'});
                                                  }}
                                                >
                                                    超大尺寸
                                                </Menu.Item>
                                            </Menu.SubMenu>
                                            }
                                            <Menu.Item
                                                key={'view-background-upload'}
                                                disabled={!isOwner || me.props.readonly}
                                                style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                                onClick={() => {
                                                    if (!isOwner || me.props.readonly) return;
                                                    PB.emit('view', 'background.on_upload',
                                                        {viewId: me.props.viewId});
                                                }}
                                            >
                                                {
                                                    (!isOwner || me.props.readonly) ? (
                                                      <div>{intl.get('Custom.menu.uploadImage')}</div>
                                                    ) : (
                                                      <ViewUploadBackgroundComponent
                                                        disabled={false}
                                                        viewId={me.props.viewId}
                                                      >
                                                          {intl.get('Custom.menu.uploadImage')}
                                                      </ViewUploadBackgroundComponent>
                                                    )
                                                }
                                            </Menu.Item>
                                            {intl.get('locale')==='zh-cn' && 
                                            <Menu.Item
                                                key={'view-background-url'}
                                                disabled={!isOwner || me.props.readonly}
                                                style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                                onClick={() => {
                                                    /*PB.emit('view', 'background.on_read_from_url',
                                                        {viewId: me.props.viewId});*/
                                                    message.info('该功能正在完善中，敬请期待……');
                                                }}
                                            >
                                                加载图片链接
                                            </Menu.Item>}
                                            <Menu.Item
                                                key={'view-background-clear'}
                                                disabled={!isOwner || me.props.readonly}
                                                style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                                onClick={() => {
                                                    PB.emit('view', 'background.on_clear',
                                                        {viewId: me.props.viewId});
                                                }}
                                            >
                                                {intl.get('Custom.menu.clearImage')}
                                            </Menu.Item>
                                        </Menu.SubMenu>
                                        <Menu.Item
                                          key="relation-desktop_background"
                                          disabled={!isOwner}
                                          style={{opacity: isOwner ? '1' : '0.6'}}
                                          onClick={() => {
                                              PB.emit('view', 'desktop_background.show_config_modal',
                                                {viewId: me.props.viewId});
                                          }}
                                        >
                                            {intl.get('Custom.menu.setBackground')}
                                        </Menu.Item>
                                        <Menu.Item
                                          key="relation-view_banner"
                                          disabled={!isOwner}
                                          style={{opacity: isOwner ? '1' : '0.6'}}
                                          onClick={() => {
                                              PB.emit('view', 'banner.show_upload_modal', {
                                                  show: true,
                                                  viewId: me.props.viewId
                                              })
                                          }}
                                        >
                                            {intl.get('Custom.menu.titlePicture')}
                                        </Menu.Item>
                                        <Menu.Item
                                            key="relation-initial_position"
                                            disabled={!isOwner}
                                            style={{opacity: isOwner ? '1' : '0.6'}}
                                            onClick={() => {
                                                // message.info('奋力开发中...');
                                                // return;
                                                PB.emit('relation', 'node.initial_position.do_save',
                                                    {viewId: me.props.viewId});
                                            }}
                                        >
                                            {intl.get('Custom.menu.optimizeLoading')}
                                        </Menu.Item>
                                        <Menu.Item
                                            key="relation-remove_initial_position"
                                            disabled={!isOwner}
                                            style={{opacity: isOwner ? '1' : '0.6'}}
                                            onClick={() => {
                                                // message.info('奋力开发中...');
                                                // return;
                                                PB.emit('relation', 'node.initial_position.do_remove',
                                                    {viewId: me.props.viewId});
                                            }}
                                        >
                                            {intl.get('Custom.menu.cancelOptimization')}
                                        </Menu.Item>
                                        <Menu.SubMenu
                                            key="view-vision"
                                            popupClassName={'dark-theme'}
                                            style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                            title={(
                                                <span>{intl.get('Custom.menu.autoDim')}</span>
                                            )}
                                        >
                                            {
                                                me.props.visionConfig === undefined ? (
                                                    <Menu.Item
                                                        key='view-vision-loading'
                                                        disabled={true}
                                                    >
                                                        {intl.get('Custom.general.loading')}
                                                    </Menu.Item>
                                                ) : ([(
                                                    <Menu.Item
                                                        key={'view-vision-multi_level_brightness-disable'}
                                                        disabled={!isOwner || me.props.readonly}
                                                        style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                                        onClick={
                                                            () => {
                                                                if (me.props.visionConfig.multiLevelBrightness) {
                                                                    me.props.changeVisionConfig({
                                                                        ...me.props.visionConfig,
                                                                        multiLevelBrightness: false,
                                                                    });
                                                                }
                                                            }
                                                        }
                                                    >
                                                        {me.props.visionConfig.multiLevelBrightness ? intl.get('Custom.menu.allNodesOn') : intl.get('Custom.menu.currentNodesOn')}
                                                    </Menu.Item>
                                                ), (
                                                    <Menu.Item
                                                        key={'view-vision-multi_level_brightness-time'}
                                                        disabled={!isOwner || me.props.readonly}
                                                        style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                                        onClick={
                                                            () => {
                                                                if (
                                                                  me.props.visionConfig.multiLevelBrightness !== true
                                                                  && me.props.visionConfig.multiLevelBrightness !== 'time'
                                                                ) {
                                                                    me.props.changeVisionConfig({
                                                                        ...me.props.visionConfig,
                                                                        multiLevelBrightness: 'time',
                                                                    });
                                                                }
                                                            }
                                                        }
                                                    >
                                                        {
                                                            (
                                                              me.props.visionConfig.multiLevelBrightness !== true
                                                              && me.props.visionConfig.multiLevelBrightness !== 'time'
                                                            ) ? intl.get('Custom.menu.autoDimTime') : intl.get('Custom.menu.currentAutoDimTime')
                                                        }
                                                    </Menu.Item>
                                                ), (
                                                    <Menu.Item
                                                        key={'view-vision-multi_level_brightness-level'}
                                                        disabled={!isOwner || me.props.readonly}
                                                        style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                                        onClick={
                                                            () => {
                                                                if (me.props.visionConfig.multiLevelBrightness !== 'level') {
                                                                    me.props.changeVisionConfig({
                                                                        ...me.props.visionConfig,
                                                                        multiLevelBrightness: 'level',
                                                                    });
                                                                }
                                                            }
                                                        }
                                                    >
                                                        {
                                                            (
                                                              me.props.visionConfig.multiLevelBrightness !== 'level'
                                                            ) ? intl.get('Custom.menu.autoDimLevel') : intl.get('Custom.menu.currentAutoDimLevel')
                                                        }
                                                    </Menu.Item>
                                                )])
                                            }
                                        </Menu.SubMenu>
                                        <Menu.Item
                                            key="view-intro"
                                            onClick={() => {
                                                // message.info('奋力开发中...');
                                                // return;
                                                PB.emit('view', 'view.info.show_modal');
                                            }}
                                        >
                                            {intl.get('Custom.menu.latestOverview')}
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button {...btnOption} className={toolbarStyle['cool-button']}>
                                    <Icon type={IconTypes.ICON_FONT} name="icon-dashboard1"/> {intl.get('Custom.menu.properties')}
                                </Button>
                            </Dropdown>
                            <Dropdown
                                key={'view-admin'}
                                overlayClassName={'dark-theme'}
                                overlay={
                                    <Menu style={{textAlign: 'center'}}>
                                        <Menu.Item
                                            key="view-guest-setting"
                                            disabled={!isOwner || me.props.readonly}
                                            style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                            onClick={() => me.setState({showJoinTypeModal: true})}
                                        >
                                            {intl.get('Custom.menu.accessSettings')}
                                        </Menu.Item>
                                        {
                                            me.props['isJoinmapUser'] ? (
                                              <Menu.Item
                                                key="view-ticket-management"
                                                disabled={!isOwner || me.props.readonly}
                                                style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                                onClick={() => me.setState({showTicketListModal: true})}
                                              >
                                                  {intl.get('Custom.menu.ticketManagement')}
                                              </Menu.Item>
                                            ) : null
                                        }
                                        <Menu.Item
                                            key="view-transfer"
                                            disabled={!isOwner || me.props.readonly}
                                            style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                            onClick={() => {
                                                // message.info('奋力开发中...')
                                                PB.emit('view', 'view.transfer.toUser', {
                                                    viewId: view.viewId,
                                                });
                                            }}
                                        >
                                            {intl.get('Custom.menu.mapTransfer')}
                                        </Menu.Item>
                                        <Menu.Item
                                            key="view-lock"
                                            disabled={!isOwner || me.props.readonly}
                                            style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                            onClick={() => {
                                                if (view.lock === 1) {
                                                    me.unlockView();
                                                } else {
                                                    me.lockView();
                                                }
                                            }}
                                        >
                                            {view.lock === 1 ? intl.get('Custom.menu.unlockEdit') : intl.get('Custom.menu.lockEdit')}
                                        </Menu.Item>
                                        <Menu.Item
                                            key="view-copy"
                                            disabled={!isFree && !isOwner}
                                            style={{opacity: (!isFree && !isOwner) ? '0.6' : '1'}}
                                            onClick={() => View.payFreeView(view, callback)}
                                        >
                                            {intl.get('Custom.menu.copyMap')}
                                        </Menu.Item>
                                        <Menu.SubMenu
                                            key="view-cross-edit"
                                            popupClassName={'dark-theme'}
                                            style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                            title={(<span>{intl.get('Custom.menu.mutualErasure')}</span>)}
                                        >

                                            <Menu.Item
                                                key={'view-cross-edit-action'}
                                                disabled={!isOwner || me.props.readonly}
                                                style={{opacity: !isOwner || me.props.readonly ? '0.6' : '1'}}
                                                onClick={() => me.toggleCrossEdit(crossEditEnabled)}
                                            >
                                                {crossEditEnabled ? intl.get('Custom.menu.prohibitEditOthers') : intl.get('Custom.menu.allowEditOthers')}
                                            </Menu.Item>
                                        </Menu.SubMenu>
                                    </Menu>
                                }
                            >
                                <Button {...btnOption} className={toolbarStyle['cool-button']}>
                                    <Icon type={IconTypes.ICON_FONT} name="icon-config1"/> {intl.get('Custom.menu.mapAuthority')}
                                </Button>
                            </Dropdown>
                            <Dropdown
                                key={'view-node'}
                                overlayClassName={'dark-theme'}
                                overlay={
                                    <Menu style={{textAlign: 'center'}}>
                                        <Menu.Item key="view-statistics"
                                                   onClick={() => PB.emit('teamwork', 'member.list.show_modal', undefined)}
                                        >
                                            {/*<Icon name="paper-clip"/> */}
                                            {intl.get('Custom.menu.nodesStatistics')}
                                        </Menu.Item>
                                        <Menu.Item
                                          key="view-generate_report"
                                          onClick={() => window.open(`/mainview/extended_functionality/generate_report/${me.props.viewId}?m=${me.props.viewInfo.name}&n=${node_fname}`, '_blank')}
                                        >
                                            {intl.get('Custom.menu.generateReport')}
                                        </Menu.Item>
                                        <Menu.Item
                                          key="view-show_drawer"
                                          onClick={() => PB.emit('presentation', 'config.list.show_drawer', {viewId: me.props.viewId})}
                                        >
                                            {intl.get('Custom.menu.keynoteReport')}
                                        </Menu.Item>
                                        <Menu.Item
                                          key="view-presentation"
                                          onClick={() => PB.emit('toolbar', 'view.explore.toolbar.relationship_clues', {viewId: me.props.viewId})}
                                        >
                                            {intl.get('Custom.menu.relationshipClues')}
                                        </Menu.Item>
                                        <Menu.Item key="view-additional-file" onClick={() => this.showFileList()}>
                                            {/*<Icon name="paper-clip"/> */}
                                            {intl.get('Custom.menu.enclosure')}
                                        </Menu.Item>
                                        <Menu.Item
                                          key="relation-fix_all"
                                          disabled={!isOwner}
                                          style={{opacity: isOwner ? '1' : '0.6'}}
                                          onClick={() => {
                                              PB.emit('relation', 'node.do_fix_all',
                                                {viewId: me.props.viewId});
                                          }}
                                        >
                                            {intl.get('Custom.menu.allNodesFixed')}
                                        </Menu.Item>
                                        <Menu.Item
                                          key="relation-unfix_all"
                                          disabled={!isOwner}
                                          style={{opacity: isOwner ? '1' : '0.6'}}
                                          onClick={() => {
                                              PB.emit('relation', 'node.do_unfix_all',
                                                {viewId: me.props.viewId});
                                          }}
                                        >
                                            {intl.get('Custom.menu.allNodesMoved')}
                                        </Menu.Item>
                                        <Menu.Item
                                          key="relation-straight_all"
                                          disabled={!isOwner}
                                          style={{opacity: isOwner ? '1' : '0.6'}}
                                          onClick={() => {
                                              PB.emit('relation', 'edge.do_straight_all',
                                                {viewId: me.props.viewId});
                                          }}
                                        >
                                            {intl.get('Custom.menu.allNodesLines')}
                                        </Menu.Item>
                                        <Menu.Item
                                          key="relation-smooth_all"
                                          disabled={!isOwner}
                                          style={{opacity: isOwner ? '1' : '0.6'}}
                                          onClick={() => {
                                              PB.emit('relation', 'edge.do_smooth_all',
                                                {viewId: me.props.viewId});
                                          }}
                                        >
                                            {intl.get('Custom.menu.allNodesCurves')}
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button {...btnOption} className={toolbarStyle['cool-button']}>
                                    <Icon type={IconTypes.ICON_FONT} name="icon-relative-graph"/> {intl.get('Custom.menu.mapNode')}
                                </Button>
                            </Dropdown>
                            <Dropdown
                                key={'view-share'}
                                overlayClassName={'dark-theme'}
                                overlay={
                                    <Menu style={{textAlign: 'center'}}>
                                        {
                                            SysConfig.advanceExportUser.indexOf(me.props.userInfo.userId)>-1 ? (
                                                <Menu.Item key="view-advance_export-image" onClick={() => {
                                                    PB.emit('graph', 'advance_export.do', {
                                                        containerId: 'network',
                                                        // type: 'image/png',
                                                        // scale: '4',
                                                        title: view.name,
                                                    });
                                                }}>
                                                    {intl.get('Custom.menu.advancedExport')}
                                                </Menu.Item>
                                            ) : ''
                                        }

                                        <Menu.Item key="view-save-image" onClick={() => {
                                            this.setState({
                                                showShareModal: true,
                                            });
                                        }}>
                                            {/*<Icon name="paper-clip"/> */}
                                            {intl.get('Custom.menu.exportPicture')}
                                        </Menu.Item>
                                        <Menu.Item key="view-save-all" onClick={() => {
                                            //PB.emit('relation', 'graph.export',{viewId: me.props.viewId});
                                            this.setState({showExportContentModal: true});
                                        }}>
                                            {/*<Icon name="paper-clip"/> */}
                                            {intl.get('Custom.menu.exportContent')}
                                        </Menu.Item>
                                        {false &&
                                        <Menu.Item key="view-save-all" onClick={() => {
                                            this.setState({showExportJsonAllModal: true});
                                        }}>
                                            {/*<Icon name="paper-clip"/> */}
                                            {intl.get('Custom.menu.exportAll')}
                                        </Menu.Item>}
                                        <Menu.Item key="view-link" onClick={() => {
                                            View.copyUrlToBoard(view, callback);
                                        }}>
                                            {/*<Icon name="paper-clip"/> */}
                                            {intl.get('Custom.menu.generateLink')}
                                        </Menu.Item>
                                        {/*<Menu.Item key="view-additional-file" onClick={() => {
                                        }}>
                                          <Icon name="paper-clip"/>
                                          导出全部
                                        </Menu.Item>*/}
                                        <Menu.Item key="view-print" onClick={() => {
                                            PB.emit('printing', 'new', '');
                                        }}>
                                            {/*<Icon name="paper-clip"/> */}
                                            {intl.get('Custom.menu.customPrinting')}
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button {...btnOption} className={toolbarStyle['cool-button']}>
                                    <Icon type={IconTypes.ICON_FONT} name="icon-fenxiang"/> {intl.get('Custom.menu.exportSave')}
                                </Button>
                            </Dropdown>
                            <JoinTypeModal
                                visible={me.state.showJoinTypeModal}
                                viewId={view && view.viewId}
                                readonly={!isOwner}
                                viewInfo={view}
                                onClose={() => me.setState({showJoinTypeModal: false})}
                            />
                            <SetViewInfoModal
                                bus={PB}
                                visible={me.state.showSetViewInfoModal}
                                viewId={view && view.viewId}
                                viewInfo={view}
                                onClose={() => me.setState({showSetViewInfoModal: false})}
                            />
                            <ShareModal
                                bus={PB}
                                visible={this.state.showShareModal}
                                viewInfo={view}
                                onClose={() => this.setState({showShareModal: false})}
                            />
                            {me.state.showExportContentModal &&
                            <ExportContentModal
                                bus={PB}
                                visible={this.state.showExportContentModal}
                                viewInfo={view}
                                onClose={() => this.setState({showExportContentModal: false})}
                            />}
                            {me.state.showExportJsonAllModal &&
                            <ExportJsonAllModal
                                bus={PB}
                                visible={this.state.showExportJsonAllModal}
                                viewInfo={view}
                                onClose={() => this.setState({showExportJsonAllModal: false})}
                            />}
                        </React.Fragment>
                    )
                }
                {
                    (permissionArray[0] & 0x80) ? (
                        <Dropdown
                            overlayClassName={'dark-theme'}
                            overlay={
                                <Menu style={{textAlign: 'center'}}>
                                    <Menu.Item
                                        key="a_static_statistics.4"
                                        onClick={() => this.onExplore('timeline')}
                                        disabled={!(permissionArray[0] & 0x40)}
                                    >
                                        <Icon name="clock-circle" style={{marginRight: '0.5em'}}/>{intl.get('Custom.menu.byTime')}
                                    </Menu.Item>
                                    <Menu.Item
                                        key="a_static_statistics.5"
                                        onClick={() => this.onExplore('map')}
                                        disabled={!(permissionArray[0] & 0x40)}
                                    >
                                        <Icon name="environment" style={{marginRight: '0.5em'}}/>{intl.get('Custom.menu.byGeography')}
                                    </Menu.Item>
                                    <Menu.Item key="a_static_statistics.7" onClick={() => this.onExplore('network')}>
                                        <Icon name="deployment-unit" style={{marginRight: '0.5em'}}/>{intl.get('Custom.menu.byRelationship')}
                                    </Menu.Item>
                                    <Menu.Item
                                        key="a_static_statistics.6"
                                        onClick={() => this.onExplore('list')}
                                        disabled={!(permissionArray[0] & 0x40)}
                                    >
                                        <Icon name="bars" style={{marginRight: '0.5em'}}/>{intl.get('Custom.menu.byCard')}
                                    </Menu.Item>
                                </Menu>
                            }>
                            <Button {...btnOption}>
                                <Icon name="area-chart"/> {intl.get('Custom.menu.training')}
                            </Button>
                        </Dropdown>
                    ) : null
                }
                {
                    (permissionArray[4] & 0x80) ? (
                        <Dropdown
                          overlayClassName={'dark-theme'}
                          overlay={(
                            <Menu style={{textAlign: 'center'}}>
                                <Menu.Item
                                  key="m_overview"
                                  //onClick={() => window.open(`http://ana.joinmap.ai/#/overview?u=${me.props.userInfo.userId}&t=${getToken()}`, '_blank')}
                                  onClick={() => window.open(`https://plot.joinmap.ai/?app=odyssey&token=8wdpd92dkwecmwcmee00ewcekwvcweecwe2192374&u=${me.props.userInfo.userId}`, '_blank')}
                                  disabled={!(permissionArray[4] & 0x40)}
                                >
                                    {intl.get('Custom.menu.panoramaView')}
                                </Menu.Item>
                                <Menu.Item
                                  key="m_micro_service"
                                  onClick={() => PB.emit('view', 'micro_service.config.show_list_modal')}
                                  disabled={!(permissionArray[4] & 0x40)}
                                >
                                    {intl.get('Custom.menu.microservices')}
                                </Menu.Item>
                            </Menu>
                          )}
                        >
                            <Button {...btnOption}>
                                <Icon name="tool"/> {intl.get('Custom.menu.opex')}
                            </Button>
                        </Dropdown>
                    ) : null
                }

                <StatisticsList
                    visual={this.state.showStatistic}
                    onClose={(status) => this.toggleOutline(status)}
                />
                <TeamworkTicketListModal
                  viewDataProvider={me.props.networkRef}
                  onClose={() => me.setState({showTicketListModal: false})}
                  visible={me.state.showTicketListModal}
                />
            </div>
        );
    }
}

// noinspection SpellCheckingInspection
WorkMenu.defaultProps = {
    isViewer: false, // 是否是浏览模式
    readonly: true,
    userInfo: {
        simplePermission: 'AAAAAAAAAAAAAAAAAAAAAA==',
    },
};

WorkMenu.propTypes = {
    networkRef: PropTypes.instanceOf(ViewDataProvider),
    readonly: PropTypes.bool,
    userInfo: PropTypes.object,
    viewId: PropTypes.string,
    visionConfig: PropTypes.object,
    changeVisionConfig: PropTypes.func,
    viewInfo: PropTypes.object,
    currentNodeId: PropTypes.string,
};

export default WorkMenu;
