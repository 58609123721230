import React from 'react';
import PropTypes from 'prop-types';

import viewStyle from '@/style/components/dashboard/dashboard.view.less';
import style from "@/style/containers/dashboard.latest.less";
import ToolbarWithButtons from "@/components/dashboard/dashboard.toolbarwithbuttons";
import {Tabs, Row, Col, Form, message, Popover, Modal, Button} from 'antd';
import QueueAnim from "rc-queue-anim";
import ViewInfoCard from "@/components/dashboard/dashboard.viewInfoCard";
import {bindUtil} from "@/libs/core-decorators";
import ViewManager from "@/components/common/common.viewManager";
import {autoSetState, withReactStateHelper, helperKey as autoSetStateHelperKey} from "@/libs/react-state-helper";
import UserNickAvatar from "@/components/common/objects/common.userAvatar";
import { API_GetSubGraph,API_LoadNodeListByUser,API_GetViewById,API_GetTeamworkMembers,API_LoadTeamworkSettings,API_GetAccessibleViewList,API_GetMyIsMasterViewList,API_GetMyIsMemberViewList,API_GetPublicViewList } from "@/libs/view/network/api.js";
import { API_GetRelationNodeList } from "@/libs/view/network/map_v2_api.js";
import moment from "moment";
import PB from '@/libs/simplePB'
import NodePresentation from "@/components/common/node/common.node.presentation.latest";
import ViewDataProvider, {overrideNextMessage} from '@/components/common/dataProvider/common.dataProvider.view';
import MyNetwork, {myVisNetworkStyle} from '@/libs/myVis/MyNetwork3';
import NodeInfoCommonTooltip, {getNodeTooltipContent} from "@/components/mainView/nodeInfo/main.nodeInfo.common.tooltip";
import intl from 'react-intl-universal';

class LatestView extends React.Component {
    state = {
        filteredViewList: [],
        nodeList:[],
        activeKey: 'tab_id_0',
        status: 'idle',
        nodeId:undefined,
        viewId:undefined,
        focusNodeId:undefined,
        presentationPlayingNodeId: undefined, // 当前自动漫游节点Id
        presentationStarted: false, // 当前播放状态
        showNodeTooltip: false,
        nodeTooltipInfo: undefined,
        currentDuring: 3000,
        presentationStayFocus: false,
    };

    myNetwork = undefined;
    container = undefined;
    processingNodeTooltip = undefined;
    playPresentationAction = 'do_start';
    autoStartPresentation = true;
    nodesPlayTimeout = undefined;

    onTabChange = activeKey => {
        let me = this;
        if (activeKey !== me.state.activeKey) {
          me.setState({activeKey,nodeId:undefined,nodeList:[]}, () => {
            me.onLoadData();
          });
        }
    };

    onLoadData = () => {
        let me = this, limit = 5;
        const currentUserId = localStorage.getItem('userId');
        me.setState({status: 'loading', filteredViewList: []}, () => {
            switch (me.state.activeKey) {
                case 'tab_id_0':
                    ViewManager.loadAccessibleViewList({q: '', start: 0, limit}).then(res => {
                        me.setState({status: 'success', filteredViewList: res}, () => {
                            me.onLoadNodes(me.state.activeKey,res);
                        });
                        
                    }).catch(err => {
                        console.log(' 加载更多数据失败 ->  :', );
                        message.info('加载更多数据失败，请稍后再试');
                        me.setState({status: 'failed'});
                    });
                    break;
                case 'tab_id_1':
                    ViewManager.loadUserIsMasterViewList(currentUserId, {q: '', e: '的事件关联图谱', start: this.pageStart, limit}).then(res_u => {
                        ViewManager.loadUserIsMemberViewList(currentUserId, {q: '', start: this.pageStart, limit}).then(res_m => {
                            let res = res_u.concat(res_m);
                            res = res.length>0 ? res.sort((A, B) => {
                                return A['updateTime'] === B['updateTime'] ? 0 : (A['updateTime'] < B['updateTime'] ? 1 : -1)
                            }).slice(0, 5) :[];
                            me.setState({status: 'success', filteredViewList: res}, () => { me.onLoadNodes(me.state.activeKey,res);});
                        }).catch(err => {
                            console.log(' 加载数据失败 ->  :', );
                            message.info('加载数据失败，请稍后再试');
                            me.setState({status: 'failed'});
                        });
                    }).catch(err => {
                        console.log(' 加载数据失败 ->  :', );
                        message.info('加载数据失败，请稍后再试');
                        me.setState({status: 'failed'});
                    });
                break;
                case 'tab_id_2':
                        // 我的开放阅读 
                        ViewManager.loadPublicViewList({q: '', start: 0, limit}).then(res => {
                            me.setState({status: 'success', filteredViewList: res}, () => { me.onLoadNodes(me.state.activeKey,res);});
                        }).catch(err => {
                            console.log(' 加载数据失败 ->  :', );
                            message.info('加载数据失败，请稍后再试');
                            me.setState({status: 'failed'});
                        });
                break;
                default:
                    // 默认 all = 可访问的图谱
                    ViewManager.loadAccessibleViewList({q: '', start: this.pageStart, limit}).then(res => {
                        me.setState({status: 'success', filteredViewList: res}, () => { me.onLoadNodes(me.state.activeKey,res);});
                    }).catch(err => {
                        console.log(' 加载数据失败 ->  :', );
                        message.info('加载数据失败，请稍后再试');
                        me.setState({status: 'failed'});
                    });
            }
        
        });
    };

    reLoadData = () => {
        let me = this, limit = 5;
        const currentUserId = localStorage.getItem('userId');
        //me.setState({filteredViewList: []}, () => {
            switch (me.state.activeKey) {
                case 'tab_id_0':
                    API_GetAccessibleViewList({q: '', start: 0, limit}).then(response => {
                        if (response && response.data && response.data.code === 0) {
                            let res = response.data.data;
                            me.setState({filteredViewList: [...new Set(res)]}, () => {me.onLoadNodes(me.state.activeKey,[...new Set(res)])});
                        }
                    }).catch(err => {
                        console.log(' 加载更多数据失败 ->  :',err );
                    });
                    break;
                case 'tab_id_1':
                    API_GetMyIsMasterViewList(currentUserId, {q: '', e: '的事件关联图谱', start: this.pageStart, limit}).then(response => {
                        if (response && response.data && response.data.code === 0) {
                            let res = response.data.data;
                            API_GetMyIsMemberViewList(currentUserId, {q: '', start: this.pageStart, limit}).then(response_m => {
                                if (response_m && response_m.data && response_m.data.code === 0) {
                                    let res_m = response.data.data;
                                    res = res.concat(res_m);
                                }
                                res = res.length>0 ? res.sort((A, B) => {
                                    return A['updateTime'] === B['updateTime'] ? 0 : (A['updateTime'] < B['updateTime'] ? 1 : -1)
                                }).slice(0, 5) :[];
                                me.setState({filteredViewList: [...new Set(res)]}, () => {me.onLoadNodes(me.state.activeKey,[...new Set(res)]);});
                            }).catch(err => {
                                console.log(' 加载数据失败 ->  :',err );
                            });
                        }
                    }).catch(err => {
                        console.log(' 加载数据失败 ->  :', err);
                    });
                break;
                case 'tab_id_2':
                        // 我的开放阅读 
                        API_GetPublicViewList({q: '', start: 0, limit}).then(response => {
                            if (response && response.data && response.data.code === 0) {
                                let res = response.data.data;
                                me.setState({filteredViewList: [...new Set(res)]}, () => {me.onLoadNodes(me.state.activeKey,[...new Set(res)])});
                            }
                        }).catch(err => {
                            console.log(' 加载更多数据失败 ->  :',err );
                        });
                break;
                default:
                    // 默认 all = 可访问的图谱
                    API_GetAccessibleViewList({q: '', start: 0, limit}).then(response => {
                        if (response && response.data && response.data.code === 0) {
                            let res = response.data.data;
                            me.setState({filteredViewList: [...new Set(res)]}, () => {me.onLoadNodes(me.state.activeKey,[...new Set(res)])});
                        }
                    }).catch(err => {
                        console.log(' 加载更多数据失败 ->  :',err );
                    });
            }
        
        //});
    };

    getMembers = async (viewId) => {
        let limit = -1, orderBy = undefined, orderType = undefined, start = 0
        let res = await API_GetTeamworkMembers(viewId, limit, orderBy, orderType, start);
        if (res && res.data && res.data.code === 0) {
            return res.data.data;
        }else{
            return [];
        }
    }

    onLoadNodes = (tab_key,filteredViewList) => {
        //let filteredViewList = this.state.filteredViewList;
        let i=0;
        filteredViewList.length>0 && filteredViewList.forEach(async (view,index) => {
            if(this.state.activeKey===tab_key){
                filteredViewList[index].members = await this.getMembers(view.viewId);
                let res = await API_GetRelationNodeList(view.viewId, {userConfirmed: 1}, 0, 5, 'update_time', 'DESC');
                let _nodeList = res.data.data;
                _nodeList.map((node,idx_n) => {
                    _nodeList[idx_n].userInfo = filteredViewList[index].members.find( member => member.userId===node.userId);
                })
                filteredViewList[index].nodes = _nodeList;
                i++;
                i===filteredViewList.length && this.setState({status: 'success',filteredViewList},()=> {
                    this.setNodes(filteredViewList);
                });

            }
        });
    }

    doFocusNode = (node,index) => {
        if (node && node.id && this.state.nodeList[index].id==node.id) {
          this.setState({
            presentationPlayingNodeId: node.id,
            focusNodeId: node.id,
            nodeId: node.id,
            viewId: node.viewId
          })
          PB.emit('relation', 'node.presentation.focus', {node: node});
        }
    };

    setNodes = (filteredViewList) => {
        let nodeList = [];
        filteredViewList.length>0 && filteredViewList.forEach((view,index) => {
            nodeList = nodeList.concat(view.nodes);
        })
        this.setState({nodeList},()=> {
            this.autoStartPresentation && nodeList.length>0 && setTimeout(() => this.nodesPlay(nodeList), 2000); 
        })
    }

    nodesPlay = (nodeList) => {
        let me = this,index=0;
        const doStart = (_index) => {
            me.doFocusNode(nodeList[_index],_index);
            _index++;
            if(_index==nodeList.length){
                //_index=0;
                //me.nodesPlayTimeout = setTimeout(()=>{doStart(_index);}, 3000);
                //requestAnimationFrame(() => this.onLoadData());
                this.reLoadData();
            }else{
                me.nodesPlayTimeout = setTimeout(()=>{doStart(_index);}, 3000);
            }
        };
        me.clearNodesPlayTimeout();
        doStart(index);
    }
    
    clearNodesPlayTimeout =() => {
        let me = this;
        if (me.nodesPlayTimeout > 0) {
            clearTimeout(me.nodesPlayTimeout);
            me.nodesPlayTimeout = 0;
        }
    }

    getNodes =(nodeId) => {
        return this.state.nodeList.filter( node => node.id===nodeId);
    }
    
    
    componentDidMount() {
        let me = this, networkOptions = myVisNetworkStyle.options();
        // 迁移到MyNetwork3
        me.container = document.getElementById('network');
        networkOptions.physics.enabled = false;
        me.myNetwork = new MyNetwork(me.container, {nodes: [], edges: []}, networkOptions);
        //me.myNetwork.network.on('beforeDrawing', (ctx) => me.drawBackground(ctx));
        this.reLoadData();
        //requestAnimationFrame(() => this.onLoadData());
/*
        if (this.autoStartPresentation) {
            let autoStartTimeout = setTimeout(() => PB.emit('relation', 'presentation.do_start', {config: {}}), 5000);
            PB.once(this, 'node', ['presentation.do_pause', 'presentation.do_hold'], () => {
              clearTimeout(autoStartTimeout);
            });
          }
*/
        PB.sub(me, 'relation', 'node.presentation.focus', ({node}) => {
            //if (me.state.nodeId !== node.id) {  //autoRefresh时为自动刷新，对同一节点修改需要再次漫游该节点以更新数据
              PB.emit('relation', 'node.presentation.stop');
              me.setState({nodeId: node.id}, () => {
                PB.emit('node', 'presentation.do', {node});
               });
               //PB.emit('node', 'presentation.do', {node});
            //}
          });
      
          PB.sub(me, 'relation', 'node.presentation.stop', () => {
            me.setState({nodeId: undefined}, () => {
              PB.emit('node', 'presentation.stop');
             })
          });

          PB.sub(this, 'network', 'focus', (nodes) => {
            /**/
            console.log("PB[network][focus] beginning：nodes", nodes);
            if (_.isArray(nodes)) {
              setTimeout(() => {
                // this.myNetwork.network.stopSimulation();
                this.myNetwork.focus(nodes.map(node => node.id));
              }, 100);
            } else {
              setTimeout(() => {
                // this.myNetwork.network.stopSimulation();
                this.myNetwork.focus(nodes.id);
              }, 100);
            }
          });
          PB.sub(this, 'network', 'node_tooltip.hide', () => {
            if (this.state.showNodeTooltip) {
              this.setState({showNodeTooltip: false});
              this.processingNodeTooltip = undefined;
            }
          });
          PB.sub(this, 'network', 'node_tooltip.show', node => {
            PB.emit('network', 'node_tooltip.hide');
            this.processingNodeTooltip = node.id;
            //this.myNetwork.focus(node.id, null, () => {
              let nodeTooltipInfo = getNodeTooltipContent(this.props.networkRef, node.id);
              if (nodeTooltipInfo.success && this.processingNodeTooltip === node.id) {
                // 显示节点Tooltip
                let style = configStyle[node['lev']] || configStyle['_default'];
                nodeTooltipInfo = {...nodeTooltipInfo, style};
                this.setState({showNodeTooltip: true, nodeTooltipInfo});
              }
            //});
          });
         // 当前自动漫游的节点
      PB.sub(me, 'node', 'presentation.playing.node', ({nodeId}) => {
        if (me.state.presentationStarted && nodeId) {
          me.setState({
            presentationPlayingNodeId: nodeId,
            focusNodeId: nodeId
          })
        }
      });
      PB.sub(me, 'node', 'presentation.started', () => {
        me.setState({ presentationStarted: true });
      });
  
      PB.sub(me, 'node', 'presentation.stopped', () => {
        me.setState({ presentationStarted: false, presentationPlayingNodeId: undefined });
      });

      PB.sub(me, 'node', 'presentation.playing.continue', () => {
        me.setState({presentationStarted: true, presentationStayFocus: false});
      });
	}
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        
    }
    componentWillUnmount = () => {
        this.clearNodesPlayTimeout();
        this.setState = (state,callback)=>{
          return;
        };
    }    
    
    render() {
        let me = this;
        return (
            <div className={style["content"] + " scrollbar scrollbar-none"}>
                <ul className={style["container"]} style={{width:'auto%'}}>
                {me.state.filteredViewList.map(view => (
                    <li key={view.id}>
                        <Row className={style["views-list"]}>
                            <Col span={11}>
                                <ViewInfoCard
									readOnly={parseInt(view['teamworkType']) !== 1}
									showCornerTip={true}
									callback={this.onViewItemUpdated}
									view={view}
									key={`view-${view.viewId}`}
									module={this.module}
                                    boxSize={1}
						        />
                            </Col>
                            <Col offset={0} span={13}>
                                <div className={style["node-list"]}>
                                    {view["nodes"] && view["nodes"].map((node, idx_n) => {
                                        return (
                                            <div key={"n-" + idx_n} className={style["node-item"] } style={{clear: 'both'}}>
                                                <div className={`${style['node-item-body']} ${style['flex-sub']}`}>
                                                    <div className={`${style['node-item-inner']} ${style['flex-sub']}`}>
                                                        <div className={`${style['flex']} ${style['align-center']} ${style['node-li']} ${me.state.presentationPlayingNodeId === node.id || me.state.focusNodeId=== node.id ? style['active'] : ''}`} onClick={(e) => {e.stopPropagation();me.doFocusNode(node);}} style={{cursor:'pointer'}}>
                                                            <UserNickAvatar className={style["user"]} user={node.userInfo} avatarSize="1.53rem" showName={false}/>
                                                            <div className={style['node-info']}>{node.fname}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)})}
                                </div>
                            </Col>
					    </Row>
                    </li>
				))}
                </ul>
                {this.myNetwork && (this.state.nodeList && this.state.nodeList.length>0) ? (
                    <NodePresentation
                    bus={PB}
                    viewDataProvider={this.props.networkRef}
                    network={this.myNetwork}
                    viewId={this.state.viewId}
                    nodeId={this.state.presentationPlayingNodeId||this.state.nodeList[0].id}
                    nodeList={this.state.nodeList}
                    showState={me.nodesPlayTimeout>0}
                    />
                ) : null
                }
                {/*<div
                    style={{
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        pointerEvents: 'none',
                        position: 'absolute',
                    }}
                    >
                    {
                        this.state.showNodeTooltip && this.state.nodeTooltipInfo &&
                        this.state.nodeTooltipInfo.success && (
                        <NodeInfoCommonTooltip {...this.state.nodeTooltipInfo} />
                        )
                    }
                </div>*/}
                <div id="network" style={{width:0,height:0}} className={style["relation-container"]}>&nbsp;</div>
                <div  className={style["tab-box"]}>
                    <ul>
                        <li className={` ${me.state.activeKey === 'tab_id_0' ? style['active'] : ''}`} onClick={()=>me.onTabChange('tab_id_0')}>{intl.get('Custom.channel.simpleChannel')[0]}</li>
                        <li className={` ${me.state.activeKey === 'tab_id_1' ? style['active'] : ''}`} onClick={()=>me.onTabChange('tab_id_1')}>{intl.get('Custom.channel.simpleChannel')[1]}</li>
                        <li className={` ${me.state.activeKey === 'tab_id_2' ? style['active'] : ''}`} onClick={()=>me.onTabChange('tab_id_2')}>{intl.get('Custom.channel.simpleChannel')[2]}</li>
                    </ul>
                </div>
            </div>
        );
    }
}

LatestView.propTypes = {
    networkRef: PropTypes.instanceOf(ViewDataProvider),
	callback: PropTypes.func, // 图谱操作的回调
	hasLogin: PropTypes.bool, // 是否已经登录
};

export default LatestView;
