import React from 'react';
import { connect } from 'react-redux';

import { Route, Redirect, Switch as SwitchR } from 'react-router-dom';
import { Layout } from 'antd';
import GenerateReport from '@/components/extendedFunctionality/generateReport';

import style from '@/style/containers/extendedFunctionality.less'
import PropTypes from 'prop-types';
import { SysUIConfig } from '@/constants/sys.config';
import BadgeInfo from '@/components/mainView/main.badgeInfo';
import PB from '@/libs/simplePB';
import intl from 'react-intl-universal';

const {Header, Content} = Layout;

@connect(
	state => ({
		loginStatus: state.account.loginStatus,
		userInfo: state.account.userInfo,
	}),
)
class ExtendedFunctionality extends React.Component {
	// 本组件路由前缀
	routePath = '/mainview/extended_functionality';

	state = {
		contentWrapSpacing: 100, // 计算图谱列表容器左右间距
	};

	componentWillMount() {
		const {loginStatus} = this.props;
		if (loginStatus !== 0) {
			this.props.history.push('/login');
		}
	}

	componentDidMount() {
		this.handleResize();
		window.addEventListener('resize', this.handleResize); //监听窗口大小改变
	}

	componentWillUnmount() {
		PB.remove(this);
		window.removeEventListener('resize', this.handleResize) // 取消监听窗口大小变化
	}

	handleResize = () => {
		let htmlFontSize = 14, // html的响应变化基准
				windowWidth = window.innerWidth,
				viewInfoCardWidth = 26; // 图谱卡片占的宽度，单位rem，需要跟据html的响应变化基准
		// 参照css定义的html的响应设置
		if (windowWidth <= 1920) {
			htmlFontSize = 14;
		} else if (windowWidth > 1920 && windowWidth <= 3999) {
			htmlFontSize = 18;
		} else if (windowWidth > 4000) {
			htmlFontSize = 24;
		}

		const cardWidth = viewInfoCardWidth * htmlFontSize;
		this.setState({
			contentWrapSpacing: (windowWidth - Math.floor(windowWidth / cardWidth) * cardWidth) / 2,
		})
	};

	render() {
		// 顶部按钮
		const { topMenus, contentWrapSpacing } = this.state;

		return (
			<Layout className={style['dashboard-layout-wrap']}>
				<Header className={style['header']}>
					<div className={style['logo']}>
						<img
							onClick={() => {
								this.props.history.push({pathname: '/index', state: {type: 'intro'}})
							}}
							src={intl.get('locale')==='zh-cn'?'/assets/logo-hans-w.png':'/assets/logo-hans_en.png'}
							alt={intl.get('Custom.general.title')}
						/>
					</div>
					{/*
					<BadgeInfo currentUserInfo={this.props.userInfo}
					           doChangePwd={this.props.doChangePwd}
					           onLogout={this.props.onLogout}
					           history={this.props.history}
					           changeUserBasicInfoStatus={this.props.changeUserBasicInfoStatus}
					           onSaveUserBasicInfo={this.props.onSaveUserBasicInfo}
					           style={{
					           	visibility: SysUIConfig.BadgeInfo.visibility,
						           // top: '17px',
					           }}
					/>*/}
				</Header>
				<Content>
					<SwitchR>
						{/*<Route path={`${this.routePath}/recommend`} render={() => null}/>*/}
						<Route path={`${this.routePath}/generate_report/:viewId?`} render={({history}) => (
							<GenerateReport
								url={this.props.url}
								history={history}
								viewId={this.props.viewId}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route render={() => (<Redirect to={topMenus[0].path}/>)}/>
					</SwitchR>
				</Content>
			</Layout>
		);
	}
}

ExtendedFunctionality.defaultProps = {
	params:{}
};

ExtendedFunctionality.propTypes = {
	url: PropTypes.string,
	userInfo: PropTypes.object,
	loginStatus: PropTypes.number,
	history: PropTypes.object,
	onLogout: PropTypes.func,
	doChangePwd: PropTypes.func,
	changeUserBasicInfoStatus: PropTypes.number,
	onSaveUserBasicInfo: PropTypes.func,
	changePwdStatus: PropTypes.number,
	removingViewList: PropTypes.array,
	resetChangePwdStatus: PropTypes.func,
	params:PropTypes.object, // 一些非必须的参数
	viewId: PropTypes.string,
};

export default ExtendedFunctionality;

