import React from 'react';
import {Icon, Modal, Button, message, Tabs } from 'antd';
import {IconTypes} from '@/constants/common';
import PropTypes from 'prop-types';
import _ from "lodash";
import QRCode from 'qrcode.react';
import moment from "moment";
import {API_TradeOrderCreat,API_UserLevelChanger,API_TradeOrderCheckState} from "@/api/user";
import {convertPrice, copyUrlToBoard} from '@/components/common/common.functions'
import PB, {SimplePB} from "@/libs/simplePB";

import style from '@/style/components/accountCenter/account.bindWeChatAccountModal.less';
import { color } from 'highcharts';

const { TabPane } = Tabs;

class MemberUpgradeModal extends React.Component {
  wxLoginHandler = undefined;

  state = {
    activeKey:'member_3',
    product_idx:2,
    level:3
  };

  shak=false;

  payMemberModal = null;
  setTimeoutCheckState = null;
  checkStateNum = 0;

  TabPaneList = [
    {key:'member_1',level:1,name:"免费用户",icon:'icon-flash-lamp',price:0,content:'免费使用炬图平台。\n最多可创建2个图谱。\n每日可打开图谱2张。\n每日可使用AI问答功能5次。\n'},
    {key:'member_2',level:2,name:"粉丝用户",icon:'icon-company',price:5700,content:'最多可创建10个图谱。\n每日可打开图谱10张。\n每日可使用AI问答功能10次。\n'},
    {key:'member_3',level:3,name:"专家用户",icon:'icon-relative-graph',price:12600,content:'最多可创建50个图谱。\n每日可打开图谱50张。\n每日可使用AI问答功能50次。\n'},
    {key:'member_4',level:100,name:"企业用户",icon:'icon-child-relationship_3',price:-1,content:'图谱数量、每日可打开图谱数量、每日可使用AI问答次数均根据企业需求定制。\n'}
  ];

  vip_content = '详情咨询，请添加炬图客服官方微信（微信号：JoinmapAI）。';

  onTabChange = activeKey => {
    let me = this;
    if (activeKey !== me.state.activeKey) {
      me.setState({activeKey,level:me.getProductLevel(activeKey)});
    }
  };

  getLevelPrice = (level) => {
    let me = this;
    let price = 0;
    me.TabPaneList.forEach((item, idx) => {
      if(item.level==level){
        price = item.price;
      }
    });
    return price;
  };

  getLevelName = (level) => {
    let me = this;
    let name = "免费用户";
    me.TabPaneList.forEach((item, idx) => {
      if(item.level==level){
        name = item.name;
      }
    });
    return name;
  };

  getProductLevel = (product) => {
    let me = this;
    let level = 0;
    me.TabPaneList.forEach((item, idx) => {
      if(item.key==product){
        level = item.level;
      }
    });
    return level;
  };


  getPayPrice = (level) => {
    let me = this;
    let pay_price = 0;
    if(level>1 && level<100 && me.props.level>1 && me.props.level<100 && level != me.props.level){
      let dif_day = moment().diff(moment(me.props.level_info.expire_time), 'days');
      pay_price = me.getLevelPrice(level) - dif_day * me.getLevelPrice(me.props.level)/30;
    }else{
      pay_price = me.getLevelPrice(level);
    }
    return pay_price;
  };

  /**
     * 购买会员
     * @param member_type 所购买的会员等级
     * @param callback 购买后的回调 true=购买成功，false=购买失败
     */
  payMember = (level) => {
    let me = this;
    if(me.shak){
      return ;
    }
    me.shak = true;
    let params ={
      trade_type:0,
      order_type: 0,
      product_id: me.state.activeKey,
      id_user: localStorage.getItem('userId')
    }
    API_TradeOrderCreat(params).then((response) => {
      if(response && response.data){
        if(response.data.code === 0){
          let trade = response.data.data;
          if(trade.total_fee==0){
            me.doDeductionMemberModal(trade);  //确认改变会员等级用到期时间抵扣
          }else{
            me.doPayMemberModal(trade); //扫码支付
          }
        }else{
          message.info(response.data.msg, 3);
        }
      }else{
        message.info('网络不稳定，请稍后重新发起购买请求。', 3);
      }
    }).catch((e) => {
      console.log('common.viewManager->payView->获取订单出错 e=', e);
      // console.log('common.viewManager->payView->获取订单出错 view=', view)
      // message.error('网络不稳定，请在窗口关闭后重做上架操作。...', 3).then(() => payViewModal.destroy())
      message.error('网络不稳定，请稍后重新发起购买请求。', 3)
      //callback && callback({action: 'pay', viewId: view.viewId, success: false})
    });
    setTimeout(()=>{
      me.shak=false;},1000);
  }

  doDeduction = (trade_no) => {
    let me = this;
    if(me.shak){
      return ;
    }
    me.shak = true;
    let params ={
      trade_no:trade_no,
      id_user: localStorage.getItem('userId')
    }
    API_UserLevelChanger(params).then((response) => {
      if(response && response.data){
        if(response.data.code === 0){
          message.info('操作成功！'+me.vip_content);
          me.props.onCancel();
          PB.emit('account', 'get.user_level', {});
        }else{
          message.info(response.data.msg, 3);
        }
      }else{
        message.info('网络不稳定，请稍后重新发起购买请求。', 3);
      }
    }).catch((e) => {
      message.error('网络不稳定，请稍后重新发起购买请求。', 3)
    });
    setTimeout(()=>{
      me.shak=false;},1000);
  }

  doDeductionMemberModal  = (trade) => {
    let me = this;
    if(me.state.level != trade.level){
      message.error('校验结果异常，请重新操作。', 3);
      return ;
    }
    let op_name = me.state.level>me.props.level?"升级":"降级";
    const deductionMemberModal = Modal.confirm({
      centered: true,
      title: "会员"+op_name,
      icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
      okText: "认确"+op_name,
      cancelText: "取消",
      width: 650,
      content:
          <div style={{
              height: '100%',
              width: '100%',
              minHeight: '300px',
              paddingTop: '0px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
          }}>
              <div style={{
                  flex: '1',
                  height: '100%',
                  display: 'flex',
                  paddingTop: '20px'
                  //alignItems: 'center',
              }}>
                  <div style={{flex: '1',lineHeight:2}}>
                      <div>订单：<b>{trade.trade_no}</b></div>
                      <div>标题：<b>{"炬图-会员"+op_name+"-"+me.getLevelName(trade.level)}</b></div>
                      <div>价格：<b>0.00元(<span style={{textDecoration:'line-through'}}>{convertPrice(trade.product_price)}元</span>)</b></div>
                      <div>说明：<b>原会员等级{me.getLevelName(me.props.level)}，到期时间：{trade.user_expire_days.substring(0,10)}。
                        {op_name}后，会员等级{me.getLevelName(trade.level)}，到期时间：{trade.expire_time.substring(0,10)}。</b></div>
                      <div><br/></div>
                  </div>
                 
              </div>
          </div>,
      onOk: () => {
        me.doDeduction(trade.trade_no);
        deductionMemberModal.destroy();
        //me.props.onCancel();
      },
      onCancel: () => {
        message.warning('您取消了'+op_name+'操作')
        deductionMemberModal.destroy();
      },
    })
  }

  doPayMemberModal  = (trade) => {
    let me = this;
    let op_name = me.state.level>me.props.level?"升级":(me.state.level==me.props.level?"续费":"降级");
    me.payMemberModal = Modal.confirm({
      centered: true,
      title: "购买会员",
      icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
      okText: "我已支付成功",
      cancelText: "取消",
      width: 650,
      content:
          <div style={{
              height: '100%',
              width: '100%',
              minHeight: '300px',
              paddingTop: '0px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
          }}>
              <div style={{
                  flex: '1',
                  height: '100%',
                  display: 'flex',
                  paddingTop: '20px'
                  //alignItems: 'center',
              }}>
                  <div style={{flex: '1',lineHeight:2}}>
                      <div>订单：<b>{trade.trade_no}</b></div>
                      <div>标题：<b>{"炬图-会员"+op_name+"-"+me.getLevelName(trade.level)}</b></div>
                      {trade.total_fee == trade.product_price && <div>价格：<b>{convertPrice(trade.total_fee)}元</b></div>}
                      {trade.total_fee != trade.product_price && <div>价格：<b>{convertPrice(trade.total_fee)}元(<span style={{textDecoration:'line-through'}}>{convertPrice(trade.product_price)}</span>元)</b></div>}
                      <div>说明：<b>原会员等级{me.getLevelName(me.props.level)}
                        {me.props.level>1 && <span>，到期时间：{trade.user_expire_days.substring(0,10)}</span>}。
                        {op_name}后，会员等级{me.getLevelName(trade.level)}，到期时间：{trade.expire_time.substring(0,10)}。</b></div>
                      <div><br/></div>
                  </div>
                  <div style={{flex: '0 1 35%', textAlign: 'center'}}>
                      <span><Icon name="wechat" style={{fontSize: '1rem', color: 'green'}}/> 微信扫码支付</span>
                      <div style={{
                          padding: '10px 10px 5px 10px ',
                          margin: '0 auto',
                          maxWidth: '150px',
                          textAlign: 'center',
                      }}>
                          <QRCode value={trade.code_url} title={'请使用微信扫码支付'}></QRCode>
                      </div>
                      <span>交易金额：<b style={{color: 'red'}}>{convertPrice(trade.total_fee)} 元</b><br/> </span>
                  </div>
              </div>
              <div style={{flex: '0 1 30%'}}>
                  <div>
                      <Icon name='exclamation-circle'/> 若手机支付<b style={{color: 'red'}}>成功</b>，请点击 <b
                      style={{color: 'red'}}>【我已支付成功】</b> 按钮。
                  </div>
                  <div>
                      <Icon name='exclamation-circle'/> 若手机支付<b style={{color: 'red'}}>失败</b>，请点击 <b
                      style={{color: 'red'}}>【取消】</b>重新购买。
                  </div>
              </div>
          </div>,
      onOk: () => {
        if(me.setTimeoutCheckState != null){
          clearTimeout(me.setTimeoutCheckState);
          me.setTimeoutCheckState = null;
        }
        me.checkStateNum = 0;
        me.checkPayState(trade.trade_no,true);
        me.payMemberModal.destroy();
        me.payMemberModal = null;
      },
      onCancel: () => {
        message.warning('您取消了支付操作')
        me.payMemberModal.destroy();
        me.payMemberModal = null;
        if(me.setTimeoutCheckState != null){
          clearTimeout(me.setTimeoutCheckState);
          me.setTimeoutCheckState = null;
        }
      },
    })
    me.checkStateNum = 0;
    me.setTimeoutCheckState = setTimeout(()=>{me.checkPayState(trade.trade_no,false);},3000);
  }

  checkPayState = (trade_no,op=false) => {
    let me = this;
    me.checkStateNum = me.checkStateNum+1;
    let params ={
      trade_no:trade_no,
      id_user: localStorage.getItem('userId')
    }
    API_TradeOrderCheckState(params).then((response) => {
      if(response && response.data){
        if(response.data.code === 0){
          if(response.data.data.trade_no==trade_no){
            if(response.data.data.state==1){
              message.info('已支付成功！'+me.vip_content);
              me.props.onCancel();
              PB.emit('account', 'get.user_level', {});
              if(me.payMemberModal != null){
                me.payMemberModal.destroy();
                me.payMemberModal = null;
              }
              if(me.setTimeoutCheckState != null){
                clearTimeout(me.setTimeoutCheckState);
                me.setTimeoutCheckState = null;
              }
              if(me.props.up_by=='show_map'){
                //打开图谱时刷新页面
                window.location.reload();
              }
            }else{
              let checkNum = op?30:300;
              if(me.checkStateNum < checkNum){
                me.setTimeoutCheckState = setTimeout(()=>{me.checkPayState(trade_no,op);},op?1000:3000);
              }
            }
          }
        }else{
          if(op){
            message.info(response.data.msg, 3);
          }else{
            if(me.checkStateNum<20){
              me.setTimeoutCheckState = setTimeout(()=>{me.checkPayState(trade_no,op);},3000);
            }
          }
        }
      }else{
        if(op){
          message.info('网络不稳定，请稍后再试。', 3);
        }else{
          if(me.checkStateNum<10){
            me.setTimeoutCheckState = setTimeout(()=>{me.checkPayState(trade_no,op);},3000);
          }
        }
      }
    }).catch((e) => {
      if(op){
        message.info('网络不稳定，请稍后再试。', 3);
      }else{
        if(me.checkStateNum<10){
          me.setTimeoutCheckState = setTimeout(()=>{me.checkPayState(trade_no,op);},3000);
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
    if (prevProps.level !== this.props.level) {
      let me = this;
      if(this.props.level<2){
        me.setState({activeKey:'member_2',level:me.getProductLevel('member_2')});
      }else{
        me.setState({activeKey:'member_3',level:me.getProductLevel('member_3')});
      }
    }
  }

  componentDidMount() {
    let me = this;
    PB.emit('account', 'get.user_level', {});
    //me.props.bus.sub(me, 'upgrade', 'set.user_level', (data) => {
    //  me.setUserLevel(data);
    //});
  }

  componentWillUnmount() {
    //this.props.bus.remove(this);
  }

  render() {
    let me = this;
    //<Button key="close" onClick={me.props.onCancel}>关闭</Button>,
    let user_level_text = me.props.level>1?me.props.level_name+'，到期日期：'+me.props.level_info.expire_time.substring(0,10):me.props.level_name;
    return (
      <Modal
        width={800}
        centered={true}
        visible={me.props.visible}
        title={'会员升级（'+user_level_text+'）'}
        footer={null}
        destroyOnClose={true}
        onCancel={me.props.onCancel}
      >
        <div style={{ height: '28.2rem',fontSize:'1.2rem' }}>
          <Tabs onChange={me.onTabChange} activeKey={me.state.activeKey}>
            {me.TabPaneList.map((item) => (
              <Tabs.TabPane key={item.key} tab={(<span><Icon type={IconTypes.ICON_FONT} name={item.icon} /><b>{item.name}</b></span>)}
                forceRender={true} className={`${style['market-drawer-body-tab-content']} scrollbar scrollbar-none`} >
                <div style={{height: '20rem'}}>
                  <p style={{marginTop:'1rem',fontSize:'1.2rem',textAlign:'center'}}>{item.name}</p>
                  {item.price>=0 && <p style={{fontSize:'1.2rem',textAlign:'center'}}><span style={{fontSize:'3rem',color:'rgb(52, 152, 219)'}}>¥{(item.price/100).toFixed(2)}</span><span>元/30天</span></p>} 
                  <p style={{fontSize:'1rem',marginTop:'1rem',marginLeft:'2rem'}}>会员权益：</p>
                  <p style={{fontSize:'1rem',whiteSpace:'pre-wrap',marginLeft:'3rem',lineHeight:'2rem'}}>{item.content}{me.props.level>1 && item.level>1 && me.vip_content}</p>
                </div>
                <div style={{textAlign:'right'}}>
                {me.props.level<100 && item.price > 0 && me.props.level<item.level &&  <Button type={'primary'} onClick={() => me.payMember(item.level)} shape="round" size="large">立即升级</Button>}
                {me.props.level<100 && item.price > 0 && me.props.level==item.level && <Button type={'primary'} onClick={() => me.payMember(item.level)} shape="round" size="large">立即续费</Button>}
                {me.props.level<100 && item.price > 0 && me.props.level>item.level && <Button type={'primary'} onClick={() => me.payMember(item.level)} shape="round" size="large">立即降级</Button>}
                {item.price === -1 && <Button type={'primary'} href="mailto:hi@joinmap.ai?subject=会员升级为企业用户" shape="round" size="large">联系hi@joinmap.ai</Button>}
                </div>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
      </Modal>
    );
  }
}

MemberUpgradeModal.defaultProps = {
  bus: PB,
};

MemberUpgradeModal.propType = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
  level: PropTypes.number
};

export default MemberUpgradeModal;