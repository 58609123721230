import {
  API_InsightDetailInfoByNode,
  API_InsightDetailPropertiesByNode,
  API_InsightDetailTimelineByNode,
  API_InsightDetailRelationByNode,
  API_InsightDetailImagesByNode,
  API_GetNodeInfo,
  API_MatchTagsByNode,
  API_ExpandNode,
  API_LoadNodeListByUser,
  API_BatchLoadNodeAmountStatistics,
  API_LoadNodeTaskList,
  API_CreateNodeTask,
  API_LoadNodeTask,
  API_UpdateNodeTask,
  API_PatchNodeTask,
  API_DoneNodeTask,
  API_ExploreRelatedNodeByNode,
  API_ExploreOverrideByNode,
  API_AddNodeAssess,
  API_GetNodeAssessList
} from "@/libs/view/network/map_v2_api";
import Node from '@/libs/view/Node';
import {bindUtil} from "@/libs/core-decorators";
import {getErrorInfo as commonGetErrorInfo, longestCommonSubstring} from "@/components/common/common.functions";
import {httpUtilWithNoMsg as httpUtil} from "@/utils/HttpUtil";
import {API_CallMicroService} from "@/api/microService";

// 获取单节点匹配标签(词云)
const API_MatchTagsByKeyword = (viewId, keyword, flag = 0) => {
  return httpUtil.get(`/view/public/match/tags`, {viewId, keyword, flag: `${flag}`});
};

// 获取两个节点之间的简报
const API_LoadBriefingInfoBetweenNodes = (from, to) => {
  return httpUtil.get(`/view/relation/briefing`, {
    from_node_json: JSON.stringify(from),
    to_node_json: JSON.stringify(to),
  });
};

// 左右组词
const API_ExpandWord = (word) => {
  return httpUtil.get(`/view/calc/expand_by_word`, {word});
};

// 依照词频统计
const API_StatisticsByWordFrequency = (nodes, limit) => {
  return httpUtil.post(`/view/calc/node_statistics/by_word`, nodes, {limit});
};

// 依照节点列表推荐特定类型的节点详情
const API_RecommendDetailByNodeList = (nodes, detailType) => {
  return httpUtil.post(`/view/calc/recommend_detail_by_nodes`, nodes, {detail_type: detailType});
};

@bindUtil.asSourceClass
class NodeDataProvider {
  static exploreRelatedNode = (nodeInfo) => {
    return new Promise((resolve, reject) => {
      API_ExploreRelatedNodeByNode(nodeInfo).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  static exploreOverride = (nodeInfo) => {
    return new Promise((resolve, reject) => {
      API_ExploreOverrideByNode(nodeInfo).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  static recommendNodes = (viewId, nodeId, start = 0) => {
    return new Promise((resolve, reject) => {
      let request = {target: {nodes: [nodeId]}, parameters: {}, start, limit: 1};
      API_CallMicroService('c2f808b9-a992-46cb-bd85-c33d7a2d5eae', viewId, request).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data[0]['graphs'][0] ? response.data.data[0]['graphs'][0].nodes : []);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  static loadInsightDetailInfo = (nodeId, nodeType) => {
    return new Promise((resolve, reject) => {
      API_InsightDetailInfoByNode(nodeId, nodeType).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取节点属性详情
   *
   * @param {string} nodeId 节点ID
   * @param {number} nodeType 节点类型
   * @param {number} [preferredType] 预期（智能识别）的节点类型
   * @param {array} [relatedTags] 相关关键词
   * @return {Promise}
   */
  static loadInsightDetailProperties = (nodeId, nodeType, preferredType = undefined, relatedTags = []) => {
    preferredType = preferredType === undefined ? nodeType : preferredType;
    return new Promise((resolve, reject) => {
      API_InsightDetailPropertiesByNode(nodeId, nodeType, preferredType, relatedTags).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取节点时间线详情
   *
   * @param {string} nodeId 节点ID
   * @param {number} nodeType 节点类型
   * @param {number} [preferredType] 预期（智能识别）的节点类型
   * @param {array} [relatedTags] 相关关键词
   * @return {Promise}
   */
  static loadInsightDetailTimeline = (nodeId, nodeType, preferredType = undefined, relatedTags = []) => {
    preferredType = preferredType === undefined ? nodeType : preferredType;
    return new Promise((resolve, reject) => {
      API_InsightDetailTimelineByNode(nodeId, nodeType, preferredType, relatedTags).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data['timeLine']);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取节点时间线详情
   *
   * @param {string} nodeId 节点ID
   * @param {number} nodeType 节点类型
   * @param {number} [preferredType] 预期（智能识别）的节点类型
   * @param {array} [relatedTags] 相关关键词
   * @param {object} [override] 覆盖参数
   * @return {Promise}
   */
  static loadInsightDetailRelations = (
    nodeId, nodeType, preferredType = undefined, relatedTags = [], override = undefined
  ) => {
    // 这里不再自动赋值，寻找关系时预期资源类型不一定与当前节点代表的类型一致
    return new Promise((resolve, reject) => {
      API_InsightDetailRelationByNode(nodeId, nodeType, preferredType, relatedTags, override).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取节点图像详情
   *
   * @param {string} nodeId 节点ID
   * @param {number} nodeType 节点类型
   * @param {number} [preferredType] 预期（智能识别）的节点类型
   * @param {array} [relatedTags] 相关关键词
   * @return {Promise}
   */
  static loadInsightDetailImages = (nodeId, nodeType, preferredType = undefined, relatedTags = []) => {
    preferredType = preferredType === undefined ? nodeType : preferredType;
    return new Promise((resolve, reject) => {
      API_InsightDetailImagesByNode(nodeId, nodeType, preferredType, relatedTags).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  static loadBriefingListByNodes = (nodes) => {
    return new Promise((resolve, reject) => {
      let from = {id: nodes[0].id, type: nodes[0].type, fname: nodes[0].fname};
      let to = {id: nodes[1].id, type: nodes[1].type, fname: nodes[1].fname};
      API_LoadBriefingInfoBetweenNodes(from, to).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取节点详情
   *
   * @param {string} viewId 图谱ID
   * @param {number} nodeType 节点类型
   * @param {string} nodeId 节点ID
   * @return {Promise}
   */
  static loadNodeDetail = (viewId, nodeType, nodeId) => {
    return new Promise((resolve, reject) => {
      API_GetNodeInfo(viewId, nodeType, nodeId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(new Node(response.data.data));
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取与指定节点相关联的一组词（词云）
   *
   * @param {string} viewId 图谱ID
   * @param {string} [nodeId] 节点ID
   * @param {string} [text] 节点文本
   * @param [flag] 步进标签
   * @return {Promise}
   */
  static loadMatchedTags = (viewId, nodeId, text = undefined, flag = 0) => {
    return new Promise((resolve, reject) => {
      (
        nodeId ? API_MatchTagsByNode(viewId, nodeId, text, flag) : API_MatchTagsByKeyword(viewId, text, flag)
      ).then(response => {
        if (response && response.data && response.data.code === 0) {
          let tags = [];
          const wordCloudData = response.data.data;
          for (let wordIdx = 0; wordIdx < wordCloudData['tags'].length; wordIdx++) {
            tags.push({
              text: wordCloudData['tags'][wordIdx],
              value: wordCloudData['values'][wordIdx],
              longestLength: undefined,
            });
          }
          tags = tags.sort((a, b) => {
            let result = b.value - a.value;
            if (result === 0 && text) {
              if (a.longestLength === undefined) {
                a.longestLength = longestCommonSubstring(a.text, text);
              }
              if (b.longestLength === undefined) {
                b.longestLength = longestCommonSubstring(b.text, text);
              }
              result = b.longestLength - a.longestLength;
            }
            return result;
          });
          resolve({nodeId, tags, type: wordCloudData['type'] || -1, flag: wordCloudData['round']});
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取与指定节点相关联的线索
   *
   * @param {string} viewId 图谱ID
   * @param {string} nodeId 节点ID
   * @param {number} [size] 期望返回的节点数量
   * @return {Promise}
   */
  static loadRelatedClue = (viewId, nodeId, size = 10) => {
    return new Promise((resolve, reject) => {
      API_ExpandNode(viewId, nodeId, size).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data['nodes']);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 通过用户ID获取节点列表
   *
   * @param {number} userId 用户ID
   * @param {string} [afterDate] 仅获取指定日期以后的数据
   * @return {Promise}
   */
  static loadNodeListByUser = (userId, afterDate = undefined) => {
    return new Promise((resolve, reject) => {
      API_LoadNodeListByUser(userId, afterDate).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 批量获取节点数量统计信息
   *
   * @param {NodeAmountStatisticsRequest[]} statisticsRequestList
   */
  static batchLoadNodeAmountStatistics = statisticsRequestList => {
    return new Promise((resolve, reject) => {
      API_BatchLoadNodeAmountStatistics(statisticsRequestList).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取节点任务列表
   *
   * @param {Object} parameters 查询参数
   */
  static loadNodeTaskList = (parameters) => {
    return new Promise((resolve, reject) => {
      API_LoadNodeTaskList(parameters).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 创建节点任务
   *
   * @param {Object} task 任务ID
   */
  static createNodeTask = (task) => {
    return new Promise((resolve, reject) => {
      API_CreateNodeTask(task).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取节点任务
   *
   * @param {string} nodeTaskId 任务ID
   */
  static loadNodeTask = (nodeTaskId) => {
    return new Promise((resolve, reject) => {
      API_LoadNodeTask(nodeTaskId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 更新节点任务
   *
   * @param {Object} task 任务ID
   */
  static updateNodeTask = (task) => {
    return new Promise((resolve, reject) => {
      API_UpdateNodeTask(task).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 部分更新节点任务
   *
   * @param {Object} task 任务ID
   *
   * @return {Promise}
   */
  static patchNodeTask = (task) => {
    return new Promise((resolve, reject) => {
      API_PatchNodeTask(task).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 完成节点任务
   *
   * @param {string} nodeTaskId 任务ID
   *
   * @return {Promise}
   */
  static completeNodeTask = (nodeTaskId) => {
    return new Promise((resolve, reject) => {
      API_DoneNodeTask(nodeTaskId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 左右组词
   *
   * @param {string} word 组词词根
   *
   * @return {Promise}
   */
  static expandWord = (word) => {
    return new Promise((resolve, reject) => {
      API_ExpandWord(word).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 统计指定节点的词频
   *
   * @param {Object[]} nodes 要统计的节点
   * @param {number} limit 限制返回结果数
   *
   * @return {Promise}
   */
  static loadWordStatisticsResult = (nodes, limit) => {
    return new Promise((resolve, reject) => {
      API_StatisticsByWordFrequency(nodes, limit).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    })
  };

  /**
   * 依照节点列表推荐特定类型的节点详情
   *
   * @param {Object[]} nodes 节点信息列表
   * @param {number} detailType 指定推荐的节点类型
   *
   * @return {Promise}
   */
  static recommendDetailByNodeList = (nodes, detailType) => {
    return new Promise((resolve, reject) => {
      API_RecommendDetailByNodeList(nodes, detailType).then(response => {
        if (response && response.data && response.data.code === 0) {
          let result = {};
          (response.data.data || []).forEach(node => {
            if (!result[node.id]) {
              result[node.id] = [];
            }
            result[node.id].push(node);
          });
          resolve(result);
        } else {
          reject(NodeDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(NodeDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * @private
   * 通過Axios请求返回的错误信息获取错误详情
   *
   * @param {*} error
   *
   * @return {{msg: string, code: number}}
   */
  static getErrorInfo = commonGetErrorInfo;

  /**
   * 新建节点的子弹评估
   *
   * @param {string} viewId 看板id
   * @param {string} nodeId 节点id
   * @param {object} data 评估数据
   * @param {object} params 其他参数
   *
   * @return {Promise}
   */
     static createNodeAssess = (viewId,nodeId,data, params) => {
      return new Promise((resolve, reject) => {
        API_AddNodeAssess(viewId,nodeId,data, params).then(response => {
          if (response && response.data && response.data.code === 0) {
            // 返回数据处理
            let result = response.data
            resolve(result);
          } else {
            reject(NodeDataProvider.getErrorInfo(response));
          }
        }).catch(error => {
          reject(NodeDataProvider.getErrorInfo(error));
        });
      });
    };
  
  /**
   * 查询节点的子弹评估列表
   * 支持分页
   *
   * @param {string} viewId 看板id
   * @param {string} nodeId 节点id
   * @param {object} params 其他参数，如：分页、查询条件
   *
   * @return {Promise}
   */
     static loadNodeAssessByNodeId = (viewId,nodeId, params) => {
      return new Promise((resolve, reject) => {
        API_GetNodeAssessList(viewId,nodeId, params).then(response => {
          if (response && response.data && response.data.code === 0) {
            // 返回数据处理
            let result = response.data
            resolve(result);
          } else {
            reject(NodeDataProvider.getErrorInfo(response));
          }
        }).catch(error => {
          reject(NodeDataProvider.getErrorInfo(error));
        });
      });
    };
  
  
}

export default NodeDataProvider;