import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Tooltip, Tree} from 'antd';
import {Element as ScrollElement} from 'react-scroll';

import PB, {SimplePB} from '@/libs/simplePB';
import MyDiffViewer from '@/libs/myDiffViewer/MyDiffViewer';
import {NetworkDataLoadingStatus} from "@/libs/view/network/status";
import {NetworkEvents} from "@/libs/view/network/events";

import {IconTypes} from '@/constants/common';
import {defaultDefine, getNodeDisplayTitle, getNodeIcon} from '@/constants/vis.defaultDefine.1';
import {getNodeIconType, ICON_CATEGORY_TIP_B, ICON_CATEGORY_TIP_H, iconConfig} from '@/constants/iconConfig';

import Icon from '@/components/common/common.icon';
import {scrollToEnd} from '@/components/common/common.functions';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

import style from '@/style/common/microService/common.microService.less';

const OPERATION = 'saveContent';

const replacementTitle = {
  fname: '更新名称',
  description: '更新描述',
  url: '更新链接',
  tag: '更新标签',
  position: '更新位置',
  icon: '更新标记',
}

const contentIconConfig = (iconConfig => {
  let result = {};
  Object.keys(iconConfig).forEach(key => {
    result[key] = iconConfig[key].iconProperty;
    if (key === ICON_CATEGORY_TIP_H) {
      result[key].color = defaultDefine.colors.level0; // 颜色调整
    } else if (key === ICON_CATEGORY_TIP_B) {
      result[key].color = '#bbb'; // 颜色调整
    }
  });
  return result;
})(iconConfig);

class MicroServicePanelResultSaveContent extends React.PureComponent {
  state = {
    // 结果数据及交互参数
    resultContainerId: `micro-service-modal-result-${Math.random()}`,
    resultTargetMap: {},
    dataTree: [],
    dataTreeMap: {},
    availableNodeAmount: 0,
    checkedKeys: [],
    expandedKeys: [],

    // 对象状态
    objectStatus: {},

    // 结果保存参数
    savingStatus: 'idle',

    dataMsg: {
      msgStatus: -2,
      msg: ''
    }
  };

  autoScroll = true;

  autoScrolling = undefined;

  /**
   * 获取树节点标题
   *
   * @param {Object} item 树节点数据
   * @param {Object} target 目标对象数据，一般是节点
   * @return {JSX.Element|string}
   */
  getTreeNodeTitle = (item, target = undefined) => {
    let me = this, displayTitle, tooltipTitle, targetNode;

    switch (item.renderAs) {
      case 'view':
        if (item.viewId !== me.props.viewDataProvider.viewId) return '';
        target = target || me.state.resultTargetMap[item.viewId] || me.props.viewDataProvider.viewInfo;
        if (!target) return '';
        return  (<span>看板：{item.name}</span>);
      case 'node':
        // resultTargetMap里并不一定是通过节点ID为KEY保存数据，但只要不是，则节点必然存在于看板中
        target = target || me.state.resultTargetMap[item.nodeId] || me.props.viewDataProvider.getNode(item.nodeId);
        if (!target) return '';
        displayTitle = getNodeDisplayTitle(target);

        if (me.state.objectStatus[item.uid] && me.state.objectStatus[item.uid].status === 'invalid') {
          return (<span>{displayTitle}</span>);
        }

        return ((target.tags && target.tags.length > 0) || target.description) ? (
          <Tooltip
            placement={'right'}
            title={(
              <pre
                style={{whiteSpace: 'pre-wrap', marginBottom: 0}}
                onClick={e => e.stopPropagation()}
                onMouseUp={e => e.stopPropagation()}
              >
                {
                  target.tags && target.tags.length > 0 ? (
                    <span>{'[' + target.tags.slice(0, 3).join(' ') + '] '}<br /></span>
                  ) : ''
                }{
                  target.description ? target.description.split('\n').map((line, idx) => (
                    <span key={`ln-${idx}`}>{line}<br /></span>
                  )) : ''
                }
              </pre>
            )}
            overlayClassName={'light-theme'}
            mouseLeaveDelay={0.05}
          >
            <span>{displayTitle}</span>
          </Tooltip>
        ) : (<span>{displayTitle}</span>);
      case 'content':
        if (me.state.objectStatus[item.uid] && me.state.objectStatus[item.uid].status === 'invalid') {
          return '<节点已删除>';
        }
        let contentInfo = me.state.resultTargetMap[item.contentId];
        if (!contentInfo) return '';
        targetNode = me.props.viewDataProvider.getNode(contentInfo.targetNodeId);
        if (!targetNode) return '';
        displayTitle = replacementTitle[contentInfo.type];

        switch (contentInfo.type) {
          case 'icon':
            let oldIcon, newIcon;
            if (targetNode._imageType) {
              // 自定义图标
              oldIcon = (<img src={targetNode.meta.iconData} alt={'原图标'} className={targetNode._imageType} />);
            } else {
              // 内置图标
              oldIcon = <Icon {...(contentIconConfig[getNodeIconType(targetNode)])} className={style['content-icon']} />
            }
            if (contentInfo.replacementNodeRef._imageType) {
              // 自定义图标
              newIcon = (
                <img
                  src={contentInfo.replacementNodeRef.meta.iconData}
                  alt={'新图标'}
                  className={contentInfo.replacementNodeRef._imageType}
                />
              );
            } else {
              // 内置图标
              newIcon = <Icon {...(contentIconConfig[getNodeIconType(contentInfo.replacementNodeRef)])} className={style['content-icon']} />
            }
            tooltipTitle = (
              <div className={style['content-icon-frame']}>
                {oldIcon}
                <Icon name={'icon-move-right'} type={IconTypes.ICON_FONT} style={{padding: '0 0.5em', opacity: 0.7}} />
                {newIcon}
              </div>
            );
            break;
          case 'fname':
          case 'url':
          case 'tag':
          case 'description':
            let oldText = targetNode[contentInfo.type];
            let newText = contentInfo.replacementNodeRef[contentInfo.type];
            tooltipTitle = (oldText === newText ? '(无区别)' : (
              <div className={`${style['content-diff-frame']} scroll-bar`}>
                <MyDiffViewer
                  leftTitle={(<span className={style['content-diff-title']}>更新前</span>)}
                  rightTitle={(<span className={style['content-diff-title']}>更新后</span>)}
                  oldValue={oldText}
                  newValue={newText}
                  codeFoldMessageRenderer={(num) => (
                    <pre className={style['content-diff-fold-message']}>展开 {num} 行相同文本 ...</pre>
                  )}
                />
              </div>
            ));
            break;
          case 'position':
            tooltipTitle = (
              <div className={style['content-icon-frame']}>
                {targetNode.fixed ? `固定位置（${Math.round(targetNode.fx * 100) / 100}, ${Math.round(targetNode.fy * 100) / 100}）` : '非固定位置'}
                <Icon name={'icon-move-right'} type={IconTypes.ICON_FONT} style={{padding: '0 0.5em', opacity: 0.7}} />
                {contentInfo.replacementNodeRef.fixed ? `固定位置（${Math.round(contentInfo.replacementNodeRef.fx * 100) / 100}, ${Math.round(contentInfo.replacementNodeRef.fy * 100) / 100}）` : '非固定位置'}
              </div>
            );
            break;
          default:
            return '';
        }

        return (
          <Tooltip
            placement={'right'}
            title={tooltipTitle}
            overlayClassName={'light-theme'}
            overlayStyle={{maxWidth: '42rem'}}
            mouseLeaveDelay={0.05}
          >
            <span>{displayTitle}</span>
          </Tooltip>
        );
      default:
        return '';
    }
  };

  /**
   * 判断树节点是否可被选择
   *
   * @param {Object} treeNode - 树节点
   * @param {Object.<string, TMicroServiceResponseObjectStatus>} [objectStatus] - 对象状态
   * @return {boolean}
   */
  isCheckableTreeNode = (treeNode, objectStatus) => {
    let me = this;

    objectStatus = objectStatus || me.state.objectStatus;

    return objectStatus[treeNode.uid]
      && ['idle', 'selected'].includes(objectStatus[treeNode.uid].status);
  };

  /**
   * 全选或反选操作回调
   */
  onCheckAll = () => {
    let me = this, dataTreeMap = me.state.dataTreeMap;

    me.onItemCheck(me.state.checkedKeys.length < me.state.availableNodeAmount
      ? Object.keys(dataTreeMap).filter(key => me.isCheckableTreeNode(dataTreeMap[key], me.state.objectStatus)): []);
  };

  /**
   * 点击复制选中节点至剪切板的回调
   */
  onCopySelectedNodesToClipboard = () => {
    let me = this;

    me.props.bus.emit('view',
      'micro_service.service.operation_save_content.copy_selected_node_to_clipboard',
      {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId});
  };

  /**
   * 树节点勾选改变后的回调
   *
   * @param {string[]} checkedKeys - 勾选的节点ID列表
   */
  onItemCheck = checkedKeys => {
    let me = this;

    me.props.bus.emit('view',
      'micro_service.service.operation_save_content.tree_node_item_check',
      {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId, checkedKeys});
  };

  /**
   * 微服务数据清空后的回调，主要为重置数据
   */
  onResultCleared = () => {
    let me = this;

    me.setState({
      resultTargetMap: {},
      dataTree: [],
      dataTreeMap: {},
      availableNodeAmount: 0,
      checkedKeys: [],
      expandedKeys: [],

      // 对象状态
      objectStatus: {},

      // 结果保存参数
      savingStatus: 'idle',
      dataMsg: {
        msgStatus: -2,
        msg: ''
      },
    }, () => {
      me.autoScroll = true;
    });
  };

  /**
   * 微服务结果刷新后的回调
   *
   * @param {Object} resultTargetMap - 目标对象ID到数据对应关系
   * @param {Array} dataTree - 树形列表节点数据
   * @param {Object} dataTreeMap - 树形列表节点ID到数据对应关系
   */
  onResultRefreshed = ({resultTargetMap, dataTree, dataTreeMap, dataMsg}) => {
    let me = this;

    me.autoScroll = (me.autoScroll || (me.state.dataTree.length === 0));
    me.setState({
      resultTargetMap,
      dataTree,
      dataTreeMap,
      dataMsg
    }, () => {
      if (me.autoScroll && me.state.dataTree.length > 0) {
        setTimeout(() => {
          if (me.autoScrolling) {
            clearTimeout(me.autoScrolling);
          }
          me.autoScrolling = setTimeout(() => {
            clearTimeout(me.autoScrolling);
            me.autoScrolling = undefined;
          }, 300);
          scrollToEnd(me, me.state.resultContainerId);
          setTimeout(() => {
            if (!me.props.loadingResult && !me.props.hasMoreResult) {
              me.autoScroll = false;
            }
          }, 400);
        }, 500);
      }
    });
  };

  /**
   * 微服务结果统计更新后的回调
   */
  onStatisticsRefreshed = ({objectStatus}) => {
    let me = this;

    me.setState({objectStatus});
  };

  /**
   * 界面状态改变回调，如树形列表被展开，选项被勾选等
   *
   * @param {number} availableNodeAmount - 可勾选的节点数量
   * @param {string[]} checkedKeys - 已勾选的节点ID列表
   * @param {string[]} expandedKeys - 已展开的节点ID列表
   * @param {string} savingStatus - 当前保存操作状态
   */
  onUIStateRefreshed = ({availableNodeAmount, checkedKeys, expandedKeys, savingStatus}) => {
    let me = this;

    me.setState({
      availableNodeAmount,
      checkedKeys,
      expandedKeys,
      savingStatus,
    }, () => {
      me.props.onCheckStatusChanged(checkedKeys.length > 0);
    });
  };

  /**
   * 刷新数据
   */
  refreshData = () => {
    let me = this, emit = () => {
      me.props.bus.emit('view', 'micro_service.service.broadcast_operation_result',
        {viewId: me.props.viewId, serviceId: me.props.microServiceId, operation: OPERATION});
      me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
        {viewId: me.props.viewId, serviceId: me.props.microServiceId, operation: OPERATION});
      me.props.bus.emit('view', 'micro_service.service.broadcast_operation_statistics',
        {viewId: me.props.viewId, serviceId: me.props.microServiceId, operation: OPERATION});
    };

    if (me.props.viewDataProvider.getData().status === NetworkDataLoadingStatus.SUCCESS) {
      emit();
    } else {
      me.props.viewDataProvider.once(me, NetworkEvents.LOADING_DATA_SUCCESS, emit);
    }
  };

  /**
   * 根据节点列表渲染树节点列表
   *
   * @param {Array} items
   * @return {Array}
   */
  renderTreeNodes = items => {
    let me = this;

    return items.map(item => {
      switch (item.renderAs) {
        case 'view':
          if (item.viewId !== me.props.viewDataProvider.viewId) return undefined;
          let view = me.state.resultTargetMap[item.viewId] || me.props.viewDataProvider.viewInfo;
          return view ? (
            <Tree.TreeNode
              title={me.getTreeNodeTitle(item)}
              key={item.id}
              dataRef={item}
              disableCheckbox={false}
              switcherIcon={item.children && item.children.length > 0 ? null :
                (me.state.objectStatus[item.uid] && me.state.objectStatus[item.uid].status === 'invalid' ? <Icon name={'stop'}/> :
                  (me.state.objectStatus[item.uid] && me.state.objectStatus[item.uid].status === 'saved' ? <Icon name={'check-circle'}/> :
                    <Icon name={'icon-node-action'} type={IconTypes.ICON_FONT} style={{opacity: 0.7}} />))}
            >
              {item.children && item.children.length > 0 ? me.renderTreeNodes(item.children) : undefined}
            </Tree.TreeNode>
          ) : undefined;
        case 'node':
          let node = me.state.resultTargetMap[item.nodeId] || me.props.viewDataProvider.getNode(item.nodeId);
          return node ? (
            <Tree.TreeNode
              title={me.getTreeNodeTitle(item)}
              key={item.id}
              dataRef={item}
              disableCheckbox={!me.isCheckableTreeNode(item, me.state.objectStatus)}
              switcherIcon={item.children && item.children.length > 0 ? null :
                (me.state.objectStatus[item.uid] && me.state.objectStatus[item.uid].status === 'invalid' ? <Icon name={'stop'}/> :
                  (me.state.objectStatus[item.uid] && me.state.objectStatus[item.uid].status === 'saved' ? <Icon name={'check-circle'}/> :
                    <Icon {...getNodeIcon(node)} color={undefined} style={{opacity: 0.7}}/>))}
            >
              {item.children && item.children.length > 0 ? me.renderTreeNodes(item.children) : undefined}
            </Tree.TreeNode>
          ) : undefined;
        case 'content':
          return (
            <Tree.TreeNode
              title={me.getTreeNodeTitle(item)}
              key={item.id}
              dataRef={item}
              disableCheckbox={!me.state.objectStatus[item.uid] || ['saved', 'invalid'].includes(me.state.objectStatus[item.uid].status)}
              switcherIcon={(me.state.objectStatus[item.uid] && me.state.objectStatus[item.uid].status === 'invalid' ? <Icon name={'stop'}/> :
                (me.state.objectStatus[item.uid] && me.state.objectStatus[item.uid].status === 'saved' ? <Icon name={'check-circle'}/>
                  : <Icon name={'icon-replace-content'} type={IconTypes.ICON_FONT} style={{opacity: 0.7}}/>))}
            />
          );
        default:
          return undefined;
      }
    });
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('view', 'micro_service.service.result_cleared', data => {
      if (me.props.viewId === data.viewId && me.props.visible && me.props.microServiceId === data.serviceId) {
        me.onResultCleared();
      }
    }).subscribe('view', 'micro_service.service.current_operation_result', data => {
      if (me.props.viewId === data.viewId && me.props.visible && me.props.microServiceId === data.serviceId
        && OPERATION === data.operation) {

        me.onResultRefreshed(data);
      }
    }).subscribe('view', 'micro_service.service.current_operation_ui', data => {
      if (me.props.viewId === data.viewId && me.props.visible && me.props.microServiceId === data.serviceId
        && OPERATION === data.operation) {

        me.onUIStateRefreshed(data);
      }
    }).subscribe('view', 'micro_service.service.current_operation_statistics', data => {
      if (me.props.viewId === data.viewId && me.props.visible && me.props.microServiceId === data.serviceId
        && OPERATION === data.operation) {

        me.onStatisticsRefreshed(data);
      }
    });

    if (me.props.visible) {
      me.refreshData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (!prevProps.visible && me.props.visible) {
      me.refreshData();
      setTimeout(() => scrollToEnd(me, me.state.resultContainerId), 300);
    }
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;

    return (
      <React.Fragment>
        <div className={style['result-tree-toolbar']}>
          <Checkbox
            onClick={me.onCheckAll}
            disabled={me.state.availableNodeAmount <= 0}
            checked={me.state.availableNodeAmount > 0 && me.state.checkedKeys.length === me.state.availableNodeAmount}
          >
            全选
          </Checkbox>
          <Tooltip
            placement={"top"}
            title={"复制选中节点文本"}
          >
            <a
              className={`a-ctrl ${me.state.checkedKeys.length > 0 ? '' : 'disabled-ctrl'}`}
              onClick={me.onCopySelectedNodesToClipboard}
            >
              <Icon name={'snippets'}/>
            </a>
          </Tooltip>
        </div>
        <ScrollElement
          id={me.state.resultContainerId}
          className={`${style['result-tree-frame']} scrollbar`}
          onWheel={() => {
            let element = document.getElementById(me.state.resultContainerId);
            me.autoScroll = (element.scrollHeight - element.scrollTop - element.clientHeight) < 30;
          }}
          onScroll={e => {
            if (me.autoScrolling) {
              clearTimeout(me.autoScrolling);
              me.autoScrolling = setTimeout(() => {
                clearTimeout(me.autoScrolling);
                me.autoScrolling = undefined;
              }, 300);
              return;
            }
            let element = e.target;
            me.autoScroll = (element.scrollHeight - element.scrollTop - element.clientHeight) < 30;
          }}
        >
          <Tree
            className={`scrollbar-none`}
            selectable={false}
            checkable={true}
            expandedKeys={me.state.expandedKeys}
            onExpand={expandedKeys => {
              me.props.bus.emit('view',
                'micro_service.service.operation_save_content.tree_node_item_expand',
                {viewId: me.props.viewId, serviceId: me.props.microServiceId, expandedKeys});
            }}
            checkedKeys={me.state.checkedKeys}
            onCheck={me.onItemCheck}
            switcherIcon={<Icon name="down" />}
          >
            {me.renderTreeNodes(me.state.dataTree)}
          </Tree>
          {
            !me.props.locked ? (
              <div className={style['infinite-scroll-loading-empty']} key={0}>
                <span style={{opacity: 0.7}}><Icon name="clock-circle" style={{marginRight: '0.5em'}} />就绪，请点击 <b>开始计算</b> 按钮</span>
              </div>
            ) : (
              me.state.dataMsg && me.state.dataMsg.msgStatus && me.state.dataMsg.msgStatus == 1?(
                <div className={style['infinite-scroll-loading-empty']} key={0}>
                  <span style={{whiteSpace:'pre-wrap',textAlign:'left'}}><Icon name={'exclamation-circle'} theme={'outlined'} style={{marginRight: '0.5em'}} />{me.state.dataMsg.msg}</span>
                </div>):
              // 加载尚未启动或正在加载中
              (me.props.hasMoreResult && me.props.autoLoadMore && !me.props.lastLoadingFailed) || me.props.loadingResult ? (
                <div
                  className={
                    style[me.state.dataTree.length <= 0 ? 'infinite-scroll-loading-empty' : 'infinite-scroll-loading']
                  }
                  key={0}
                >
                  <span><Icon name="loading" style={{marginRight: '0.5em'}} />计算中，请稍后...</span>
                </div>
              ) : (
                (!me.props.loadingResult && me.props.lastLoadingFailed && me.props.autoLoadMore) ? (
                  <div
                    className={
                      style[me.state.dataTree.length <= 0 ? 'infinite-scroll-loading-empty' : 'infinite-scroll-loading']
                    }
                    key={0}
                  >
                      <span>
                        <Icon name={'exclamation-circle'} theme={'outlined'} style={{marginRight: '0.5em'}} />
                        数据计算失败，请点击 <b>重新计算</b> 按钮
                      </span>
                  </div>
                ) : (
                  me.state.dataTree.length <= 0 ? (
                    <div className={style['infinite-scroll-loading-empty']} key={0}>
                      <span><Icon name={'exclamation-circle'} theme={'outlined'} style={{marginRight: '0.5em'}} />计算完成，没有更多的结果了</span>
                    </div>
                  ) : undefined
                )
              )
            )
          }
        </ScrollElement>
      </React.Fragment>
    );
  }
}

MicroServicePanelResultSaveContent.defaultProps = {
  bus: PB,
  visible: false,
};

MicroServicePanelResultSaveContent.propTypes = {
  locked: PropTypes.bool.isRequired,
  autoLoadMore: PropTypes.bool.isRequired,
  hasMoreResult: PropTypes.bool.isRequired,
  lastLoadingFailed: PropTypes.bool.isRequired,
  loadingResult: PropTypes.bool.isRequired,
  onCheckStatusChanged: PropTypes.func.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  bus: PropTypes.instanceOf(SimplePB),
  microServiceId: PropTypes.string,
  viewId: PropTypes.string,
  visible: PropTypes.bool,
};

export default MicroServicePanelResultSaveContent;