import React from 'react';
import { connect } from "react-redux";
import {Route, Redirect, Switch} from "react-router-dom";
import Loading from "@/components/common/common.loading";
import layoutStyles from "@/style/default/defaultViewLayout.less";
import Tree from '@/components/demonstration/tree';

class Demonstration extends React.Component {
  state = {
  };

  render() {
    return (
      <div className={layoutStyles["layout-wrap"]}>
        <Loading />
        <div className={layoutStyles["header"]}>
          <div className={layoutStyles["logo"]}>
            <img src={'/assets/logo-hans.png'} alt={"炬图"}
              onClick={() => { this.props.history.push({pathname: "/index",state: { type: "intro" }});}} />
          </div>
        </div>
        <div className={`${layoutStyles["content-outer-fullscreen"]} dark-theme scrollbar-18`} style={{marginTop:'60px'}}>
          <Switch>
            <Route path="/show/tree">
              <Tree/>
            </Route>
          </Switch>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
  }),
  (dispatch) => ({})
)(Demonstration);
