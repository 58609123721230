import React from 'react';
import Config from '@/constants/sys.config'
import {Version} from '@/constants/Version'
import intl from 'react-intl-universal';

export const Copyright = ({className,locale}) => {
    return locale==='zh-cn' ?(
        <div className={className}>
            <span style={{paddingRight:'0.5rem'}}>{Config.sysTitle}</span>
          <span style={{paddingRight: '0.5rem'}}>
            <a onClick={() => {
                window.open('/mainview/relation/2389ee5f-af7a-4f12-a0bb-2e7a33201ab2', '_blank');
              }}
            >{Version}</a>
          </span>
          <span style={{ paddingRight:'0.5rem'}}>{Config.sysCopyRight}</span>
          <span style={{ paddingRight:'0.5rem'}}><a href={'https://beian.miit.gov.cn'} target={'_blank'}>{Config.sysIcp}</a></span>
          <span><a href={'https://dxzhgl.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%25E5%258D%2597%25E4%25BA%25AC%25E6%25A8%25AF%25E5%259B%25BE%25E6%2595%25B0%25E6%258D%25AE%25E7%25A7%2591%25E6%258A%2580%25E6%259C%2589%25E9%2599%2590%25E5%2585%25AC%25E5%258F%25B8&type=xuke'} target={'_blank'}>{Config.sysVats}</a></span>
        </div>
    ):(
      <div className={className}>
          <span style={{paddingRight:'0.5rem'}}>{Config.sysTitle}</span>
      </div>
  )
};
