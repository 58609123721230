import React from 'react';
import PropTypes from 'prop-types';
import { Button, message, Modal, Steps } from 'antd';

import PB, { SimplePB } from '@/libs/simplePB';
import Icon from '@/components/common/common.icon';

import style from '@/style/components/main.toolbar.stortcutMenu.less';
import { IconTypes } from '@/constants/common';
import {API_processJsonAll,API_getJsonURL,API_getQiNiuTextFile,API_logUserUsageMap } from "@/api/map_v2";
import moment from 'moment';
import {saveJsonFile} from "@/components/common/common.functions";

const { Step } = Steps;

const stepStyle = {
	//marginBottom: 10,
	//boxShadow: '0px -1px 0 0 #e8e8e8 inset',
	//paddingTop: 0
  };

class shareModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			export_json_text:'',
			export_json_btn:'导 出 全 部',
			export_reload:0,
			jmplayer_txt:'',
			currentStep: 2,
			step_one_status: 'process',
			step_one_text: '',
			step_two_status: 'wait',
			step_two_text: '',
			step_two_text: '',
			step_two_disabled: true
		};
	}

	json_url = ''

	componentDidMount() {
		let me = this;
		/*if (me.props.viewInfo.hasCover === 1) {
			requestAnimationFrame( () => {
				ViewManager.loadViewCover(me.props.viewId).then(coverRes => {
					if (coverRes) {
						me.setState({
							uploadedCoverImgBase64Url: coverRes,
						})
					}
				});
			});
		}*/

		me.props.bus.sub(me, 'graph', 'export.done', () => {
			me.props.onClose();
		});
		me.get_jmplayerTxt();
		me.get_processJson();
	}

	componentWillUnmount() {
		this.props.bus.remove(this);
		this.setState({
			export_json_text: '',
			export_json_btn: '导 出 全 部',
			export_reload: 0,
			step_one_status: 'process',
			step_one_text: '',
			step_one_disabled: true,
			step_two_status: 'wait',
			step_two_text: '',
			step_two_disabled: true

		});
	}

	get_jmplayerTxt = () => {
		let me = this;
		API_getQiNiuTextFile('jmplayer.txt').then((rsp) => {
			if(rsp && rsp.data){
				me.setState({
					jmplayer_txt: rsp.data
				});
			}
		}).catch((e) => {
			console.warn('API_getQiNiuTextFile:',e);
		});
	};

	do_processJsonAll = () => {
		let me = this;
		let viewId = me.props.viewInfo.viewId;
		me.setState({
			export_json_text: '',
			export_json_btn: '导 出 全 部',
		}, () => {
			let params ={
			reload: me.state.export_reload,
			process_id: localStorage.getItem("view_json_"+viewId)||0
			}
			API_processJsonAll(viewId,params).then((response) => {
			if(response && response.data){
				if(response.data.code === 0 && response.data.data){
					let re_data = response.data.data;
					me.setState({
						export_json_text: '更新时间：'+moment(response.data.data['starttime']).format('YYYY-MM-DD HH:mm:ss'),
						export_json_btn: '重 新 导 出',
						export_reload:1
					});
					message.info('下载中，请稍后！');
					//window.open('https://down.joinmap.ai/json_file/' + response.data.data['process_response'], '_blank');
					API_getJsonURL(response.data.data['process_response']).then((rsp) => {
						saveJsonFile(rsp, me.props.viewInfo.name+"（全部）");
					}).catch((e) => {
						console.warn('API_getJsonURL:',e);
					});
				}else{
					message.warn(response.data.msg);
					me.setState({
						export_json_text: '',
						export_json_btn: '导 出 全 部',
						export_reload:0
					});
					if(response.data.code === 3001 && int(response.data.data) > 0){
						localStorage.setItem(`view_json_${viewId}`, int(response.data.data));
					}
				}
				
			}else{
				console.warn('API_processJsonAll:',response);
				me.setState({
					export_json_text: '',
					export_json_btn: '导 出 全 部',
					export_reload:0
				});
			}
			}).catch((error) => {
				console.warn('API_processJsonAll:',error);
			//this.setState({report_content_info:'加载数据失败，请稍后再试'});
			//message.info('加载数据失败，请稍后再试');
			});

		});
	};

	do_close = () => {
		let me = this;
		me.setState({
			export_json_text: '',
			export_json_btn: '导 出 全 部',
			export_reload: 0
		}, () => {
			me.props.onClose();
		});
	}

	onChangeStep = (current) => {
		if(current==1){
			this.down_processJson();
			this.do_logUserUsageMap(1);
		}else{
			this.do_processJson();
		}
		
	}

	do_processJson = () => {
		let me = this;
		let viewId = me.props.viewInfo.viewId;
		me.setState({
			step_one_status: 'wait',
			step_one_text: '生成中...',
			step_one_disabled: true,
			step_two_status: 'wait',
			step_two_text: '',
			step_two_disabled: true
		}, () => {
			let params ={
				reload: 1,
			}
			API_processJsonAll(viewId,params).then((response) => {
				if(response && response.data){
					if(response.data.code === 3001){
						me.get_processJson();
						message.info(response.data.msg);
						localStorage.setItem(`view_json_${viewId}`, int(response.data.data));
					}
				}else{
					console.warn('API_processJsonAll:',response);
				}
			}).catch((error) => {
				console.warn('API_processJsonAll:',error);
			//this.setState({report_content_info:'加载数据失败，请稍后再试'});
			//message.info('加载数据失败，请稍后再试');
			});

		});
	};

	get_processJson = () => {
		let me = this;
		let viewId = me.props.viewInfo.viewId;
		let params ={
			reload: 0,
			process_id: localStorage.getItem("view_json_"+viewId)||0
			}
			API_processJsonAll(viewId,params).then((response) => {
			if(response && response.data){
				if(response.data.code === 0 && response.data.data){
					me.json_url = response.data.data['process_response'];
					me.setState({
						//export_json_text: '更新时间：'+moment(response.data.data['starttime']).format('YYYY-MM-DD HH:mm:ss'),
						//export_json_btn: '重 新 导 出',
						//export_reload:1,
						step_one_status: 'finish',
						step_one_text: ''+moment(response.data.data['starttime']).format('YYYY-MM-DD HH:mm'),
						step_one_disabled: false,
						step_two_status: 'process',
						step_two_text: '',
						step_two_disabled: false
					});
				}else{
					//message.warn(response.data.msg);
					if(response.data.code === 3001){
						setTimeout(() => {
							me.get_processJson();
						}, 1000);
						localStorage.setItem(`view_json_${viewId}`, int(response.data.data));
						me.setState({
							step_one_status: 'process',
							step_one_text: '生成中...',
							step_one_disabled: true,
							step_two_status: 'wait',
							step_two_text: '',
							step_two_disabled: true
						}, () => {
							
						});

					}
				}
				
			}else{
				console.warn('API_processJsonAll:',response);
			}
			}).catch((error) => {
				console.warn('API_processJsonAll:',error);
			});
	};

	down_processJson = () => {
		let me = this;
		let viewId = me.props.viewInfo.viewId;
		if(me.json_url != ''){
			//message.info('下载中，请稍后……');
			me.setState({
				step_two_text: '下载中...',
				step_two_disabled: true
			}, () => {
				API_getJsonURL(me.json_url).then((rsp) => {
					saveJsonFile(rsp, me.props.viewInfo.name+"（全部）");
					setTimeout(() => {
						me.setState({
							step_two_text: '',
							step_two_disabled: false
						});
					}, 1000);
				}).catch((e) => {
					console.warn('API_getJsonURL:',e);
				});
			});

		}else{
			message.warn('请先生成文件！');
		}
	};

	jmplayerDownload = (e) => {
		e.stopPropagation();
		window.open('http://video.joinmap.ai/joinmap_soft/jmplayer.zip', '_blank');
		this.do_logUserUsageMap(0);
	}

	do_logUserUsageMap = (op_position_id) => {
		let me = this;
		let viewId = me.props.viewInfo.viewId;
		let params ={
			op_position_id: op_position_id,
		}
		API_logUserUsageMap(viewId,params).then((response) => {
			if(response && response.data){
				if(response.data.code === 0){
				}else{
					console.warn("API_logUserUsageMap-"+op_position_id+":"+response.data.msg);
				}
				
			}else{
				console.warn('API_logUserUsageMap:',response);
			}
		}).catch((error) => {
				console.warn('API_logUserUsageMap:',error);
		});
	}


	render() {
		let me = this;
		// const {viewInfo, callback} = me.props;
		// const path = window.location.host + `/mainview/relation/${viewInfo.viewId}`;

		return (
			<Modal
				title={<div>
					<Icon type={IconTypes.ICON_FONT}
					      name="icon-fenxiang"
					      style={{marginRight: 10,overflow:'hidden'}}/>
					导出内容-{me.props.viewInfo.name}
				</div>}
				visible={me.props.visible}
				centered={true}
				destroyOnClose={true}
				maskClosable={true}
				width={'50rem'}
				footer={null}
				onCancel={me.do_close}
				bodyStyle={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
			>
				<div className={style['shortcut-menu-content']} style={{width:'100%'}}>
					<div className={style['share-item']} style={{width:'100%'}}>
						<dl className={style['description-list']}>
							<dd style={{lineHeight:'2.4rem',clear:"both"}}>
								<span>1、下载炬图阅读器</span>
								<span style={{float:'right'}}>
									<div className={style['share-btns']} style={{paddingTop:0,marginBottom:'1rem'}}>
										<Button className={style['share-btn']}
												type='primary'
												size={'large'}
												style={{width:'10rem'}}
												onClick={e => {
													me.jmplayerDownload(e);
												}}
										><Icon type={IconTypes.ICON_FONT} name="icon-fenxiang" rotate={90}/>下载阅读器</Button>
									</div>
								</span>
							</dd>
							<dd>
								<div className={style['tips']} style={{marginBottom: '3rem',width:'100%',paddingLeft:0,whiteSpace:'pre-wrap'}}>
								{me.state.jmplayer_txt}
								</div>
							</dd>
							<dd style={{lineHeight:'2.4rem',clear:"both"}}>
								<span>2、导出内容</span>
								<span style={{float:'right'}}>
									<div className={style['share-btns']} style={{paddingTop:0,marginBottom:'1rem'}}>
										<Button className={style['share-btn']}
												type='primary'
												size={'large'}
												style={{width:'10rem'}}
												onClick={e => {
													e.stopPropagation();
													me.props.bus.emit('relation', 'graph.export',{viewId: me.props.viewInfo.viewId});
													me.do_logUserUsageMap(1);
												}}
										><Icon type={IconTypes.ICON_FONT} name="icon-fenxiang"/>导 出 内 容</Button>
									</div>
								</span>
							</dd>
							<dd>
								<div className={style['tips']} style={{marginBottom: '3rem',width:'100%',paddingLeft:0}}>
									导出图谱内容的JSON文件(不包含附件)。
								</div>
							</dd>
							<dd style={{lineHeight:'2.4rem',clear:"both"}}>
								<span>3、导出全部</span>
								<span style={{float:'right',width:'20rem'}}>
									<Steps type="navigation" current={me.state.currentStep} onChange={me.onChangeStep} className={style['step-export']} style={stepStyle} size="small">
										<Step status={me.state.step_one_status} description={me.state.step_one_text} disabled={me.state.step_one_disabled}
											 title={<span style={{color:'#1890ff'}}>生成文件</span>} />
										<Step status={me.state.step_two_status} description={me.state.step_two_text} disabled={me.state.step_two_disabled}
											title={me.state.step_two_status=='process'?(<span style={{color:'#1890ff'}}>下载文件</span>):("下载文件")}/>
									</Steps>
								</span>
							</dd>
							<dd>
								<div className={style['tips']} style={{marginBottom: '3rem',width:'100%',paddingLeft:0}}>
									导出图谱全部内容的JSON文件(包含附件)。<br/>
									生成文件的执行过程会比较慢，会自动在后台执行，可以稍后再来查看执行结果。<br/>
									{me.state.step_two_status=='process'?(`本次JSON文件生成的时间为${me.state.step_one_text}，您可以点击“下载文件”直接下载，也可以点击“生成文件”重新生成。`):('')}
								</div>
							</dd>
						</dl>
						
					</div>
				</div>
			</Modal>
		);
	}
}

shareModal.defaultProps = {
	bus: PB,
	visible: false,
	viewInfo: {name: '', desc: '', hasCover: false},
};

shareModal.propTypes = {
	bus: PropTypes.instanceOf(SimplePB),
	visible: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	viewInfo: PropTypes.object.isRequired,
};

export default shareModal;
