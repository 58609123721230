import React from 'react';
import {Link} from 'react-router-dom'
import style from '@/style/components/framework.homeFramework.less'
import SysConfig from '@/constants/sys.config'
import {Version} from '@/constants/Version'

/**
 * 页头
 * @param className
 * @returns {*}
 * @constructor
 */
export const PageHeader = ({className}) => {
    return (
        <div className={className+' '+style.header}>
            <img alt={'logo'} src={SysConfig.sysLogo}/>
            <span>{SysConfig.sysTitle}</span>
        </div>
    )
};

/**
 * 页头 + 菜单
 * @param className 样式class name
 * @param {array} menu {href,key,title}
 * @returns {*}
 * @constructor
 */
export const PageMenuHeader = ({className, menu}) => {

    const headerMenu = menu ? (
      <div className={style["menu"]}>
        {
          menu['menus'] ? menu['menus'].map(menu => (
            <Link to={{pathname: menu.href}} key={menu.key} style={{textDecoration: "none"}}>
              <div className={style["menu-item"]}>
                {/* <Icon type="mail" /> */}
                {menu.title}
              </div>
            </Link>
          )) : null
        }
      </div>
    ) : null;

    return (
        <div className={style["header"] + " " + className}>
            <img className={style["logo"]} src={SysConfig.sysLogo} alt={'logo'}/>
            <div className={style["title"]}>{SysConfig.sysTitle}</div>
            {headerMenu}
        </div>
    )


};

/**
 * 页脚
 * @param className
 * @returns {*}
 * @constructor
 */
export const PageFooter = ({className}) => {
    return (
        <div className={style["footer"] + " " + className}>
            <Copyright className={className}/>
        </div>
    )
};


/**
 * 版权文字
 * @param className 样式class name
 * @returns {*}
 * @constructor
 */
export const Copyright = ({className,locale}) => {
    return locale==='zh-cn'?(
        <div className={className+' '+style.copyright}>
            <span style={{paddingRight: '0.5rem'}}>{SysConfig.sysTitle}</span>
            <span style={{paddingRight: '0.5rem'}}>
              <a onClick={() => {
                window.open('/mainview/relation/2389ee5f-af7a-4f12-a0bb-2e7a33201ab2', '_blank');
              }}
              >{Version}</a>
            </span>
            <span style={{paddingRight: '0.5rem'}}>{SysConfig.sysCopyRight}</span>
            <span style={{ paddingRight:'0.5rem'}}><a href={'https://beian.miit.gov.cn'} target={'_blank'}>{SysConfig.sysIcp}</a></span>
            <span><a href={'https://dxzhgl.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%25E5%258D%2597%25E4%25BA%25AC%25E6%25A8%25AF%25E5%259B%25BE%25E6%2595%25B0%25E6%258D%25AE%25E7%25A7%2591%25E6%258A%2580%25E6%259C%2589%25E9%2599%2590%25E5%2585%25AC%25E5%258F%25B8&type=xuke'} target={'_blank'}>{SysConfig.sysVats}</a></span>
        </div>
    ):(
      <div className={className+' '+style.copyright}>
          <span style={{paddingRight: '0.5rem'}}>{SysConfig.sysTitle}</span>
      </div>
  )
};

