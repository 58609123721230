import React from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, Dropdown, Modal, message, Tooltip } from 'antd';
import PB, { SimplePB } from '@/libs/simplePB';
import Icon from '@/components/common/common.icon';
import { IconTypes } from '@/constants/common';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

import style from '@/style/components/main.toolbar.stortcutMenu.less';
import toolbarStyle from '@/style/components/main.toolbar.less';

import { getToken, REQUEST_BASE } from '@/utils/HttpUtil';

import { showSuccessMsg } from '@/components/common/common.message';

import ShareModal from '@/components/common/view/common.view.info.shareModal';
import {SysUIConfig} from "@/constants/sys.config";
import intl from 'react-intl-universal';

const aiConsoleStatusText = {
	'background': intl.get('Custom.view.backgroundStatus'),
	'hidden': intl.get('Custom.view.hiddenStatus'),
	'active': intl.get('Custom.view.activeStatus'),
  };

class ShortcutMenu extends React.Component {
	state = {
		showShareModal: false, // 是否显示分享图谱对话框
	};

	aiConsoleShowNotice = false;
  aiConsoleShowNoticeTimeout = undefined;
	aiConsoleRealStatus = 'active';

	componentDidMount() {
		let me = this;
		me.props.bus.sub(me, 'aiConsole', ['message.push', 'message.update'], () => {
			// 有新信息
			me.aiConsoleShowNotice = true;
			me.showAiConsoleNotice();
		});
	  
		me.props.bus.sub(me, 'aiConsole', 'status.changed', ({status}) => {
			// 对话框状态改变
			me.setState({aiConsoleStatus: status});
			me.aiConsoleRealStatus = status;
		});

	}

	// 分享弹框
	openShareModal = () => {
		this.setState({
			showShareModal: true,
		});
	};

	onChangeAiConsoleStatus = () => {
		let me = this;
	
		me.props.bus.emit('aiConsole', 'status.do_change', {status: null});
	};
	
	showAiConsoleNotice = () => {
		let me = this;
	
		if (me.aiConsoleShowNoticeTimeout === undefined) {
		  me.aiConsoleShowNoticeTimeout = setTimeout(() => {
			me.setState({aiConsoleStatus: 'notice'}, () => {
			  me.aiConsoleShowNoticeTimeout = setTimeout(() => {
				me.aiConsoleShowNoticeTimeout = undefined;
				me.setState({aiConsoleStatus: me.aiConsoleRealStatus}, () => {
				  me.aiConsoleShowNotice = false;
				});
			  }, 3000);
			});
		  }, 1);
		}
	};

	render() {
		const { viewInfo } = this.props;
		let me = this;
		let node_fname = '';
        if(me.props.currentNodeId){
            let node =  me.props.networkRef.getNode(me.props.currentNodeId);
            node_fname = node.fname;
        }

		return (
			<div
				className={`${style['shortcut-menus-warp']} dark-theme`}
				style={{visibility: SysUIConfig.ShortcutMenu.visibility}}
			>
				<div className={style['shortcut-menus-list']}>
					<Tooltip
						title={intl.get('Custom.message.switchDialogStatus') + aiConsoleStatusText[me.aiConsoleRealStatus||'active']}
						trigger='hover'
						placement="left"
						>
						<Button
							className={style['robot-btn']} style={{fontSize: '1.4em', verticalAlign: '-0.075em', lineHeight: '1em'}}
							onClick={() => me.onChangeAiConsoleStatus()}
						>
							{
							me.state.aiConsoleStatus === 'notice' ? (
								<Icon className={style['flash-icon']} name={'icon-robot-smile'} type={IconTypes.ICON_FONT} />
							) : (
								me.state.aiConsoleStatus === 'active' ? (
								<Icon className={style['fade-in-icon']} name={'icon-robot-smile'} type={IconTypes.ICON_FONT} />
								) : (
								me.state.aiConsoleStatus === 'background' ? (
									<Icon className={style['fade-in-icon']} name={'icon-robot-normal'} type={IconTypes.ICON_FONT} />
								) : (
									<Icon className={style['fade-in-icon']} name={'icon-robot-cry'} type={IconTypes.ICON_FONT} />
								)
								)
							)
							}
						</Button>
					</Tooltip>
					<Button className={`${toolbarStyle['cool-button']} ${style['cool-button']}`}
					        onClick={this.openShareModal}
					>
						<Icon type={IconTypes.ICON_FONT} name="icon-fenxiang"/>{intl.get('Custom.menu.export')}
					</Button>
					{ false &&
					<Button className={`${toolbarStyle['cool-button']} ${style['cool-button']}`}
					        onClick={() => PB.emit('printing', 'new', '')}
					>
						<Icon type={IconTypes.ICON_FONT} name="icon-yinshua"/>{intl.get('Custom.menu.printing')}
					</Button>}
					<Button className={`${toolbarStyle['cool-button']} ${style['cool-button']}`}
							onClick={() => window.open(`/mainview/extended_functionality/generate_report/${viewInfo.viewId}?m=${viewInfo.name}&n=${node_fname}`, '_blank')}
					>
						<Icon type={IconTypes.ICON_FONT} name="icon-yinshua"/>{intl.get('Custom.menu.generateReport')}
					</Button>
				</div>

				<ShareModal
					bus={PB}
					visible={this.state.showShareModal}
					viewInfo={viewInfo}
					onClose={() => this.setState({showShareModal: false})}
				/>
			</div>
		);
	}
}

ShortcutMenu.defaultProps = {
	bus: PB,
};

ShortcutMenu.propTypes = {
    networkRef: PropTypes.instanceOf(ViewDataProvider),
	history: PropTypes.object, // router的参数
	bus: PropTypes.instanceOf(SimplePB),
	viewInfo: PropTypes.object.isRequired,
    currentNodeId: PropTypes.string,
};

export default ShortcutMenu;
