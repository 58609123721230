import React, { useRef, useEffect } from 'react';
 
const NoLoginMark = () => {
  const canvasRef = useRef(null);
 
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      let text = "未登录,请登录";
      //ctx.rotate(-45 * Math.PI / 180);
      ctx.font = '100px 黑体';
      ctx.fillStyle = 'rgba(155,155,155,0.2)';
      let width = canvas.width;
      let lineHeight = 1.2; // 假设行高为字体大小的1.2倍
      // 计算文本的宽度
      let textWidth = ctx.measureText(text).width;
      
      // 计算文本的左上角位置以使其居中
      var x = (width - textWidth) / 2;
      var y = (canvas.height / 2) - (100 * lineHeight / 2);
      ctx.fillText(text, x, y);
    }
  }, []);

  let innerHeight = window.innerHeight;
  let innerWidth = window.innerWidth
 
  return <canvas ref={canvasRef} width={innerWidth} height={innerHeight} />;
};
 
export default NoLoginMark;