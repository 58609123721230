// 原版 vis
// import {Network, DataSet} from 'vis'
// 修改版vis
import {Network, DataSet} from '@/libs/vis/index-network'
import Node from '@/libs/view/Node'
import Edge from '@/libs/view/Edge'
import {SimplePB} from "@/libs/simplePB";
// 计算
import {unique} from '@/components/common/common.functions';
import _ from 'lodash'

import {
    defaultDefine, // 基本配色
    STATUS_GREW, // 生长状态值
    nodeGroupNamesStyle, // 节点各种状态的样式
    edgeGroupNamesStyle, // 边的各种状态的样式
    graphConfig,
} from "@/constants/vis.defaultDefine.1";
import canvasToImage from "canvas-to-image";
import MyManipulationSystem from "@/libs/myVis/MyManipulationSystem";
import MyInteractionHandler from "@/libs/myVis/MyInteractionHandler";

/**
 * 如果target(也就是FirstOBJ[key])存在，
 * 且是对象的话再去调用deepObjectMerge，
 * 否则就是FirstOBJ[key]里面没这个对象，需要与SecondOBJ[key]合并
 */
const deepObjectMerge = (FirstOBJ, SecondOBJ) => { // 深度合并对象
    for (var key in SecondOBJ) {
        FirstOBJ[key] = FirstOBJ[key] && FirstOBJ[key].toString() === "[object Object]" ?
            deepObjectMerge(FirstOBJ[key], SecondOBJ[key]) : FirstOBJ[key] = SecondOBJ[key];
    }
    return FirstOBJ;
};

/**
 * vis network style 包装了操作集合
 * @type  {*}
 */
export const myVisNetworkStyle = {
    /**
     * 默认vis options
     * @return {*}
     */
    'options': (options) => {
        let defaultOptions = {
            // groups 配置
            // useDefaultGroups: false, // 当node设置了group但是没有设置groups样式，是否使用vis js默认的groups样式
            // groups: this.GroupStyle.node, // 用户自定义的groups样式

            // layout 布局相关
            'layout': {
                // 配置每次生成的节点位置是否一样，参数为数字1、2等。当不使用分层布局时，节点最初是随机定位的。这意味着每次固定的位置是不同的。如果手动提供一个随机种子，每次布局都是相同的。理想情况下，您尝试使用未定义的种子，重新加载，直到您对布局感到满意，并使用getSeed()方法来确定种子。
                'randomSeed': 4321,
                // 当启用时，网络将使用Kamada Kawai算法进行初始布局。对于大于100个节点的网络，将自动执行聚类以减少节点的数量。这可以大大提高稳定时间。如果网络是非常互联的（没有或很少的叶节点），这可能不起作用，它将恢复到旧的方法。性能将在未来得到改善。
                'improvedLayout': true,
                // 层级结构显示。当为true时，布局引擎使用默认设置以分层方式对节点进行定位。对于定制，您可以提供对象。
                'hierarchical': {
                    'enabled': false,
                    'direction': 'UD',
                    // 用于根据数据确定节点级别的算法。可能的选项是：hubsize, directed。
                    // Hubsize采取最边缘的节点，并把它们放在最上面。从层次结构的其余部分进行评估。定向粘附到和来自边缘的数据。A–> B SO B是一个低于A的级别。
                    'sortMethod': 'hubsize',
                    // 'sortMethod': 'directed',
                    'levelSeparation': 200,
                    'nodeSpacing': 80,
                },
            },

            // 0.9 版节点配置，默认（普通节点）
            'nodes': Object.assign(nodeGroupNamesStyle.default, {
                'size': 10, // 节点shape=image(图形时)定义默认大小
                'borderWidth': 0, // 正常时的边
                'borderWidthSelected': 1, // 选中节点时的边宽度 该数值会累加到borderWidth上
                'color': {
                    // 节点基本颜色
                    'border': defaultDefine.colors.nodeBorder, // 边框样式
                    // 高亮状态（和选中）
                    'highlight': {
                        'border': defaultDefine.colors.selectedNode,
                        'background': defaultDefine.colors.defaultSelected,
                    },
                    // 节点悬浮状态
                    'hover': {
                        'border': defaultDefine.colors.selectedNode,
                        // 'background': defaultDefine.colors.listed0
                    },
                },
                // 节点默认字体
                'font': {
                    'color': defaultDefine.colors.defaultColor,
                    'background': 'transparent', // 字体背景透明
                    'size': 13, // 默认字体大小
                    // 'background': 'rgba(255,255,255,1)', // 默认背景色，不设置为透明
                    'strokeWidth': 0,
                },
                // 通用 节点的阴影，测试用于高亮时的效果
                'shadow': {
                    'enabled': false,
                    'color': defaultDefine.colors.shadow,
                    'size': 25,
                    'x': 0,
                    'y': 0,
                },
                'chosen': {
                    'node': function (values, id, selected, hovering) {
                        // console.log('values, id, selected, hovering:',values, id, selected, hovering)
                        // rgb(255,255,107) 'bold', 'italic'
                        values.shadow = true; // 选中节点出现阴影
                        // values.shadowColor = '#ffff4d'; // 选中节点出现阴影
                        // values.shadowSize = 100; // 选中节点出现阴影延申
                        // values.borderWidth = 1;
                        // values.borderColor = defaultDefine.colors.selectedNode;
                        // values.color = '#e92545' // 红色
                    },
                    'label': function (values, id, selected, hovering) {
                        if (selected) {
                            // values.size = 16
                            // values.mod = 'bold'
                            // values.strokeWidth = 3
                            // values.strokeColor = '#87ceeb'

                            // values.strokeColor = defaultDefine.colors.shadow
                            // values.color = '#8e8e8e'

                            // values.color = '#ffff00' // 金黄色
                            // this.background=defaultDefine.colors.defaultPrimary
                        }
                    },
                },
            }),
            'edges': Object.assign(edgeGroupNamesStyle.default, {
                'dashes': false, // 虚线
                'width': 1, // shil@1216 default=0.5
                'selectionWidth': 1,
                // 边的弯曲设置
                'smooth': {
                    'enabled': true,
                    'forceDirection': 'horizontal',
                    // 'type': 'discrete',
                    // 'roundness': 0.5,
                    // 'type': 'continuous',
                    // 'roundness': 0.3,
                    'type': 'curvedCW',
                    'roundness': 0.15,
                },
                // 边的有向（箭头）设置
                /*'arrows': {
                    'to': {
                        'enabled': false,
                        'scaleFactor': 0.9,
                        'type': 'arrow',
                    },
                },*/
                // 边的颜色设置
                'color': {
                    // 'inherit': 'from',  // 颜色来自from
                    'inherit': false, // 颜色不取两端边的颜色
                    'color': defaultDefine.colors.defaultEdge,
                    // vis中的highlight就是selected时的状态； 在 chosen中做了设置
                    'highlight': defaultDefine.colors.selectedEdge,
                    // 'highlight': defaultDefine.colors.highlightEdge,
                    // 'hover': defaultDefine.colors.hoverEdge,
                    'opacity': 1,
                },
                // 边被选中时
                'chosen': {
                    'edge': function (values, id, selected, hovering) {
                        if (hovering) {
                            values.opacity = 0.9;
                            values.color = defaultDefine.colors.hoverEdge
                        }
                        if (selected) {
                            values.opacity = 1;
                            values.color = defaultDefine.colors.selectedEdge
                        }
                    },
                },
                // 边上面的字体设置，可以支持部分html
                'font': {
                    'color': defaultDefine.colors.edgeColor,
                    'size': 10,
                    'strokeWidth': 0,
                },
            }),
            // 物理(仿真)效应
            'physics': {
                'solver': 'barnesHut',
                'barnesHut': {
                    'gravitationalConstant': -8000, // 排斥力
                    'centralGravity': 0.01, // 中心的引力大小
                    'springLength': 100, // 弹簧的剩余长度
                    'springConstant': 0.03,
                    'damping': 0.1, // 节点移动速度，碰撞、抖动加剧
                    'avoidOverlap': 0.7, // 不知道什么意思，效果是调节了最小间距节点间的距离
                },
                'forceAtlas2Based': {
                    'gravitationalConstant': -80, // 排斥力
                    'centralGravity': 0.01, // 中心的引力大小
                    'springLength': 100, // 弹簧的剩余长度
                    'springConstant': 0.03,
                    'damping': 0.1, // 节点移动速度，碰撞、抖动加剧
                    'avoidOverlap': 0.7, // 不知道什么意思，效果是调节了最小间距节点间的距离
                },
                'minVelocity': 0.5, // 一旦达到所有节点的最小速度，我们假设网络已经稳定，仿真停止。
                // 'maxVelocity':50, // 节点最大速度
                'timestep': 0.6, // 节点变化速度
                'stabilization': {
                    'enabled': true,
                    'iterations': 1000, // 后台批量模拟时，模拟多少步发一次通知
                },
            },
            /*
                        'physics': {
                            'solver': 'forceAtlas2Based',
                            'forceAtlas2Based': {
                                // 'centralGravity':0.01, //
                                'gravitationalConstant': -50,
                                'springLength': 15, // 弹簧的剩余长度
                                'damping': 0.3, // 节点移动速度，碰撞、抖动加剧
                                'avoidOverlap': 0.5, // 不知道什么意思，效果是调节了最小间距节点间的距离
                            },

                            'minVelocity': 1, // 一旦达到所有节点的最小速度，我们假设网络已经稳定，仿真停止。
                            // 'maxVelocity':50, // 节点最大速度
                            'timestep': 0.9, // 节点变化速度
                            'stabilization': {
                                'enabled': true,
                                'iterations': 100, // 最多变化多少步？

                            },
                        },
            */
            'interaction': {
                'dragNodes': true, // 是否能拖动节点
                'dragView': true, // 是否能拖动画布
                'hideEdgesOnDrag': false, // 拖动画布时是否隐藏连接线
                'hideNodesOnDrag': false, // 拖动画布时是否隐藏节点
                'hover': true, // 鼠标移过后加粗该节点和连接线
                'keyboard': false, //
                'multiselect': true, // 按 ctrl 多选
                'navigationButtons': false, // 是否显示控制按钮
                'selectable': true, // 是否可以点击选择
                'selectConnectedEdges': true, // 选择节点后是否显示连接线
                'hoverConnectedEdges': false, // 鼠标滑动节点后是否显示连接线
                'tooltipDelay': 200,
                'zoomView': true, // 是否能缩放画布
            },
            manipulation: {
                enabled: true,
                initiallyActive: false,
                addNode: false,
                addEdge: false,
                editNode: () => {},
                editEdge: false,
                deleteNode: false,
                deleteEdge: false,
            },
        };

        if (options) {
            // console.log('deepObjectMerge(defaultOptions, options)=',deepObjectMerge(defaultOptions, options))
            return deepObjectMerge(defaultOptions, options)
        } else {
            // console.log('defaultOptions=',defaultOptions);
            return defaultOptions
        }
    },

    // 缓存的样式值
    'cachedStyles': {},
    // 缓存的样式值
    'cachedEdgeStyles': {},

    //vis样式保留字段
    NodeReservedField: [
        'group',
        'firstGroup',
        'hiddenGroup',
        'color',
        'shape',
        'font',
        'icon',
        'borderWidth',
        'borderWidthSelected',
        'shapeProperties',
        'imageFilter',
    ],
    //vis edge 样式保留字段
    EdgeReservedField: [
        'group',
        'firstGroup',
        'hiddenGroup',
        'color',
        'shape',
        'font',
        'icon',
        'borderWidth',
        'borderWidthSelected',
        'shapeProperties',
    ],
    //vis node 处理节点样式
    dealNodeStyle(node) {
        // 值不存在直接返回
        if (!node) return false;
        // 合并前清理节点原来有的样式，可添加，tip: 可多删不能少删
        myVisNetworkStyle.NodeReservedField.forEach(field => {
            node[field] ? delete node[field] : null;
        });
        // 重新计算样式
        myVisNetworkStyle.dealGroupNamesAndAddStyle(node)
    },
    //vis edge 处理边样式
    dealEdgeStyle(edge) {
        // 值不存在直接返回
        if (!edge) return false;
        // 合并前清理节点原来有的样式，可添加，tip: 可多删不能少删
        myVisNetworkStyle.EdgeReservedField.forEach(field => {
            edge[field] ? delete edge[field] : null;
        });
        // 重新计算样式
        myVisNetworkStyle.dealGroupNamesAndAddStyle(edge, false)
    },

    /**
     * 处理样式groupName，节点 + 边统一处理
     * 只处理样式
     * @param item 节点或者边 对象
     * @param isNode true=节点(默认)，false=边
     * @return {*}
     */
    dealGroupNamesAndAddStyle(item, isNode = true) {
        // console.group('myVisNetworkStyle.dealGroupNamesAndAddStyle')
        // 合并前清理节点原来有的样式，可添加，tip: 可多删不能少删
        // item.group ? delete item.group : null;
        // item.firstGroup ? delete item.firstGroup : null;
        // item.hiddenGroup ? delete item.hiddenGroup : null;
        // item.color ? delete item.color : null;
        // item.shape ? delete item.shape : null;
        // item.font ? delete item.font : null;
        // item.icon ? delete item.icon : null;
        // item.borderWidth ? delete item.borderWidth : null;
        // item.borderWidthSelected ? delete item.borderWidthSelected : null;
        // item.shapeProperties ? delete item.shapeProperties : null;


        // console.log('this->',this)
        // console.log('graphConfig->',graphConfig)

        // 判断item时点还是边
        let GroupNamesStyle;
        let classname;
        let level;
        let caches;
        // 根据节点字段属性，生成样式数组
        let groups = ['default'];

        if (isNode === false) { // fixme:边,这么判断不准确
            GroupNamesStyle = edgeGroupNamesStyle;
            classname = graphConfig.EdgeClassFieldName;
            level = graphConfig.EdgeDivideFieldName;
            caches = this.cachedEdgeStyles;

            // 暂时：判断大纲类型边
            if (item.meta && item.meta.text2text) {
                groups.push('outline')
            }

        } else { // 节点
            GroupNamesStyle = nodeGroupNamesStyle;
            classname = graphConfig.NodeClassFieldName;
            level = graphConfig.NodeDivideFieldName;
            caches = this.cachedStyles

        }

        // console.log('GroupNamesStyle->',GroupNamesStyle)
        // console.log('classname->',classname)
        // console.log('level->',level)
        // console.log('caches->',caches)

        // 状态值临时存储地方
        // if (!item._temp) item._temp = {}

        // Tip: ** 以下判断添加样式的顺序很重要，慎重改变 **

        // 判断对象是否有分类字段，没有的用default样式,有对象分类字段的则拼接出样式名：对象分类字段+字段值
        if (item[level[item[classname]]]) {
            groups.push(level[item[classname]] + item[level[item[classname]]])
        }

        // 节点：对用户节点的对象分类判断
        if (item.lev && item[classname] === 0) {
            groups.push(item.lev)
        }

        // AI生成
        if (item._aiGenerated) {
            groups.push('ai-generated');
        }

        // 节点：系统推荐专用
        if (item._systemRecommend) {
            groups.push('system-recommend');
        }

        if (item._imageType && item[classname] === 0) {
            groups.push(item._imageType);
        }

        // 是否是生长节点
        if (item.status === STATUS_GREW && item.visible !== false && !item._aiGenerated) {
            groups.push('grew')
        }

        // 针对节点：是否是当前选择
        if (item._currentSelect === true) {
            groups.push('currentSelect');
            groups.push(`currentSelect-${item.lev}`);
        }

        // 针对节点： 是否是上次选择
        if (item._lastSelect === true) {
            groups.push('lastSelect');
            groups.push(`lastSelect-${item.lev}`);
        }
        if (item._hover === true) {
            // groups.push("hoverFocusNode");
            groups.push("hover");
            groups.push(`hover-${item.lev}`);
        }
        if (item._focused === true) {
            // groups.push("hoverFocusNode");
            groups.push("focus");
            groups.push(`focus-${item.lev}`);
        }
        let brightness = item._brightness;
        if (item._currentSelect) brightness = undefined;
        if (item._presenting) brightness = undefined;
        if (brightness) {
            groups.push(`brightness-${brightness}`);
        }
        // 进度条
        let progressColor = undefined;
        if (item.aiGraphRank && (item._noProgressBar !== true)) {
            // 方案一
            /*
            if (item.aiGraphRank < 20) {
                groups.push('graph-rank-FF7000');
                progressColor = '#FF7000';
            } else if (item.aiGraphRank >= 80) {
                groups.push('graph-rank-20B2AA');
                progressColor = '#20B2AA';
            }
            */

            // 方案二 200508 新颜色
            /**/
            if (item.aiGraphRank < 20) {
                groups.push('graph-rank-FFF2CC');
                progressColor = 'rgb(255,106,59)';
            } else if (item.aiGraphRank >= 80) {
                groups.push('graph-rank-A9D18E');
                progressColor = 'rgb(101,164,72)';
            } else {
                groups.push('graph-rank-BDD7EE');
                progressColor = 'rgb(255,224,125)';
            }
        }
        // 直线
        if (item.meta && item.meta['smooth'] === 0) {
            groups.push('straight');
        }
        // 箭头
        if (item.arrows && item.meta && item.meta['direction']) {
            groups.push(`direction-${item.meta['direction']}`);
        } else if (item.arrows) {
            groups.push('arrows');
        } else if (!item.arrows) {
            groups.push('no-arrows');
        }
        // 颜色
        if (item.meta && item.meta['color'] && !item._userMarkedInvisible) {
            const {r, g, b, a} = item.meta['color'];
            groups.push(`color-rgba(${r}, ${g}, ${b}, ${a})`);
        }
        // 宽度
        if (item.meta && item.meta['width']) {
            groups.push(`width-${item.meta['width']}`);
        }
        // 引力
        if (item.meta && item.meta['physics'] === 0) {
            groups.push('nonePhysics');
        }
        // 可见性
        if (item.visible === false) {
            groups.push('invisible');
        }

        // 用户标记为隐藏边
        if (item._userMarkedInvisible) {
            groups.push('user-marked-invisible');
        }

        // console.log('groups=',groups);
        // console.log('item=',item);
        // 拼接样式分类名
        item.groupNames = groups.join(' ');

        // 例外处理,自定义图片的节点
        let originImage = item._image;

        // 根据样式数组，从样式配置文件中取值拼接成最终样式
        // 边没有type???
        if (caches[item[classname]] && caches[item[classname]][item.groupNames]) {
            item = Object.assign(item, caches[item[classname]][item.groupNames]);
            // console.log('dealGroupNamesAndAddStyle,item=',item)
            if (item.meta && item.meta.scale) {
                let scale = parseFloat(item.meta.scale);
                item.size *= scale;
                if (item.shape && item.shape === 'ellipse' && item.font && item.font.size) {
                    item.font = {...item.font, size: item.font.size * scale};
                } else if (item.shape && item.shape === 'box') {
                    if (item.font && (item.font.size || item.font.lineHeight || item.font.vadjust)) {
                        item.font = {
                            ...item.font,
                            size: item.font.size ? item.font.size * scale : undefined,
                            lineHeight: item.font.lineHeight ? item.font.lineHeight * scale : undefined,
                            vadjust: item.font.vadjust ? item.font.vadjust * scale : undefined,
                        };
                    }
                    if (item.widthConstraint && item.widthConstraint.maximum) {
                        item.widthConstraint = {
                            ...item.widthConstraint,
                            maximum: item.widthConstraint.maximum * scale,
                        };
                    }
                    if (item.margin) {
                        item.margin = {
                            top: item.margin.top ? item.margin.top * scale : undefined,
                            left: item.margin.left ? item.margin.left * scale : undefined,
                            bottom: item.margin.bottom ? item.margin.bottom * scale : undefined,
                            right: item.margin.right ? item.margin.right * scale : undefined,
                        };
                    }
                } else if (item.icon && item.icon.size) {
                    item.icon = {...item.icon, size: item.icon.size * parseFloat(item.meta.scale)};
                }
            }
        } else {
            let styles = {};
            for (const key of groups) {
                // 替换样式
                if (GroupNamesStyle[item[classname]] && Object.keys(GroupNamesStyle[item[classname]]).includes(key)) { // 先找大类
                    // styles = {...styles, ...GroupNamesStyle[item[classname]][key]}
                    styles = Object.assign(styles, GroupNamesStyle[item[classname]][key])
                } else if (Object.keys(GroupNamesStyle).includes(key)) { // 找状态
                    // styles = {...styles, ...GroupNamesStyle[key]}
                    styles = Object.assign(styles, GroupNamesStyle[key])
                }
            }
            // 颜色
            if (item.meta && item.meta['color'] && !item._userMarkedInvisible) {
                const {r, g, b, a} = item.meta['color'];
                styles.color = {...(styles.color || {})};
                styles.color.color = `rgb(${r}, ${g}, ${b})`;
                styles.color.opacity = parseFloat(a);
            }
            // 是否存在进度条颜色
            if (progressColor) {
                styles.font = styles.font || {};
                styles.font = {...styles.font, color: progressColor};
            }

            // 是否变暗
            if (brightness) {
                if (styles.color) {
                    if (styles.color.border) {
                        styles.color = {
                            ...styles.color,
                            border: defaultDefine.brighten(styles.color.border, brightness),
                        };
                    } else {
                        styles.color = {
                            ...styles.color,
                            border: defaultDefine.brighten(defaultDefine.colors.nodeBorder, brightness),
                        };
                    }
                    if (styles.color.background) {
                        styles.color = {
                            ...styles.color,
                            background: defaultDefine.brighten(styles.color.background, brightness),
                        };
                    } else {
                        styles.color = {
                            ...styles.color,
                            background: defaultDefine.brighten(defaultDefine.colors.level1, brightness),
                        };
                    }
                    if (styles.color.color) {
                        styles.color = {...styles.color, color: defaultDefine.brighten(styles.color.color, brightness)};
                    } else {
                        styles.color = {
                            ...styles.color,
                            color: defaultDefine.brighten(defaultDefine.colors.edgeColor, brightness),
                        };
                    }
                } else {
                    styles.color = {
                        border: defaultDefine.brighten(defaultDefine.colors.nodeBorder, brightness),
                        background: defaultDefine.brighten(defaultDefine.colors.level1, brightness),
                        color: defaultDefine.brighten(defaultDefine.colors.edgeColor, brightness),
                    };
                }
                if (styles.font && styles.font.color) {
                    styles.font = {...styles.font, color: defaultDefine.brighten(styles.font.color, brightness)};
                } else {
                    styles.font = {
                        ...(styles.font || {}),
                        color: defaultDefine.brighten(defaultDefine.colors.default, brightness),
                    };
                }
                switch (styles.shape) {
                    case 'image':
                    case 'circularImage':
                        styles.imageFilter = `brightness(${Math.round(brightness * 100)}%)`;
                        break;
                    case 'icon':
                        if (styles.icon && styles.icon.color) {
                            styles.icon = {
                                ...styles.icon,
                                color: defaultDefine.brighten(styles.icon.color, brightness),
                            };
                        }
                        break;
                }
            }
            caches[item[classname]] ? caches[item[classname]][item.groupNames] = styles : caches = Object.assign(caches, {[item[classname]]: {[item.groupNames]: styles}});
            // console.log(`New style to catch: ${item.groupNames}`, styles);
            // caches = {...caches, ...{[item[classname]]: {[item.groupNames]: styles}}}
            // console.log('dealGroupNamesAndAddStyle,styles=',styles)
            // console.log('item=',item)
            // 把样式合并到节点里去
            item = Object.assign(item, styles);
            if (item.meta && item.meta.scale) {
                let scale = parseFloat(item.meta.scale);
                item.size *= scale;
                if (item.shape && item.shape === 'ellipse' && item.font && item.font.size) {
                    item.font = {...item.font, size: item.font.size * scale};
                } else if (item.shape && item.shape === 'box') {
                    if (item.font && (item.font.size || item.font.lineHeight || item.font.vadjust)) {
                        item.font = {
                            ...item.font,
                            size: item.font.size ? item.font.size * scale : undefined,
                            lineHeight: item.font.lineHeight ? item.font.lineHeight * scale : undefined,
                            vadjust: item.font.vadjust ? item.font.vadjust * scale : undefined,
                        };
                    }
                    if (item.widthConstraint && item.widthConstraint.maximum) {
                        item.widthConstraint = {
                            ...item.widthConstraint,
                            maximum: item.widthConstraint.maximum * scale,
                        };
                    }
                    if (item.margin) {
                        item.margin = {
                            top: item.margin.top ? item.margin.top * scale : undefined,
                            left: item.margin.left ? item.margin.left * scale : undefined,
                            bottom: item.margin.bottom ? item.margin.bottom * scale : undefined,
                            right: item.margin.right ? item.margin.right * scale : undefined,
                        };
                    }
                } else if (item.icon && item.icon.size) {
                    item.icon = {...item.icon, size: item.icon.size * scale};
                }
            }
        }

        if (originImage) item.image = originImage;
        return item
    },

    // 节点和边的统一状态处理
    dealItemStatus(item, status) {
        // 值不存在直接返回
        if (!item) return false;
        // 以下是不可叠加样式，tip: 判断的顺序不能变！！！
        if (status === '_beforeLastSelect') { // 刚刚被取消的上个节点
            item._lastSelect = false;
            item._currentSelect = false;
        }
        if (status === '_beforeCurrentSelect') { // 刚刚被取消的上个节点
            item._lastSelect = false;
            item._currentSelect = false;
        }
        if (status === '_lastSelect') { // 刚刚成为上个节点的当前节点
            item._lastSelect = true;
            item._currentSelect = false
        }
        if (status === '_currentSelect') { // 刚刚成为当前节点的节点（有可能是上个节点）
            item._lastSelect = false;
            item._currentSelect = true;
        }
        if (status === '_presenting') {
            item._presenting = true;
        } else if (status === '_beforePresenting') {
            item._presenting = false;
        }

        // 以下是可叠加样式
        if (status === '_dragging') { // 刚刚成为当前节点的节点（有可能是上个节点）
            item._dragging = true;
        }

        if (status === '_hover') { // 刚刚成为当前节点的节点（有可能是上个节点）
            item._hover = true;
        }

        if (status === '_lastHover') { // 刚刚成为当前节点的节点（有可能是上个节点）
            item._hover = false
        }
        if (status === '_focus') { // 刚刚成为当前节点的节点（有可能是上个节点）
            item._focused = true;
        }
        if (status === '_lastFocus') { // 刚刚成为当前节点的节点（有可能是上个节点）
            item._focused = false;
        }

        if (status === '_hidden') { // 刚刚成为当前节点的节点（有可能是上个节点）
            item._hidden = true;
        }
        return item;
    },

    /**
     * 节点受到的向心力。这个力会让节点像中心靠拢。
     * 根据节点计算向心力大小
     * @param nodesNum
     * @return {number}
     */
    calcGra(nodesNum) {
        if (nodesNum > 6000) {
            return 16
        } else if (nodesNum > 3000) {
            return 7
        } else if (nodesNum > 1500) {
            // return 4
            return 20 // shilei
        } else if (nodesNum > 500) {
            return 1
        } else if (nodesNum > 50) {
            return 0.8
        } else {
            return 0.1
        }
    },
};

const defaultM = () => ({
    'isClickedCanvas': false, // 是否点击的画布
    'nodes': {
        '_dragging': undefined, // 正在拖动的节点
        '_hover': undefined, // 悬浮节点(不是选中)，例如：定位到的点
        '_lastHover': undefined, // 被切换的 悬浮节点(不是选中)，，用于恢复样式
        '_focus': undefined, // 聚焦节点(不是选中)，例如：定位到的点
        '_lastFocus': undefined, // 被切换的 聚焦节点(不是选中)，用于恢复样式
        '_hidden': undefined, // 隐藏节点
        '_currentSelect': undefined, // 当前节点(选中)
        '_beforeCurrentSelect': undefined, // 前一个当前节点(选中),用来恢复样式
        '_lastSelect': undefined, // 上一个点击节点
        '_beforeLastSelect': undefined, // 刚刚被取消的上一个节点,用来恢复样式
        '_presenting': undefined, // 演示中的节点
        '_beforePresenting': undefined, // 刚刚被取消的演示中的节点,用来恢复样式
    },
    'edges': {
        '_dragging': undefined, // 正在拖动的边
        '_hover': undefined, // 悬浮边(不是选中)，例如：定位到的边
        '_lastHover': undefined, // 被切换的 悬浮边(不是选中)，，用于恢复样式
        '_focus': undefined, // 聚焦边(不是选中)，例如：定位到的边
        '_lastFocus': undefined, // 被切换的 聚焦边(不是选中)，用于恢复样式
        '_hidden': undefined, // 隐藏边
        '_currentSelect': undefined, // 当前边(选中)
        '_beforeCurrentSelect': undefined, // 前一个当前边(选中),用来恢复样式
        '_lastSelect': undefined, // 上一个点击边
        '_beforeLastSelect': undefined, // 刚刚被取消的上一个边,用来恢复样式
    },
    'network': {
        '_stabilized': false, // 是否冻结
    },
});

/**
 * 独立的画图工具
 * tip: 只管画图和响应，不管数据,不管样式
 */
class MyNetwork {
    lastStopFitFn = undefined;
    // 用户是否正在双击
    doubleClicked = false;
    // 正在等待确认是否双击
    clicking = false;
    // 拖动起始位置
    draggingStartPoint = undefined;
    draggingNodePosition = {};
    /**
     * 默认节点亮度
     *
     * @type {number|string}
     * @private
     */
    _normalBrightness = 1.0;

    /**
     * 构造
     * @param container network的dom容器对象
     * @param data 原始数据
     * @param options 原始数据处理的配置
     * @param events
     * @param bus 消息总线
     * @returns {null}
     */
    constructor(container, data = {'nodes': [], 'edges': []}, options = {}, events = {}, bus = new SimplePB()) {
        window.myNetwork = this
        // 标识
        this._uuid = _.uniqueId('MyNetwork3');
        /**
         * @private
         * 事件处理中间件
         *
         * @type {SimplePB}
         */
        this._PB = bus;

        // 操作画面对象（点、边、画布）处理的中间值
        this._mediacy = defaultM();

        // 初始化
        this._graph = {};
        if (data && data.nodes) {
            this._graph.nodes = new DataSet(data.nodes)
        } else {
            this._graph.nodes = new DataSet();
            console.warn('MyNetwork3.js->constructor-> 没有点数据')
        }
        if (data && data.edges) {
            this._graph.edges = new DataSet(data.edges)
        } else {
            this._graph.edges = new DataSet();
            console.warn('MyNetwork3.js->constructor-> 没有边数据')
        }
        // 配置
        this._options = myVisNetworkStyle.options(options);
        console.log('MyNetwork->options', options);
        // console.log('MyNetwork->this._options', this._options)
        // 创建
        if (container) {
            this._container = container;
            this._network = new Network(container, this._graph, this._options);
            // noinspection JSUnresolvedFunction
            this._network.manipulation._clean();
            delete this._network.manipulation;
            this._network.manipulation =
              new MyManipulationSystem(this._network.body, this._network.canvas, this._network.selectionHandler);
            this._network.interactionHandler =
              new MyInteractionHandler(this._network.body, this._network.canvas, this._network.selectionHandler);
            this._network.setOptions(this._options);
            window._network = this._network
        } else {
            this._container = undefined;
            console.error('MyNetwork3.js->constructor-> 没有指定Vis Network的初始化容器');
            return null
        }

        // 内部初始化
        /*this.graph.nodes.on('*', function (event, properties, senderId) {
            // console.log('event:', event, 'properties:', properties, 'senderId:', senderId)
        });
        this.graph.edges.on('*', function (event, properties, senderId) {
            // console.log('event:', event, 'properties:', properties, 'senderId:', senderId)
        });*/
        // network 绑定(默认)响应事件
        this.bindEvent(events);
    }

    /**
     * 重布局并绘制关系图
     *
     * @param {function} [callback] 回调函数
     * @param {number} [maxWait] 最多等待毫秒数
     */
    reLayout(callback, maxWait = 25000) {
        let me = this, timeoutOpt = {timeout: false, handler: undefined};
        const nodes = me._graph.nodes.get();
        me._graph.nodes.clear();
        me._network.moveTo({
            position: {x: 0, y: 0},
            scale: 1,
            animation: {duration: 100},
        });
        setTimeout(() => {
            me.updateGraph({nodes});
            setTimeout(() => {
                const fitInterval = setInterval(() => {
                    me._network.fit({animation: true});
                }, 5000);
                const stopFitFn = () => {
                    if (timeoutOpt.timeout) {
                        console.log('动画已超时');
                        return;
                    }
                    let localStopTimeout = setTimeout(() => {
                        if (timeoutOpt.timeout) {
                            console.log('动画已超时');
                            return;
                        }
                        if (timeoutOpt.handler) clearTimeout(timeoutOpt.handler);
                        console.log('动画完成，节点数：', nodes.length);
                        clearInterval(fitInterval);
                        callback && callback();
                    }, 500);
                    me._network.once('startStabilizing', () => {
                        clearTimeout(localStopTimeout);
                        if (me.lastStopFitFn) me._network.off('stabilized', me.lastStopFitFn);
                        me.lastStopFitFn = stopFitFn;
                        me._network.once('stabilized', stopFitFn);
                    });
                };
                if (me.lastStopFitFn) me._network.off('stabilized', me.lastStopFitFn);
                me.lastStopFitFn = stopFitFn;
                me._network.once('stabilized', stopFitFn);
                me._network.fit({animation: true});
                setTimeout(() => clearInterval(fitInterval), 10000); // 5 + 10秒后不再自适应
                if (maxWait > 0) {
                    timeoutOpt.handler = setTimeout(() => {
                        timeoutOpt.timeout = true;
                        console.log('动画超时，节点数：', nodes.length);
                        me._network.stopSimulation();
                        callback && callback();
                    }, maxWait);
                }
            }, Math.min(nodes.length * nodes.length / 100, 5000));
        }, 100);
    };

    //-------------------------------------------------
    /**
     * 设置关系图数据
     * 替换原数据，并更新画面
     * @param graph dataset或者array
     */
    setGraph(graph) {
        const {nodes, edges} = graph;
        if (nodes instanceof DataSet) {
            this._graph.nodes = nodes;
        } else if (Array.isArray(nodes)) {
            this._graph.nodes = new DataSet(this.transformData(nodes, 'node'));
        } else {
            console.log('无效的nodes数据')
        }

        if (edges instanceof DataSet) {
            this._graph.edges = edges;
        } else if (Array.isArray(edges)) {
            this._graph.edges = new DataSet(this.transformData(edges, 'edge'))
        } else {
            console.log('无效的edges数据')
        }
        this._network.setData(this._graph);
    };

    setGraph2(graph) {
        let {nodes, edges} = graph;
        let tempNodes = [];
        if (nodes) {
            nodes.map(node => {
                if (node instanceof Node) {
                    tempNodes.push(node)
                } else {
                    tempNodes.push(new Node(node))
                }
            });
            this._graph.nodes = new DataSet(tempNodes);
        }

        let tempEdges = [];
        if (edges) {
            edges.map(edge => {
                if (edge instanceof Edge) {
                    tempEdges.push(edge)
                } else {
                    tempEdges.push(new Edge(edge))
                }
            });
            this._graph.edges = new DataSet(tempEdges);
        }
        this._network.setData(this._graph);
    }

    /**
     * 添加数据，并对每个节点都做处理
     * id 重复时会报错
     * @param graph 新的数据 针对array并且不是Node
     * @param funNode 对每个节点的预处理
     * @param funEdge 对每个边的预处理
     */
    addGraph(graph, funNode, funEdge) {
        let temp = [];
        if (graph && graph.nodes) {
            graph.nodes.map(node => {
                if (node && node.id) {
                    funNode ? node = funNode(node) : null;
                    if (node instanceof Node) {
                        temp.push(node)
                    } else {
                        temp.push(new Node(node))
                    }
                }
            });

            this._graph.nodes.update(temp);
        }

        let tempEdges = [];
        if (graph && graph.edges) {
            graph.edges.map(edge => {
                funEdge ? edge = funEdge(edge) : null;
                if (edge instanceof Edge) {
                    tempEdges.push(edge)
                } else {
                    tempEdges.push(new Edge(edge))
                }
            });
            this._graph.edges.update(tempEdges);
        }
    }

    /**
     * 更新数据，
     * 数据值更改了以后要重新计算样式
     * @param graph 待更新的数据，
     * @param funNode
     * @param funEdge
     */
    updateGraph(graph, funNode, funEdge) {
        if (graph && graph.nodes) {
            this._graph.nodes.update(graph.nodes.map(node => {
                funNode && funNode(node);
                myVisNetworkStyle.dealNodeStyle(node);
                return node;
            }))
        }
        if (graph && graph.edges) {
            this._graph.edges.update(graph.edges.map(edge => {
                funEdge && funEdge(edge);
                myVisNetworkStyle.dealEdgeStyle(edge);
                return edge;
            }))
        }
        this.updateNodeMediacy();
    }

    /**
     * 清空画面数据
     */
    clearGraph() {
        this._graph.nodes.clear();
        this._graph.edges.clear();
        this._mediacy = defaultM();
    }

    /**
     * 更新节点->更新画面
     * @param nodes
     */
    updateNodes(nodes = []) {
        this._graph.nodes.update(nodes.map(n => (n instanceof Node) ? n : new Node(n)));
    }

    /**
     * 更新边->更新画面
     * @param edges
     */
    updateEdges(edges = []) {
        return this._graph.edges.update(edges.map(e => (e instanceof Edge) ? e : new Edge(e)));
    }

    /**
     * 更新点【未完成】
     * 发请求，回调更新画面
     * @param nodes 支持 id Node object
     */
    removeNodes(nodes = []) {
        return this._graph.nodes.remove(this.getNodes(nodes, true))
    }

    /**
     * 更新边【未完成】
     * 发请求，回调更新画面
     * @param edges 支持 id Node object
     */
    removeEdges(edges = []) {
        return this._graph.edges.remove(this.getEdges(edges, true))
    }

    //-------------------------------------------------
    /**
     * 添加事件监听处理函数
     *
     * @param target 实例
     * @param name 事件名称
     * @param handler 事件处理函数
     */
    subscribe = (target, name, handler) => {
        // console.log('订阅pb：', name)
        this._PB.sub(target, 'default', name, handler);
        return this;
    };

    /**
     * 取消某个对象对所有事件的监听
     */
    unSubscribe = (target) => {
        this._PB.remove(target);
        return this;
    };

    //-------------------------------------------------
    // vis network 对象
    get UUID() {
        // console.log('get uuid');
        return this._uuid;
    }

    set UUID(uuid) {
        // console.log('set uuid');
        return undefined;
    }

    /**
     * vis network 对象
     *
     * @return {Network}
     */
    get network() {
        // console.log('get network');
        return this._network;
    }

    set network(network) { // fixme:设置了以后是否要更新图
        // console.log('set network');
        this._network = network;
    }

    // graph
    get graph() {
        // console.log('get network');
        return this._graph;
    }

    set graph(graph) {
        console.log('不能直接设置graph,请用setGraph方法');
        this._graph = graph;
        this._network.setData(this._graph)
    }

    // options
    get options() {
        // console.log('get options');
        return this._options
    }

    set options(options) { // fixme:设置了以后是否要更新图
        this._options = myVisNetworkStyle.options(options);
        this._network.setOptions(this._options)
    }

    /**
     * 更新现有的options
     * @param options 待更新的options项目
     */
    updateOptions(options) {
        this._options = deepObjectMerge(this._options, options);
        this._network.setOptions(this._options)
    }

    // mediacy
    get mediacy() {
        // console.log('get options');
        return this._mediacy;
    }

    set mediacy(mediacy) {
        // console.log('set options');
        // do nothing 中间状态值不能设置
    }

    // PB 对象
    get PB() {
        // console.log('get PB');
        return undefined;
    }

    set PB(network) {
        // console.log('set PB');
        return undefined;
    }

    get currentSelectedNodeId() {
        return this._mediacy.nodes._currentSelect;
    };

    get lastSelectedNodeId() {
        return this._mediacy.nodes._lastSelect;
    };

    /**
     * @returns {number|string}
     */
    get defaultNormalBrightness() {
        return this._normalBrightness;
    }

    set defaultNormalBrightness(normalBrightness) {
        if (this._normalBrightness !== normalBrightness) {
            this._normalBrightness = normalBrightness;
            this._PB.emit('default', 'CONFIG.normalBrightnessChanged');
        }
    }

    //-------------------------------------------------
    // 中间状态更新，fixme:可能有各种意外状况
    // 不依赖 Node.js
    updateNodeMediacy(callback) {
        // 节点处理
        let temp = [];
        let item;
        for (let status in this._mediacy.nodes) {
            if (this._mediacy.nodes[status]) {
                item = this._graph.nodes.get(this._mediacy.nodes[status]);
                if (!item) continue;
                item = myVisNetworkStyle.dealItemStatus(item, status);
                item = myVisNetworkStyle.dealGroupNamesAndAddStyle(item);
                temp.push(item)
            }
        }
        this._graph.nodes.update(temp);

        // 边处理
        temp = [];
        for (let status in this._mediacy.edges) {
            if (this._mediacy.edges[status]) {
                item = this._graph.edges.get(this._mediacy.edges[status]);
                if (!item) continue;
                item = myVisNetworkStyle.dealItemStatus(item, status);
                item = myVisNetworkStyle.dealGroupNamesAndAddStyle(item);
                temp.push(item)
            }
        }
        this._graph.edges.update(temp);

        // 加载自定义事件
        callback && callback()
    }

    /**
     * network绑定事件
     * @param customEvents obj 事件集合
     * @param doComm bool 是否覆盖默认的事件
     */
    bindEvent(customEvents = {}, doComm = true) {
        let that = this;
        if (customEvents === false) { // todo:取消全部事件绑定
            that._network.on('*', () => {
                console.log('取消全部事件绑定')
            })
        } else if (customEvents.constructor === Object) {
            // 默认的执行方法
            let defaultEvents = {
                'click': (params, customEvent) => {
                    if (params.event['changedPointers'][0].ctrlKey || params.event['changedPointers'][0].metaKey) return;
                    if (this.clicking) return;
                    this.clicking = true;
                    setTimeout(() => {
                        this.clicking = false;
                        if (this.doubleClicked) {
                            this.doubleClicked = false;
                            return;
                        }
                        if (doComm === true && customEvent !== false) {
                            // 公共处理
                            commClick(params);
                            // 更新节点、边各种中间状态的样式
                            that.updateNodeMediacy(() => {
                                // 触发事件
                                that._PB.emit('default', 'NETWORK.click', that);
                                // 加载自定义事件
                                customEvent && customEvent(params);
                            })
                        } else {
                            // 加载自定义事件
                            customEvent && customEvent(params);
                        }
                    }, 200);
                },
                'doubleClick': (params, customEvent) => {
                    if (params.event['changedPointers'][0].ctrlKey || params.event['changedPointers'][0].metaKey) return;
                    this.doubleClicked = true;
                    // 加载自定义事件
                    customEvent && customEvent(params);
                },
                'dragStart': (params, customEvent) => {
                    that._PB.emit('default', 'NETWORK.dragStart', that);
                    if (doComm === true && customEvent !== false) {
                        if (params.nodes.length >= 1) { // 是否拖动了节点
                            let nodeId = that._network.getNodeAt(params.pointer.DOM);
                            let node = that._graph.nodes.get(nodeId);
                            let nodes = that._graph.nodes.get(params.nodes);
                            if (nodes.findIndex(node => node.fixed && !node._locked) >= 0) { // 固定位置的节点才会有拖动停留的效果，其他状态节点忽略
                                that._mediacy.nodes._dragging = node.id; // 设置进入拖动的状态，在dragEnd里面要用
                                that.draggingStartPoint = params.pointer.canvas;
                                nodes.filter(n => n.fixed).forEach(n => {
                                    that.draggingNodePosition[n.id] = {
                                        x: n.x,
                                        y: n.y,
                                    }
                                })
                            }
                            // 更新节点、边各种中间状态的样式
                            that.updateNodeMediacy(() => {
                                // 加载自定义事件
                                customEvent && customEvent(params);
                            })
                        }
                    } else {
                        // 加载自定义事件
                        customEvent && customEvent(params);
                    }
                },
                'dragging': (params, customEvent) => {
                    // 触发事件
                    that._PB.emit('default', 'NETWORK.dragging', that);
                    if (params.nodes.length >= 1) {
                        let nodeId = params.nodes.find(nodeId => nodeId === that._mediacy.nodes._dragging);
                        let nodes = that._graph.nodes.get(params.nodes);
                        if (nodeId && nodes.findIndex(node => node.fixed && !node._locked) >= 0) {
                            const position = params.pointer.canvas; // 拖动结束时的坐标
                            let diffPosition = {
                                diffX: position.x - that.draggingStartPoint.x,
                                diffY: position.y - that.draggingStartPoint.y,
                            };
                            if (!isNaN(diffPosition.diffX) && !isNaN(diffPosition.diffY)) {
                                that._graph.nodes.update(nodes.filter(
                                    n => that.draggingNodePosition[n.id] && n.fixed
                                ).map(n => {
                                    n.x = n.fx = that.draggingNodePosition[n.id].x + diffPosition.diffX;
                                    n.y = n.fy = that.draggingNodePosition[n.id].y + diffPosition.diffY;
                                    return n;
                                }), 'magicId$doNotAutoStartSimulation');
                            }
                        }
                    }
                    // 加载自定义事件
                    customEvent && customEvent(params);
                },
                'dragEnd': (params, customEvent) => {
                    that._PB.emit('default', 'NETWORK.dragEnd', that);
                    if (doComm === true && customEvent !== false) {
                        if (params.nodes.length >= 1) {
                            let nodeId = params.nodes.find(nodeId => nodeId === that._mediacy.nodes._dragging);
                            let nodes = that._graph.nodes.get(params.nodes);
                            if (nodeId && nodes.findIndex(node => node.fixed && !node._locked) >= 0) { // 判断是否是记录的拖动节点
                                const position = params.pointer.canvas; // 拖动结束时的坐标
                                let diffPosition = {
                                    diffX: position.x - that.draggingStartPoint.x,
                                    diffY: position.y - that.draggingStartPoint.y,
                                };
                                if (!isNaN(diffPosition.diffX) && !isNaN(diffPosition.diffY)) {
                                    that._graph.nodes.update(nodes.filter(
                                        n => that.draggingNodePosition[n.id] && n.fixed
                                    ).map(n => {
                                        n.x = n.fx = that.draggingNodePosition[n.id].x + diffPosition.diffX;
                                        n.y = n.fy = that.draggingNodePosition[n.id].y + diffPosition.diffY;
                                        return n;
                                    }), 'magicId$doNotAutoStartSimulation');
                                }
                                that.draggingStartPoint = undefined;
                                that.draggingNodePosition = {};
                                that._mediacy.nodes._dragging = undefined // 一次完整拖动结束，取消拖动状态
                            }
                            // 更新节点、边各种中间状态的样式
                            that.updateNodeMediacy();
                            // 加载自定义事件
                            customEvent && customEvent(params);
                        }
                    } else {
                        // 加载自定义事件
                        customEvent && customEvent(params);
                    }
                },
                'oncontext': (params, customEvent) => {
                    params.event.preventDefault();// 取消了原生的右击事件
                    // 加载自定义事件
                    customEvent && customEvent(params);
                },
                'stabilized': (params, customEvent) => {
                    // 设置network的中间状态
                    that._mediacy.network._stabilized = true;
                    // 触发事件
                    that._PB.emit('default', 'NETWORK.stabilize', that);
                    // 加载自定义事件
                    customEvent && customEvent(params);
                },
                'startStabilizing': (params, customEvent) => {
                    // 设置network的中间状态
                    that._mediacy.network._stabilized = false;
                    // 触发事件
                    that._PB.emit('default', 'NETWORK.stabilize', that);
                    // 加载自定义事件
                    customEvent && customEvent(params);
                },
                'zoom': (params, customEvent) => {
                    // 触发事件
                    that._PB.emit('default', 'NETWORK.zoom', that);
                    // 加载自定义事件
                    customEvent && customEvent(params);
                },
                'nearlyStabilized': (params, customEvent) => {
                    // 触发事件
                    that._PB.emit('default', 'NETWORK.nearlyStabilized', that);
                    // 加载自定义事件
                    customEvent && customEvent(params);
                },
            };
            // 合并事件名称
            let newEvents = Object.keys(defaultEvents).concat(Object.keys(customEvents));
            newEvents = unique(newEvents); // 去重

            //循环绑定事件
            // console.log('即将绑定事件列表：', newEvents);
            newEvents.forEach(event => {
                let ce = customEvents[event];
                if (!ce) {
                    ce = {before: undefined, after: undefined};
                } else if (typeof ce === "function") {
                    ce = {before: undefined, after: ce};
                } else {
                    ce = {
                        before: typeof ce.before === "function" ? ce.before : undefined,
                        after: typeof ce.after === "function" ? ce.after : undefined,
                    };
                }
                // 去掉原有的事件
                that._network.off(event);
                // 重新绑定事件
                if (Object.keys(defaultEvents).includes(event)) { // 如果defaultEvents有就合并
                    that._network.on(event, (params) => {
                        let continueFlag = true;
                        if (ce.before) {
                            continueFlag = (ce.before(params) !== false);
                        }
                        if (continueFlag) {
                            defaultEvents[event](params, ce.after);
                        }
                    })
                } else { // defaultEvents没有，使用用户自定与的
                    that._network.on(event, (params) => {
                        let continueFlag = true;
                        if (ce.before) {
                            continueFlag = (ce.before(params) !== false);
                        }
                        if (continueFlag) {
                            ce.after(params);
                        }
                    });
                }
            })
        } else {
            console.log('Mynetwork3不正确的事件集合')
        }

        // 公共的click方法,切换点击后点和边的各种中间状态
        const commClick = (params) => {
            let that = this;
            if (params.nodes.length === 0 && params.edges.length === 1) { // 只选中了一条边
                // console.log('commClick->选中了一条边');
                that._mediacy.edges._beforeLastSelect = that._mediacy.edges._lastSelect;
                that._mediacy.edges._lastSelect = that._mediacy.edges._currentSelect;
                that._mediacy.edges._currentSelect = params.edges[0];

                // 清空选中节点数据
                that._mediacy.nodes._beforeCurrentSelect = that._mediacy.nodes._currentSelect;
                that._mediacy.nodes._currentSelect = undefined; // 清空选中节点
                that._mediacy.nodes._beforeLastSelect = that._mediacy.nodes._lastSelect;
                that._mediacy.nodes._lastSelect = undefined// 清空选中节点
            } else if (params.nodes.length === 1) { // 选中单个点
                // console.log('commClick->选中单个点');
                // tip: * 顺序不能乱 *
                if (params.nodes[0] != that._mediacy.nodes._currentSelect) {
                    if (params.nodes[0] == that._mediacy.nodes._lastSelect) {
                        that._mediacy.nodes._beforeLastSelect = undefined
                    } else {
                        that._mediacy.nodes._beforeLastSelect = that._mediacy.nodes._lastSelect
                    }
                    if (that._mediacy.nodes._currentSelect) {
                        that._mediacy.nodes._lastSelect = that._mediacy.nodes._currentSelect
                    }
                    that._mediacy.nodes._beforeCurrentSelect = that._mediacy.nodes._currentSelect;
                    that._mediacy.nodes._currentSelect = params.nodes[0];

                    // 清空选中边
                    that._mediacy.edges._beforeCurrentSelect = that._mediacy.edges._currentSelect;
                    that._mediacy.edges._currentSelect = undefined; // 清空选中节点
                    that._mediacy.edges._beforeLastSelect = that._mediacy.edges._lastSelect;
                    that._mediacy.edges._lastSelect = undefined// 清空选中节点
                }

            } else { // 其他状况
                console.log('commClick->其他状况');
                that._mediacy.nodes._beforeLastSelect = that._mediacy.nodes._lastSelect; // 清空上个选中节点
                that._mediacy.nodes._lastSelect = undefined; // 清空选中节点

                that._mediacy.nodes._beforeCurrentSelect = that._mediacy.nodes._currentSelect;// 清空选中节点数据
                that._mediacy.nodes._currentSelect = undefined;// 清空选中节点数据

                that._mediacy.edges._beforeCurrentSelect = that._mediacy.edges._currentSelect;// 清空选中边
                that._mediacy.edges._currentSelect = undefined;// 清空选中边
            }
            // console.log('commClick->that._mediacy=', that._mediacy)
        }
    }

    presenting(nodeId) {
        this._mediacy.nodes._beforePresenting = this._mediacy.nodes._presenting;
        this._mediacy.nodes._presenting = nodeId;
        if (this._mediacy.nodes._beforePresenting !== this._mediacy.nodes._presenting) {
            this.updateNodeMediacy();
        }
    }

    //-------------------------------------------------
    /**
     * 设置network的布局方式
     * @param direction 布局方向
     */
    setLayout(direction) {
        this._network.setOptions({
            'layout': {
                'improvedLayout': true,
                'hierarchical': {
                    'enabled': true,
                    'direction': direction,
                },
            },
        })
    }

    /**
     * 设置仿真方式
     * @param solver
     */
    setPhysics(solver) {
        this._network.setOptions({
            'layout': {
                'hierarchical': {
                    'enabled': false,
                },
            },
            'physics': {
                'solver': solver,
            },
        })
    }

    /**
     * 设置仿真弹簧长度--节非固定节点间距
     * @param value
     */
    setSpringLength(value) {
        // tip: 注意不同的布局模式
        this._network.setOptions({
            'physics': {
                'forceAtlas2Based': {
                    'springLength': value,
                },
                'barnesHut': {
                    'springLength': value,
                },
            },
        })
    }

    //调节固定节点间距
    setSpringLengthFix(value) {
        let that = this;
        let val = value>20?(value-20):(value/20);
        let nodes = that._graph.nodes.get();
        if (nodes.findIndex(node => node.fixed && !node._locked) >= 0) { // 判断是否是记录的拖动节点
            that._graph.nodes.update(nodes.filter(
                n => n.fixed
            ).map(n => {
                n.x = n.fx*val;
                n.y = n.fy*val;
                return n;
            }), 'magicId$doNotAutoStartSimulation');
        }
        // 更新节点、边各种中间状态的样式
        that.updateNodeMediacy();
    }

    //设置图标大小
    setImgSize(value) {
        let that = this;
        let nodes = that._graph.nodes.get();
        if (nodes) {
            that._graph.nodes.update(nodes.map(n => {
                n.size = value;
                return n;
            }), 'magicId$doNotAutoStartSimulation');
        }
        // 更新节点、边各种中间状态的样式
        that.updateNodeMediacy();
    }

    //设置字体大小
    setFontSize(value) {
        let that = this;
        let nodes = that._graph.nodes.get();
        if (nodes) {
            that._graph.nodes.update(nodes.map(n => {
                n.font.size = value;
                return n;
            }), 'magicId$doNotAutoStartSimulation');
        }
        // 更新节点、边各种中间状态的样式
        that.updateNodeMediacy();
    }

    /**
     * 解析指定对象中有效的边
     * @param obj array 待解析的对象数据
     * @param getId bool 是否只要返回id
     * @returns {Array} 返回对象数据或者id数组
     */
    getEdges = (obj, getId = false) => {
        return this.getItems('edge', obj, getId)
    };

    /**
     * 解析指定对象中有效的节点
     *
     * @param obj array 待解析的对象数据
     * @param getId bool 是否只要返回id
     * @returns {Array} 返回对象数据或者id数组
     */
    getNodes = (obj, getId = false) => {
        return this.getItems('node', obj, getId)
    };

    /**
     * 获取数据集的对象
     * @param type string node|edge
     * @param obj
     * @param getId bool 是否只要id
     * @returns {Array}
     */
    getItems = (type, obj, getId = false) => {
        let nodesIds = [];
        let filterFn;
        if (Array.isArray(obj)) {
            if (obj.length > 0) {
                obj.forEach(item => {
                    if (item) {
                        if (item instanceof Object) {
                            if (item.id) {
                                nodesIds.push(item.id)
                            }
                        } else {
                            // 不是对象，则作为id
                            nodesIds.push(item)
                        }
                    }
                })
            }
            filterFn = ele => nodesIds.includes(ele.id);
        } else if (!_.isFunction(obj)) {
            nodesIds = [obj];
            filterFn = ele => nodesIds.includes(ele.id);
        } else {
            filterFn = obj;
        }

        // 去除关系图中不存在的节点
        let dataset = undefined;
        if (type === 'edge') {
            dataset = this._graph.edges
        } else if (type === 'node') {
            dataset = this._graph.nodes
        } else {
            return false;
        }

        if (getId === true) {
            nodesIds = dataset.get({
                filter: filterFn,
            }).map(info => info.id);
        } else {
            nodesIds = dataset.get({
                filter: filterFn,
            });
        }

        // console.log('nodesIds =', nodesIds);
        return nodesIds
    };

    /**
     * 缩放图
     * @param h up放大 and down缩小
     * @param c 缩放值，在现有基础上放大或缩小倍数
     */
    zoom(h, c = 1) {
        let xy = this._network.getViewPosition();
        let scale = this._network.getScale();
        // if (scale < 0.5) return
        if (h === 'up') {
            c = 3 / 2;
        } else if (h === 'down') {
            c = 2 / 3;
        } else if (h === 1) {
            c = 1;
            scale = 1;
        } else {
            console.log('无效的缩放指令,忽略。');
            c = 1
        }
        this._network.moveTo({
            'position': xy,
            'scale': scale * c,
            // offset: {x:Number, y:Number}
            'animation': { // -------------------> can be a boolean too!
                'duration': 100,
            },
        })
    }

    /**
     * 聚焦节点
     * @param id
     * @param opt
     */
    /**
     * 聚焦节点
     * 效果：闪烁
     * @param {string|array} ids
     * @param opt
     * @param callback
     */
    focus(ids, opt, callback) {
        let nodes = this._graph.nodes.get(ids);
        let me = this;
        if (nodes) {
            if (!_.isArray(nodes)) {
                nodes = [nodes];
            }
            let originalFixed = false;
            if (nodes.length === 1) {
                // 根据屏幕设置缩放比例
                // let w_Width = document.body.offsetWidth;
                // let w_Height = document.body.offsetHeight;
                // console.log('当前屏幕的dpi：', this.getDPI());
                let scale = 1.5;

                // 合并opt
                let newOpt = {scale, animation: true, locked: true};
                if (opt) {
                    newOpt = deepObjectMerge(newOpt, opt);
                }
                if (newOpt.locked) {
                    originalFixed = nodes[0].fixed;
                    if (!originalFixed) {
                        nodes[0]._locked = true;
                        nodes[0].fixed = true;
                        nodes[0].fx = undefined;
                        nodes[0].fy = undefined;
                        nodes[0].initialX = undefined;
                        nodes[0].initialY = undefined;
                        this._graph.nodes.update(nodes);
                    }
                }

                // 先聚焦节点
                this._network.focus(nodes[0].id, newOpt);
            } else {
                // fit
                this._network.fit({nodes: nodes.map(node => node.id), animation: true});
            }
            setTimeout(() => {
                try {
                    me.flash(ids, 2, 600, () => {
                        if (!originalFixed && nodes.length === 1 && nodes[0]._locked) {
                            setTimeout(() => {
                                nodes = this._graph.nodes.get(ids);
                                if (nodes) {
                                    if (!_.isArray(nodes)) {
                                        nodes = [nodes];
                                    }
                                    nodes[0].fixed = false;
                                    nodes[0]._locked = false;
                                    this._graph.nodes.update(nodes, 'magicId$doNotAutoStartSimulation');
                                }
                            }, 1000);
                        }

                        // 回调
                        callback && callback();

                        // 更新状态，貌似没必要，先加上
                        me.updateNodeMediacy();
                    });
                } catch (e) {
                    console.log(e);
                    // 忽略
                }
            }, 1300);
        } else {
            // 回调
            callback && callback();
        }
    }

    //
    /**
     * 闪烁节点
     * @param ids 节点 id
     * @param times 次数 默认2次
     * @param duration 持续时间
     * @param callback 回调
     */
    flash(ids, times = 2, duration = 600, callback) {
        let nodes = this._graph.nodes.get(ids);
        if (nodes) {
            let me = this;
            let idxObj = {i: 0};
            if (!_.isArray(nodes)) {
                nodes = [nodes];
            }
            let flashFn = () => {
                if (times <= idxObj.i) {
                    // 回调
                    callback && callback();
                    return;
                }
                idxObj.i++;
                nodes = this._graph.nodes.get(ids);
                if (!_.isArray(nodes)) {
                    nodes = [nodes];
                }
                nodes.forEach(node => node.becomeFocusNode());
                me._graph.nodes.update(nodes, 'magicId$doNotAutoStartSimulation');
                setTimeout(() => {
                    nodes = this._graph.nodes.get(ids);
                    if (!_.isArray(nodes)) {
                        nodes = [nodes];
                    }
                    nodes.forEach(node => node.cancelFocus());
                    me._graph.nodes.update(nodes, 'magicId$doNotAutoStartSimulation');
                    setTimeout(flashFn, duration / 2)
                }, duration / 2);
            };
            flashFn();
        } else {
            // 回调
            callback && callback();
        }
    }

    decorateEdges(fn) {
        if (!_.isFunction(fn)) return;
        let me = this;

        let edges = this.getEdges(() => true);
        edges = edges.map(edge => {
            let fromNode = edge.from ? me._graph.nodes.get(edge.from) : undefined;
            let toNode = edge.to ? me._graph.nodes.get(edge.to) : undefined;
            if (fromNode && toNode) {
                let decoration = fn({fromNode, toNode, edge});
                if (decoration) {
                    return new Edge({...edge, ...decoration});
                }
            }
            return undefined;
        });
        edges = edges.filter(edge => !!edge);

        if (edges && edges.length > 0) {
            this._graph.edges.update(edges);
        }
    }

    // 点亮节点
    light(ids, withEdges = true) {
        const stabilized = this._mediacy.network._stabilized;
        // console.time('[light-getNodes]');
        let nodesIds = this.getNodes(ids, true);
        let nodes = this._graph.nodes.get(nodesIds);
        // console.timeEnd('[light-getNodes]');
        if (nodes) {
            if (!_.isArray(nodes)) {
                nodes = [nodes];
            }
            // console.time('[light-cancelDarken]');
            nodes = nodes.filter(node => node.cancelDarken(1));
            // console.timeEnd('[light-cancelDarken]');
            // console.log(`[light] ${nodes.length} nodes to update`);
            // console.time('[light-update-nodes]');
            this._graph.nodes.update(nodes);
            // console.timeEnd('[light-update-nodes]');
        }

        let edges = [];
        if (withEdges === true) {
            // console.time('[light-find-edges]');
            this._graph.edges.forEach((edge) => {
                if (nodesIds.indexOf(edge.from) >= 0 || nodesIds.indexOf(edge.to) >= 0) {
                    if (edge.cancelDarken(1)) edges.push(edge)
                }
            });
            // console.timeEnd('[light-find-edges]');
            // console.log(`[light] ${edges.length} edges to update`);
            // console.time('[light-update-edges]');
            this._graph.edges.update(edges);
            // console.timeEnd('[light-update-edges]');
        }
        if (stabilized) {
            this._network.stopSimulation();
        }
    }

    // 正常亮度
    normalBrightness(ids, withEdges = true) {
        const stabilized = this._mediacy.network._stabilized;
        // console.time('[normal-getNodes]');
        let nodesIds = this.getNodes(ids, true);
        let nodes = this._graph.nodes.get(nodesIds);
        // console.timeEnd('[normal-getNodes]');
        if (nodes) {
            if (!_.isArray(nodes)) {
                nodes = [nodes];
            }
            // console.time('[normal-cancelDarken]');
            nodes = nodes.filter(node => node.cancelDarken(this._normalBrightness));
            // console.timeEnd('[normal-cancelDarken]');
            // console.log(`[normal] ${nodes.length} nodes to update`);
            // console.time('[normal-update-nodes]');
            this._graph.nodes.update(nodes);
            // console.timeEnd('[normal-update-nodes]');
        }

        let edges = [];
        if (withEdges === true) {
            // console.time('[normal-find-edges]');
            this._graph.edges.forEach((edge) => {
                if (nodesIds.indexOf(edge.from) >= 0 || nodesIds.indexOf(edge.to) >= 0) {
                    if (edge.cancelDarken(this._normalBrightness)) edges.push(edge)
                }
            });
            // console.timeEnd('[normal-find-edges]');
            // console.log(`[normal] ${edges.length} edges to update`);
            // console.time('[normal-update-edges]');
            this._graph.edges.update(edges);
            // console.timeEnd('[normal-update-edges]');
        }
        if (stabilized) {
            this._network.stopSimulation();
        }
    }

    // 变暗节点
    dark(ids, withEdges = true) {
        const stabilized = this._mediacy.network._stabilized;
        // console.time('[dark-getNodes]');
        let nodesIds = this.getNodes(ids, true);
        let nodes = this._graph.nodes.get(nodesIds);
        // console.timeEnd('[dark-getNodes]');
        if (nodes) {
            if (!_.isArray(nodes)) {
                nodes = [nodes];
            }
            // console.time('[dark-becomeDarken]');
            nodes = nodes.filter(node => node.becomeDarken());
            // console.timeEnd('[dark-becomeDarken]');
            // console.log(`[dark] ${nodes.length} nodes to update`);
            // console.time('[dark-update-nodes]');
            this._graph.nodes.update(nodes);
            // console.timeEnd('[dark-update-nodes]');
        }

        let edges = [];
        if (withEdges === true) {
            // console.time('[dark-find-edges]');
            this._graph.edges.forEach((edge) => {
                if (nodesIds.indexOf(edge.from) >= 0 || nodesIds.indexOf(edge.to) >= 0) {
                    if (edge.becomeDarken()) edges.push(edge)
                }
            });
            // console.timeEnd('[dark-find-edges]');
            // console.log(`[dark] ${edges.length} edges to update`);
            // console.time('[dark-update-edges]');
            this._graph.edges.update(edges);
            // console.timeEnd('[dark-update-edges]');
        }
        if (stabilized) {
            this._network.stopSimulation();
        }
    }

    /**
     * 聚焦 vis方式
     * @param id
     * @param opt
     */
    focusOfVis(id, opt) {
        // 聚焦节点发生改变
        if (id !== this._mediacy.nodes._focus) {
            this._mediacy.nodes._lastFocus = this._mediacy.nodes._focus;
            this._mediacy.nodes._focus = undefined;
            // 去除聚焦状态
            this.updateNodeMediacy(() => {
                // 合并opt
                let newOpt = {scale: 1.5, animation: true, locked: true};
                if (opt) {
                    newOpt = deepObjectMerge(newOpt, opt);
                }
                this._mediacy.nodes._focus = id;
                this._network.focus(id, newOpt);
                setTimeout(() => {
                    // 聚焦节点未变化时展示聚焦状态
                    if (this._mediacy.nodes._focus === id) {
                        this.updateNodeMediacy();
                    }
                }, 1100)
            });
            /*
            this._network.once('animationFinished', () => {
                this.updateNodeMediacy()
            })
            */
        }
    }

    /**
     * 平滑移动到指定位置
     *
     * @param x
     * @param y
     * @param opt
     */
    moveTo({x, y}, opt) {
        // 合并opt
        let newOpt = {scale: 1.5, animation: true, locked: false};
        if (opt) {
            newOpt = deepObjectMerge(newOpt, opt);
        }
        console.log('moveTo->newOpt', newOpt);
        // 移动画面到左边
        let now_position = this._network.getViewPosition();
        this._network.moveTo({
            position: {x: x - now_position.x, y: y - now_position.y},
            ...newOpt,
        });
    }

    /**
     * 按指定方向平滑移动
     *
     * @param direction
     * @param opt
     */
    move(direction, opt) {
        // 合并opt
        let newOpt = {animation: true, locked: false};
        if (opt) {
            newOpt = deepObjectMerge(newOpt, opt);
        }
        let now_position = this._network.getViewPosition();
        let width = document.documentElement.clientWidth;
        let height = document.documentElement.clientHeight;
        let standXY_Zero = this._network.DOMtoCanvas({x: 0, y: 0})
        let standXY = this._network.DOMtoCanvas({x: width, y: height})
        standXY={
          x:Math.abs(standXY.x - standXY_Zero.x),
          y:Math.abs(standXY.y - standXY_Zero.y),
        }
        if (direction === 'up') {
            now_position.y = now_position.y - standXY.y / 4;
        } else if (direction === 'left') {
            now_position.x = now_position.x - standXY.x / 4;
        } else if (direction === 'right') {
            now_position.x = now_position.x + standXY.x / 4;
        } else if (direction === 'down') {
            now_position.y = now_position.y + standXY.y / 4;
        } else {
            now_position = null;
        }

        // 移动画面到左边
        if (now_position) {
            this._network.moveTo({
                position: now_position,
                ...newOpt,
            });
        }

    }

    /**
     * 固化节点
     * @param ids
     * @param solidEdge 是否连边一起固化
     */
    solidNodes(ids = [], solidEdge = false) {
        // 固化节点所有的连接边
        let connectedEdges = [];
        let nodes = this.getNodes(ids);
        nodes = nodes.map(node => {
            if (solidEdge === true) {
                connectedEdges = connectedEdges.concat(this._network.getConnectedEdges(node.id))
            }
            if (node.becomeSolid()) return node
        });
        this._graph.nodes.update(nodes);

        if (solidEdge === true) {
            if (connectedEdges.length > 0) {
                let tempEdges = this._graph.edges.get(connectedEdges);
                tempEdges = tempEdges.map(edge => {
                    if (edge.becomeSolid()) return edge
                });
                this._graph.edges.update(tempEdges)
            }
        }
    }

    /**
     * 虚化节点
     * @param ids
     * @param grewEdge 是否连边一起虚化
     */
    grewNodes(ids = [], grewEdge = false) {
        // 固化所有节点的所有连接边
        let connectedEdges = [];
        let nodes = this.getNodes(ids);
        nodes = nodes.map(node => {
            if (grewEdge === true) {
                connectedEdges = connectedEdges.concat(this._network.getConnectedEdges(node.id))
            }
            if (node.cancelSolid()) return node
        });
        this._graph.nodes.update(nodes);

        if (grewEdge === true) {
            if (connectedEdges.length > 0) {
                let tempEdges = this._graph.edges.get(connectedEdges);
                tempEdges = tempEdges.map(edge => {
                    if (edge.cancelSolid()) return edge
                });
                this._graph.edges.update(tempEdges)
            }
        }
    }

    /**
     * 隐藏部分节点（画面中不显示）
     * 不用处理边
     * @param ids
     */
    hideNodes(ids) {
        let nodes = [];
        let nodesIds = this.getNodes(ids, true);
        this._graph.nodes.forEach((node, id) => {
            if (nodesIds.indexOf(id) === -1) {
                if (node.cancelHidden()) nodes.push(node)
            } else {
                if (node.becomeHidden()) nodes.push(node)
            }
        });
        this._graph.nodes.update(nodes)
    }

    /* 隐藏所有边 */
    hideAllEdges() {
        let edges = [], update = false;
        this._graph.edges.forEach(edge => {
            if (!edge.hidden && edge.becomeHidden()) {
                edges.push(edge);
                update = true
            }
        });
        // console.log('MyNetwork3.js > hideAllEdges -> update :', update);
        update && this._graph.edges.update(edges);
    }

    /* 显示所有边 */
    showAllEdges() {
        let edges = [], update = false;
        this._graph.edges.forEach(edge => {
            if (edge.hidden && edge.cancelHidden()) {
                edges.push(edge);
                update = true;
            }
        });
        // console.log('MyNetwork3.js > showAllEdges -> update :', update);
        update && this._graph.edges.update(edges);
    }

    /**
     * 显示全部节点（画面中显示）
     */
    showAll() {
        // node恢复正常
        let nodes = [];
        this._graph.nodes.forEach((node) => {
            if (node.cancelHide()) nodes.push(node)
        });
        this._graph.nodes.update(nodes);

        // edge恢复正常
        let edges = [];
        this._graph.edges.forEach((edge, index) => {
            if (edges.cancelHide()) edges.push(edge)
        });
        this._graph.edges.update(edges)
    }

    /**
     * 高亮指定节点，其余节点变暗
     * @param ids
     * @param reverse 连接边的显示方式，默认:高亮节点间的边高亮，相反=与高亮节点相连的边都高亮
     */
    lightNodes(ids = [], reverse = false) {
        let nodesIds = this.getNodes(ids, true);
        let nodes = [];
        this._graph.nodes.forEach((node, id) => {
            if (nodesIds.indexOf(id) === -1) {
                if (node.becomeDarken()) nodes.push(node)
            } else {
                if (node.cancelDarken()) nodes.push(node)
            }
        });
        this._graph.nodes.update(nodes);

        let edges = [];
        if (reverse === true) {
            this._graph.edges.forEach((edge) => {
                if (nodesIds.indexOf(edge.from) >= 0 || nodesIds.indexOf(edge.to) >= 0) {
                    if (edge.cancelDarken()) edges.push(edge)
                } else {
                    if (edge.becomeDarken()) edges.push(edge)
                }
            })
        } else {
            this._graph.edges.forEach((edge) => {
                if (nodesIds.indexOf(edge.from) >= 0 && nodesIds.indexOf(edge.to) >= 0) {
                    if (edge.cancelDarken()) edges.push(edge)
                } else {
                    if (edge.becomeDarken()) edges.push(edge)
                }
            })
        }
        this._graph.edges.update(edges)
    }

    /**
     * 高亮全部，fixme:如何更有效率？
     */
    lightAll() {
        // node恢复正常
        let nodes = [];
        this._graph.nodes.forEach((node) => {
            if (node.cancelDarken()) nodes.push(node)
        });
        this._graph.nodes.update(nodes);

        // edge恢复正常
        let edges = [];
        this._graph.edges.forEach((edge) => {
            if (edge.cancelDarken()) edges.push(edge)
        });
        this._graph.edges.update(edges)
    }

    /**
     * 计算某节点随机半径、随机角度的坐标位置
     * @param r 随机点出现的范围半径
     */
    randomRoundOfNode(r) {
        let txy = {}
        // 有连接节点，获取目标节点的xy位置
        let xy = this._timeline.getPositions([s.id])
        if (Object.keys(xy).length > 0) {
            // 在中心点周围圆周上生成随机点，100是半径
            let radius = Math.random() * 100 + 50 // 半径
            let angle = Math.random() * Math.PI * 2 //角度
            let offsetX = Math.cos(angle) * radius
            let offsetY = Math.sin(angle) * radius

            txy.x = xy[s.id].x + offsetX // 不重叠
            txy.y = xy[s.id].y + offsetY
        }
        return txy
    }


    /**
     * 计算某节点随机半径的圆周上平均分布的位置坐标
     * @param total 圆周生成位置数量
     * @param r 随机点出现的范围半径
     * @param node 圆周的中心节点
     */
    posRoundOfNode(total, r = 100, node = undefined) {
        /**
         * 圆周均匀分布坐标
         * @param total2 总数
         * @param r2 半径
         * @param xy2 中心点坐标
         * @returns {[]}
         */
        const fn = (total2, r2 = 100, xy2) => {
            let txy2 = [];
            // 在中心点周围圆周上生成随机点，100是半径
            let radius = Math.random() * r2 + 50; // 半径
            let angle_per = (360 / total) * Math.PI; //角度

            for (let i = 0; i < total2; i++) {
                let angle = angle_per * i; //角度
                let offsetX = Math.cos(angle) * radius;
                let offsetY = Math.sin(angle) * radius;
                txy2.push({
                    x: xy2.x + offsetX,
                    y: xy2.y + offsetY,
                })
            }

            return txy2
        };

        let txy = [];
        let xy = undefined;
        if (node) {
            // 有连接节点 -> 获取目标节点的xy位置
            xy = this._network.getPositions([node.id]);
            xy = xy[node.id];
        } else {
            // 没有指定中心节点 -> 获取窗口的中心位置
            xy = this._network.getViewPosition();
        }

        if (total === 1) {
            if (!node) {
                txy.push(xy);
            } else {
                txy = fn(total, r, xy)
            }
        } else if (total > 1) {
            txy = fn(total, r, xy)
        }

        return txy
    }

    // 恢复全部边
    // showAllEdges() {
    //     // edge恢复正常
    //     let edges = []
    //     this._graph.edges.forEach((edge, index) => {
    //         edge.hidden = false
    //         edges.push(edge)
    //     })
    //     this._graph.edges.update(edges)
    // }

    // 获取DPI
    getDPI() {
        console.log('window.devicePixelRatio:', window.devicePixelRatio);
        // alert('window.devicePixelRatio:', window.devicePixelRatio);
        let arrDPI = new Array;
        if (window.screen.deviceXDPI) {
            // 能获取到
            arrDPI[0] = window.screen.deviceXDPI;
            arrDPI[1] = window.screen.deviceYDPI;
        } else if (!window.devicePixelRatio) {
            // 1 2 ^
            console.log('window.devicePixelRatio:', window.devicePixelRatio);
        } else {
            // 计算出来，准吗？
            let tmpNode = document.createElement("DIV");
            tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
            document.body.appendChild(tmpNode);
            arrDPI[0] = parseInt(tmpNode.offsetWidth);
            arrDPI[1] = parseInt(tmpNode.offsetHeight);
            tmpNode.parentNode.removeChild(tmpNode);
        }
        return arrDPI;
    }

    /*
    * node -> Node  edge -> Edge
    * 把数据转成对象
    * */
    transformData(items, type) {
        let itemsData = items;
        if (type === 'node') {
            itemsData = itemsData.map(item => {
                if (item instanceof Node) {
                    return item;
                } else {
                    return new Node(item);
                }
            });
        } else if (type === 'edge') {
            itemsData = itemsData.map(item => {
                if (item instanceof Edge) {
                    return item;
                } else {
                    return new Edge(item);
                }
            });
        }

        return itemsData;
    }


    //-----------------------------------------------------------
    // 以下与图谱快照相关
    //-----------------------------------------------------------
    /**
     * 获取全部节点的vis座标
     * @param nodes
     */
    k_getNodesPos(nodes = undefined) {
        let this_network = this._network;
        this_network.storePositions();
        let res = {};
        if (!nodes) {
            nodes = this._graph.nodes
        }
        // return nodes;
        this._graph.nodes.forEach((node) => {
            // res = node
            // console.log('this_network=', this_network);
            let pos = this_network.getPositions([node['id']]);
            // console.log('pos=', pos);
            let poss = pos[node['id']];
            poss = this_network.canvasToDOM(poss);
            res[node['id']] = [poss['x'], poss['y']];
        });
        return res;
    }

    /**
     * 获取全部节点的vis座标canvas
     * @param nodes
     */
     k_getNodesPos_canvas(nodes = undefined) {
        let this_network = this._network;
        this_network.storePositions();
        let res = {};
        if (!nodes) {
            nodes = this._graph.nodes
        }
        // return nodes;
        this._graph.nodes.forEach((node) => {
            // res = node
            // console.log('this_network=', this_network);
            let pos = this_network.getPositions([node['id']]);
            let poss = pos[node['id']];
            res[node['id']] = [poss['x'], poss['y']];
        });
        return res;
    }

    /**
     * 快照用缩放
     * @param c 缩放值，在现有基础上放大或缩小倍数
     */
    k_scale(c = 1) {
        let xy = this._network.getViewPosition();
        this._network.moveTo({
            'position': xy,
            'scale': c,
            'animation': false,
        })
    }

    /**
     * 保存画布为图片
     * @param options 画面缩放
     */
    k_saveToImg(options) {
        console.log('画布保存为图片');
        let {filename, fileext, scale, width, height} = options;
        if (!fileext) {
            fileext = 'jpg';
        }
        // canvas 容器
        const container = document.getElementById('network'); // vis network 的容器
        //  重新设置容器的宽高
        container.style.width = width + 'px';
        container.style.height = height + 'px';
        // Tip:增加立即更新dom的动作,怎么刷新??
        let canvas = container.getElementsByTagName('canvas')[0];

        canvasToImage(canvas, {
            name: filename,
            type: fileext,
            quality: 1,
        });
    }

    /**
     * 聚焦节点 没有动画
     * @param id
     * @param scale
     */
    k_focusNode(id, scale) {
        this._network.focus(id, {scale, animation: false});
    }
}

// docs: network可订阅的事件,有其他需要再添加
/*
NETWORK.click => click
NETWORK.stabilize => stabilized,startStabilizing
 */
export default MyNetwork
