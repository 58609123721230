import httpUtil, {httpUtilWithNoMsg} from '@/utils/HttpUtil'
import SysConfig from '@/constants/sys.config';

export const API_CheckUsername = (username) => {
  return httpUtil.post(`/user/checkUserName?userName=${username}`, {axios: {custom_error_handling: 1}});
};

export const API_Login = (username, pwd, remember) => {
  return httpUtil.post("/user/login", {username, pwd, remember});
};

export const API_MagicLogin = () => {
  //return httpUtilWithNoMsg.get("/magic_login");
  return httpUtilWithNoMsg.get("/map_v2/user/magic_login", {http_hostname : window.location.hostname});
};

export const API_Register = (username) => {
  return httpUtil.post("/user/register", {username});
};

export const API_ResetPassword = (email) => {
  return httpUtil.post(`/user/getBackPwd/email/code?email=${email}&host=${encodeURI(`${SysConfig.baseUri}/resetPassword/{code}`)}`);
};

export const API_ResetPasswordSaveNewPassword = (password, token, strength) => {
  return httpUtil.post('/user/getBackPwd/email', {code: token, newPwd: password, strength});
};

export const API_LoginViaWeChat = (code) => {
  return httpUtil.post('/user/thirdPart/login/wx', {code});
};

export const API_GetWeChatFwhTicket = () => {
  return httpUtilWithNoMsg.get('/user/thirdPart/wx_fwh_ticket');
};

export const API_CheckWeChatFwhTicket = (ticket) => {
  return httpUtilWithNoMsg.get('/user/thirdPart/wx_fwh_ticket/actions/check', {ticket});
};

export const API_BindWeChat = (code, autoMerge) => {
  return httpUtilWithNoMsg.post('/user/thirdPart/bind/wx', {code, autoMerge});
};

export const API_UnbindWeChat = () => {
  return httpUtilWithNoMsg.post('/user/thirdPart/unbind/wx');
};

export const API_ChangePwd = (oldPwd, newPwd, strength) => {
  return httpUtil.post('/user/resetPwd', {newPwd, oldPwd, strength});
};

export const API_ChangeUserBasicInfo = ({nick, name, org, province, city, picId}) => {
  return httpUtil.put('/user/user', {nick, name, org, province, city, picId});
};

export const API_GetUserInfo = () => {
  return httpUtilWithNoMsg.get('/user/user/self');
};