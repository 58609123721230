import httpUtil,{getHttpUtil, getToken, httpUtilWithNoMsg} from '@/utils/HttpUtil';

export const API_ParseRelation = (text, parentNodeId) => {
  return httpUtil.post(`/view/relation/actions/parse_relation${parentNodeId ? `?parent_node_id=${parentNodeId}` : ''}`,
    text);
};

const httpUtil2 = getHttpUtil(false, 'https://snap.joinmap.ai');
//const httpUtil3 = getHttpUtil(false, 'http://g08.joinmap.ai');

//let token = getToken();
export const API_makeMapByNodeId = (nodeId) => {
  return httpUtil2.get(`/wth_server_api/tupu_make_by_id`, {node_id:nodeId});
};

export const API_makeMap = (params) => {
  return httpUtil2.get(`/wth_server_api/tupu_make_by_id_1`, params);
};

export const API_starNode = (label) => {
  return httpUtil.get(`/es/star_node/query/label?label=${label}`);
}

export const API_esMicroServiceQuery = (keyword, type=undefined, limit=10) => {
  return httpUtil.get(`/es/ms/query?keyword=${keyword}&size=${limit}`,{type:type});
}

export const API_esMicroServicePresentationQuery = (keyword, type=undefined, limit=30) => {
  return type!==undefined?httpUtil.get(`/view/micro_service/get_es_ms_presentation_type?keyword=${keyword}&limit=${limit}`,{type:type}):httpUtil.get(`/view/micro_service/get_es_ms_presentation?keyword=${keyword}&limit=${limit}`);
}

export const API_analysisNodeCutTen = (viewId, nodeId, fanme, tag) => {
  return httpUtilWithNoMsg.get(`/view/analysis/incident/get_node_result_ten/${viewId}/${nodeId}?fanme=${fanme}&tag=${tag}`);
};

export const API_analysisNodeForEdge = (viewId, nodeId, fanme, tag) => {
  //return httpUtilWithNoMsg.get(`/g08/v1web/link/getnodeforedge?view_id=${viewId}&node_id=${nodeId}&node_nm=${fanme}&node_tag=${tag}`);
  return httpUtilWithNoMsg.get(`/g08/analysis/v1web/node/get_similar_node?guid_map=${viewId}&node=${fanme+' '+tag}`);
};

export const API_analysisNodeForEdgeMore = (viewId, nodeId, params) => {
  return httpUtilWithNoMsg.post(`/g08/analysis/getnodeforedge/more?view_id=${viewId}&node_id=${nodeId}`,params);
};

export const API_analysisNodeForEdgeDeep = (viewId, nodeId, params) => {
  return httpUtilWithNoMsg.post(`/g08/analysis/getnodeforedge/deep?view_id=${viewId}&node_id=${nodeId}`,params);
};

export const API_analysisMilvusChopdown = (params) => {
  //return httpUtilWithNoMsg.get(`/g08/analysis/recommend/chop_layer_one/get_milvus_phrdown`,params);
  //return httpUtilWithNoMsg.get(`/g08/analysis/recommend/chop_layer_one/get_milvus_phrdown_v2`,params);
  //return httpUtilWithNoMsg.get(`/g08/analysis/recommend/chop_layer_one/get_milvus_phrdown_v5`,params);
  return httpUtilWithNoMsg.get(`/g08/analysis/recommend/chop_layer_one/get_milvus_phrdown_v6`,params);
};

export const API_analysisSemantemeByNodes = (params) => {
  //return httpUtilWithNoMsg.get(`/g08/v1web/analysis/chop_layer_one/get_semanteme_bynodes`,params);
  //return httpUtilWithNoMsg.get(`/g08/v1web/analysis/chop_layer_one/get_semanteme_bynodes_v2`,params);
  return httpUtilWithNoMsg.get(`/g08/v1web/analysis/chop_layer_one/get_semanteme_bynodes_v5`,params);
};

export const API_analysisChopdown = (params) => {
  return httpUtilWithNoMsg.get(`/g08/analysis/recommend/chop_layer_one/getphrdown`,params);
};

export const API_analysisChopdown_v2 = (params) => {
  return httpUtilWithNoMsg.post(`/g08/analysis/recommend/chop_layer_one/getphrdown_v2`,params);
};

export const API_analysisChopdown_english = (params) => {
  return httpUtilWithNoMsg.get(`/english/analysis/recommend/chop_layer_one/getphrdown`,params);
};

export const API_analysisChopdownBak = (params) => {
  return httpUtilWithNoMsg.get(`/g08/analysis/recommend/chop_layer_one/getphrdownbak`,params);
};

export const API_analysisChopdownBak_english = (params) => {
  return httpUtilWithNoMsg.get(`/english/analysis/recommend/chop_layer_one/getphrdownbak`,params);
};

export const API_analysisIncidentList = (params) => {
  return httpUtil.get(`/view/analysis/incident/get_incidents_form_ety_proc`,params);
};

export const API_analysisIncidentDetails = (params) => {
  return httpUtil.post(`/view/analysis/incident/get_incidents_detail_by_ids`,params);
};

export const API_analysisIncidentFromSearcher = (params) => {
  return httpUtil.get(`/view/analysis/incident/get_incidents_from_searcher`,params);
};

export const API_searchInputEvtList = (params) => {
  //return httpUtil.get(`/g08/search/evt`,params);
  if(params.ekind==='event'){
    return httpUtil.get(`/g08/search/evtlist`,params);
  }else{
    return httpUtil.get(`/g08/search/etylist`,params);
  }
};

export const API_matchInputEvtList = (params) => {
  //return httpUtil.get(`/g08/search/evt`,params);
  if(params.ekind==='event'){
    return httpUtil.get(`/g08/match/evtlist`,params);
  }else{
    return httpUtil.get(`/g08/match/etylist`,params);
  }
};
export const API_getEtyMoreInfo = (params) => {
  return httpUtil.get(`/g08/event/etymoreinfo`,params);
};

export const API_getPresentationByKeyword = (params) => {
  //return httpUtilWithNoMsg.get(`/g08/v1web/presentation/getbykeyword`,params);
  return httpUtilWithNoMsg.get(`/g08/v1web/analysis/getpresentationbykeyword`,params);
};

export const API_getPresentationNodes = (params) => {
  return httpUtilWithNoMsg.post(`/g08/v1web/analysis/getpresentationnode`,params);
};

export const API_getSubgraphByKeyword = (params) => {
  return httpUtil2.get(`/wth_server_api/ling_dao_project_pin_dao_jiekou`, params);
};

export const API_getDatasetByKeyword = (params) => {
  //return httpUtil2.get(`/wth_server_api/load_data_by_para_pin_dao_jiekou`,params);
  return httpUtilWithNoMsg.get(`/g08/analysis/bykeyword/getdataset`,params);
};

export const API_getDatasetDetail = (params) => {
  //return httpUtil2.get(`/wth_server_api/load_table_pin_dao_jiekou`, params);
  return httpUtilWithNoMsg.get(`/g08/analysis/bykeyword/getdatasetdetail`,params);
};

export const API_matchEvtList = (params) => {
  if(params.ekind==='event'){
    return httpUtil.get(`/g08/analysis/event_h01/matchevtlist`,params);
  }else{
    return httpUtil.get(`/g08/analysis/event_h01/matchetylist`,params);
  }
};

export const API_matchEvtListParent = (params) => {
  if(params.ekind==='event'){
    return httpUtil.get(`/g08/analysis/event_h01/matchevtlist_evt`,params);
  }else{
    return httpUtil.get(`/g08/analysis/event_h01/matchetylist_ety`,params);
  }
};

export const API_matchEvtListChild = (params) => {
  if(params.ekind==='event'){
    return httpUtil.post(`/g08/analysis/event_h01/matchevtlist_ety`,params);
  }else{
    return httpUtil.post(`/g08/analysis/event_h01/matchetylist_evnt`,params);
  }
};

export const API_checkChopTxt = (params) => {
  return httpUtilWithNoMsg.get(`/g08/v1web/chop/check_chop_txt`,params);
};

export const API_matchEtyList = (params) => {
  //return httpUtilWithNoMsg.get(`/g08/analysis/ety/matchlist_v4`,params);
  return httpUtilWithNoMsg.get(`/g08/analysis/event/matchlist`,params);
};

export const API_getComInfo = (params) => {
  return httpUtilWithNoMsg.get(`/g08/analysis/ety/get_com_info_v4`,params);
};

export const API_getEventByCom = (params) => {
  return httpUtilWithNoMsg.get(`/g08/analysis/ety/get_event_by_com`,params);
};

export const API_getEventByEty = (params) => {
  //return httpUtilWithNoMsg.get(`/g08/analysis/ety/get_event_by_ety`,params);
  return httpUtilWithNoMsg.get(`/g08/analysis/event/get_ety_event`,params);
};

export const API_getCpsInfo = (params) => {
  return httpUtilWithNoMsg.get(`/g08/analysis/ety/get_cps_info`,params);
};

export const API_getEttyFromCps = (params) => {
  //return httpUtilWithNoMsg.get(`/g08/analysis/ety/get_etty_from_cps`,params);
  return httpUtilWithNoMsg.get(`/g08/analysis/event/get_etty_from_event`,params);
};

export const API_getEttyInfo = (params) => {
  //return httpUtilWithNoMsg.get(`/g08/analysis/ety/get_etty_info`,params);
  return httpUtilWithNoMsg.get(`/g08/analysis/event/get_etty_info`,params);
};

export const API_streamChat = (params) => {
  //return httpUtilWithNoMsg.post(`/j59/stream`,params);
  return httpUtilWithNoMsg.post(`/g08/chat/stream`,params);
};

export const API_getNodeIds = (params) => {
  return httpUtilWithNoMsg.get(`/g08/v1web/node/get_node_id_by_sequence`,params);
};

export const API_matchNodeList = (params) => {
  return httpUtilWithNoMsg.get(`/g08/analysis/node/matchlist`,params);
};

//export const baseEventSourceUrl = "/base/g8667/stream";
export const baseEventSourceUrl = "/base/map_v2_stream";