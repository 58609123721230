import React from 'react';
import PropTypes from 'prop-types';
import {Modal, message, Form, Switch, Select, Upload, Button} from 'antd';

import {withReactStateHelper, autoSetState} from '@/libs/react-state-helper';
import {bindUtil} from '@/libs/core-decorators';
import PB, {SimplePB} from '@/libs/simplePB';
import {NetworkFileListLoadingStatus} from '@/libs/view/network/status';
import {NetworkEvents} from '@/libs/view/network/events';

import {getToken, REQUEST_BASE} from '@/utils/HttpUtil';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import Icon from '@/components/common/common.icon';

@withReactStateHelper
@bindUtil.asTargetClass
class Desktop extends React.Component {
  state = {
    // fit, fill, stretch, repeat, center
    backgroundDisplayType: 'fill',
    backgroundKey: 0,

    showConfigModal: false,
    savingConfig: false,
    configBackgroundUseImage: false,
    configBackgroundImageId: undefined,
    configBackgroundImageSrc: undefined,
    configBackgroundDisplayType: 'fill',
  };

  fileToUpload = undefined;

  @autoSetState
  @bindUtil.bindToProperty('props.viewDataProvider', 'fileListLoadingStatus')
  fileListLoadingStatus = {status: NetworkFileListLoadingStatus.IDLE};

  @autoSetState
  @bindUtil.bindToProperty('props.viewDataProvider', 'files')
  fileList = [];

  beforeUpload = file => {
    let me = this;

    if (file.size > 50 * 1024 * 1024) {
      message.error("上传图片不要超过50MB");
    } else {
      me.fileToUpload = file;
      const URL = window.URL || window.webkitURL;
      // noinspection JSCheckFunctionSignatures
      let img = new Image();
      img.src = URL.createObjectURL(file);
      me.setState({configBackgroundImageSrc: img.src, configBackgroundUseImage: true});
    }

    return false;
  };

  generateBackgroundCssByDisplayType = (displayType, style = {}) => {
    switch (displayType) {
      case 'fit':
        style.backgroundSize = 'contain';
        style.backgroundPosition = 'center center';
        style.backgroundRepeat = 'no-repeat';
        break;
      case 'stretch':
        style.backgroundSize = '100% 100%';
        style.backgroundPosition = 'center center';
        style.backgroundRepeat = 'no-repeat';
        break;
      case 'repeat':
        style.backgroundPosition = 'left top';
        style.backgroundRepeat = 'repeat';
        break;
      case 'center':
        style.backgroundPosition = 'center center';
        style.backgroundRepeat = 'no-repeat';
        break;
      case 'fill':
      default:
        style.backgroundSize = 'cover';
        style.backgroundPosition = 'center center';
        style.backgroundRepeat = 'no-repeat';
    }
    return style;
  }

  onCloseConfigModal = () => {
    let me = this;

    me.setState({showConfigModal: false});
  };

  onSaveDesktopBackground = () => {
    let me = this, {
      backgroundKey,
      configBackgroundUseImage,
      configBackgroundImageId,
      configBackgroundImageSrc,
      configBackgroundDisplayType,
    } = me.state;

    return new Promise((resolve, reject) => {
      me.setState({savingConfig: true}, () => {
        if (configBackgroundUseImage && !configBackgroundImageSrc && !configBackgroundImageId) {
          message.error('请先选择背景图片');
          reject();
        } else if (configBackgroundUseImage && me.fileToUpload) {
          if (me.fileToUpload.size > 10 * 1024 * 1024) {
            message.error('上传图片不要超过10MB');
            reject();
            return;
          }

          // 生成背景KEY
          let imageKey = Math.random();

          // 上传文件
          me.props.viewDataProvider.addFiles(undefined, '背景图', [me.fileToUpload], [{b: imageKey}]).then(() => {
            // 保存背景配置
            me.props.viewDataProvider.updateConfig(`$.viewData.desktop.background`, {
              type: 'customImage',
              imageKey,
              displayType: configBackgroundDisplayType,
            }).then(() => {
              me.setState({backgroundKey: imageKey, backgroundDisplayType: configBackgroundDisplayType,
                showConfigModal: false, savingConfig: false});
              message.success('背景设置成功');
              resolve();
            }).catch(() => {
              me.setState({savingConfig: false});
              message.error('背景设置失败');
              reject();
            });
          }).catch(() => {
            me.setState({savingConfig: false});
            message.error('背景设置失败');
            reject();
          });
        } else {
          // 保存背景配置
          me.props.viewDataProvider.updateConfig(`$.viewData.desktop.background`, {
            type: configBackgroundUseImage ? 'customImage' : 'none',
            imageKey: configBackgroundUseImage ? backgroundKey : 0,
            displayType: configBackgroundUseImage ? configBackgroundDisplayType : 'fill',
          }).then(() => {
            me.setState({backgroundKey: configBackgroundUseImage ? backgroundKey : 0,
              configBackgroundImageId: configBackgroundUseImage ? me.state.configBackgroundImageId : undefined,
              backgroundDisplayType: configBackgroundUseImage ? configBackgroundDisplayType : 'fill',
              showConfigModal: false, savingConfig: false});
            message.success('背景设置成功');
            resolve();
          }).catch(() => {
            me.setState({savingConfig: false});
            message.error('背景设置失败');
            reject();
          });
        }
      });
    });
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('view', 'desktop_background.show_config_modal', ({viewId}) => {
      if (me.props.viewDataProvider.viewId === viewId) {
        me.fileToUpload = undefined;
        me.setState({
          showConfigModal: true,
          savingConfig: false,
          configBackgroundUseImage: me.state.backgroundKey > 0 && !!me.state.configBackgroundImageId,
          configBackgroundImageSrc: undefined,
          configBackgroundDisplayType: me.state.backgroundDisplayType,
        });
      }
    });

    me.props.viewDataProvider.once(me, NetworkEvents.LOADING_STRUCTURE_SUCCESS, () => {
      me.props.viewDataProvider.loadConfig(`$.viewData.desktop`).then(config => {
        if (config) {
          if (config.background) {
            me.setState({
              backgroundKey: config.background.type === 'customImage' ? config.background.imageKey : 0,
              backgroundDisplayType: config.background.displayType
            });
          }
        }
      });
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this, backgroundImageStyle = {}, tmpBackgroundImageStyle = {};

    if (me.state.backgroundKey > 0 && me.fileListLoadingStatus.status === NetworkFileListLoadingStatus.SUCCESS) {
      me.fileList.find(attachment => {
        return attachment.fileList.find((file, idx) => {
          let meta = attachment.meta ? attachment.meta[idx] : {b: 0};
          if (meta.b === me.state.backgroundKey) {
            backgroundImageStyle = me.generateBackgroundCssByDisplayType(me.state.backgroundDisplayType, {
              backgroundImage: `url(${REQUEST_BASE}/view/project/file/${file['fileId']}?Authorization=${getToken()})`,
            });
            if (me.state.configBackgroundImageId !== file['fileId']) {
              let {fileId} = file;
              requestAnimationFrame(() => me.setState({
                configBackgroundImageId: fileId,
                configBackgroundUseImage: true,
              }));
            }
            return true;
          } else {
            return false;
          }
        });
      });
    }

    if (me.state.showConfigModal && me.state.configBackgroundUseImage) {
      tmpBackgroundImageStyle = me.generateBackgroundCssByDisplayType(me.state.configBackgroundDisplayType, {
        backgroundImage: me.state.configBackgroundImageSrc
          ? `url(${me.state.configBackgroundImageSrc})`
          : `url(${REQUEST_BASE}/view/project/file/${me.state.configBackgroundImageId}?Authorization=${getToken()})`,
      });
    }

    return (
      <React.Fragment>
        <div
          style={{
            display: me.state.showConfigModal ? 'none' : 'block',
            position: 'fixed',
            left: 0,
            top: 0,
            height: '100vh',
            width: '100vw',
            pointerEvents: 'none',
            ...backgroundImageStyle,
          }}
        />
        <div
          style={{
            display: me.state.showConfigModal ? 'block' : 'none',
            position: 'fixed',
            left: 0,
            top: 0,
            height: '100vh',
            width: '100vw',
            pointerEvents: 'none',
            ...tmpBackgroundImageStyle,
          }}
        />
        <Modal
          title={'设置固定背景'}
          visible={me.state.showConfigModal}
          closable={!me.state.savingConfig}
          mask={false}
          width={'20rem'}
          okText={'保存'}
          onOk={() => me.onSaveDesktopBackground().then(() => me.onCloseConfigModal())}
          okButtonProps={{
            loading: me.state.savingConfig,
            style: {width: 'calc(10rem - 29px)', marginRight: '8px', marginLeft: '10px'},
            disabled: (me.state.configBackgroundUseImage && !me.state.configBackgroundImageSrc && !me.state.configBackgroundImageId)
          }}
          cancelText={'取消'}
          onCancel={() => me.onCloseConfigModal()}
          cancelButtonProps={{disabled: me.state.savingConfig, style: {width: 'calc(10rem - 29px)'}}}
        >
          <Form
            labelAlign={'left'}
            labelCol={{xs: {span: 24}, sm: {span: 12}}}
            wrapperCol={{xs: {span: 24}, sm: {span: 12}}}
            style={{margin: '-0.2em 0'}}
          >
            <Form.Item label={'上传图片文件'} style={{marginBottom: 0}}>
              <div style={{marginLeft: '5px'}}>
                <Upload
                  multiple={false}
                  showUploadList={false}
                  beforeUpload={me.beforeUpload}
                >
                  <Button
                    block={true}
                    style={{padding: '0 12px'}}
                  >
                    <Icon name={'picture'} />
                    <span style={{marginLeft: '11px', letterSpacing: '1px'}}>选择图片</span>
                  </Button>
                </Upload>
              </div>
            </Form.Item>
            <Form.Item label={'使用背景图片'} style={{marginBottom: 0}}>
              <div style={{marginLeft: '5px'}}>
                <Switch
                  checked={me.state.configBackgroundUseImage}
                  onChange={checked => me.setState({configBackgroundUseImage: checked})}
                  disabled={!me.state.configBackgroundImageId && !me.state.configBackgroundImageSrc}
                />
              </div>
            </Form.Item>
            <Form.Item label={'图片展示方式'} style={{marginBottom: 0}}>
              <div style={{marginLeft: '5px'}}>
                <Select
                  value={me.state.configBackgroundDisplayType}
                  onChange={value => me.setState({configBackgroundDisplayType: value})}
                  disabled={
                    !me.state.configBackgroundUseImage
                    || (!me.state.configBackgroundImageId && !me.state.configBackgroundImageSrc)
                  }
                >
                  <Select.Option value={'fill'}>填充</Select.Option>
                  <Select.Option value={'fit'}>适应</Select.Option>
                  <Select.Option value={'stretch'}>拉伸</Select.Option>
                  <Select.Option value={'repeat'}>平铺</Select.Option>
                  <Select.Option value={'center'}>居中</Select.Option>
                </Select>
              </div>
            </Form.Item>
          </Form>
          <div />
        </Modal>
      </React.Fragment>
    );
  }
}

Desktop.defaultProps = {
  bus: PB,
};

Desktop.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default Desktop;