import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import {message,Tooltip, Drawer, Button, Checkbox, Tabs, Input, Modal, Table, Popover, Radio,Row, Col} from 'antd';
import PB, {SimplePB} from '@/libs/simplePB';
import {IconTypes} from '@/constants/common';
import Icon from '@/components/common/common.icon';
import {IconFont} from '@/components/common/common.fonticon';
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import RelationDataProvider from '@/components/common/dataProvider/common.dataProvider.relation';
import {uniqueArrayObj} from "@/utils/Common";
//import style from '@/style/common/node/common.node.explore.analysis.serach.input.less';
import style from '@/style/components/extendedFunctionality/generatReport.less';
import moment from "moment";
import {API_matchNodeList,API_matchEtyList,API_getPresentationByKeyword,API_getDatasetByKeyword,API_getEtyMoreInfo,API_matchEvtListChild,API_getPresentationNodes,API_getComInfo,API_getEventByCom,API_getCpsInfo
  ,API_getEttyFromCps,API_getEttyInfo,API_getEventByEty } from "@/api/relation";
import {API_getNodeListByCondition} from "@/libs/view/network/map_v2_api.js";
import copy from 'copy-to-clipboard';
import {showErrorMessage} from '@/components/common/common.message';
import Highlighter from "react-highlight-words";
import intl from 'react-intl-universal';

const pageNum = 15;
const NODATAMSG = '';

class InputSearchFromDB extends React.PureComponent {

  //state = {};
  constructor(props){
    super(props);
    this.state = {
      status: 'idle',
      msg:'',
      bus_data: {keyword:''},
      searchText:'',
      dataList:[],
      start: 0,
      dataNum:0,
      showDrawer: true,
      showDrawerList: false,
      showDrawerDetail: false,
      nodeListSelectionMap: {},
      nodeListSelectedAmount: 0,
      nodeListAllSelectedIndeterminate: false,
      nodeListAllSelected: false,
      incidentDetail: {},
      APIPage: 0,
      activeKey: 'currentMap',
      isDeep: false,
      showModal: false,
      modalTitle: '',
      modalLoading: false,
      datasetDetail:{columns:[],dataSource:[]},
      liMore:false,
      kws:[],
      ekind:'currentMap',
      selectionText:'',
      showTextSearch:false,
      showTextSearchBox:{x:0,y:0},
      showEtyDrawer: false,
      etyStatus: 'idle',
      etyLoading: false,
      etyTitle:'',
      etyInfo:{},
      etyEventList:[],
      showCpsDrawer: false,
      cpsLoading:false,
      cpsInfo:{},
      advanced:false,
      words:[]
    };
  }

  pageNo = 0;
  TabPaneList = [
   // {key:'eventTitle',name:"事件解构",icon:'icon-flash-lamp'},
   // {key:'eventObject',name:"事件对象",icon:'icon-company'},
   // {key:'subgraph',name:"事件线索",icon:'icon-relative-graph'},
   // {key:'presentation',name:"清单报告",icon:'icon-child-relationship_3'},
   // {key:'dataset',name:"数据集报告",icon:'icon-database'},
  ];

  searchTypeList = [
    {key:'currentMap',name:"搜索当前图谱",icon:'icon-flash-lamp',abled:true},
  //  {key:'owner',name:"搜我的",icon:'icon-flash-lamp',abled:true},
  //  {key:'member',name:"搜参与",icon:'icon-flash-lamp',abled:true},
  //  {key:'channel',name:"搜频道",icon:'icon-flash-lamp',abled:true},
  //  {key:'com',name:"搜企业",icon:'icon-company',abled:true},
  //  {key:'who',name:"搜人物",icon:'icon-relative-graph',abled:true},
  //  {key:'presentation',name:"搜清单报告",icon:'icon-child-relationship_3',abled:true},
  //  {key:'dataset',name:"搜数据集",icon:'icon-database',abled:true},
  ];
  cpsSrcNames = {'200':'论文','300':'专利','500':'软著'};

  evt={
    //window.event和事件参数对象e的兼容
    getEvent:function (evt) {
      return window.event||evt;
    },
    //可视区域的横坐标的兼容代码
    getClientX:function (evt) {
      return this.getEvent(evt).clientX;
    },
    //可视区域的纵坐标的兼容代码
    getClientY:function (evt) {
      return this.getEvent(evt).clientY;
    },
    //页面向左卷曲出去的横坐标
    getScrollLeft:function () {
      return window.pageXOffset||document.body.scrollLeft||document.documentElement.scrollLeft||0;
    },
    //页面向上卷曲出去的纵坐标
    getScrollTop:function () {
      return window.pageYOffset||document.body.scrollTop||document.documentElement.scrollTop||0;
    },
    //相对于页面的横坐标(pageX或者是clientX+scrollLeft)
    getPageX:function (evt) {
      return this.getEvent(evt).pageX? this.getEvent(evt).pageX:this.getClientX(evt)+this.getScrollLeft();
    },
    //相对于页面的纵坐标(pageY或者是clientY+scrollTop)
    getPageY:function (evt) {
      return this.getEvent(evt).pageY?this.getEvent(evt).pageY:this.getClientY(evt)+this.getScrollTop();
    }
  };

  onNodeListSelectAllChanged = e => {
    let me = this, nodeListAllSelected = e.target.checked, nodeListSelectionMap = {}, nodeListSelectedAmount = 0;
    if (nodeListAllSelected) {
      Object.keys(me.state.nodeListSelectionMap).forEach(k => nodeListSelectionMap[k] = true);
      nodeListSelectedAmount = Object.keys(me.state.nodeListSelectionMap).length;
    }else{
      Object.keys(me.state.nodeListSelectionMap).forEach(k => nodeListSelectionMap[k] = false);
    }
    me.setState({
      nodeListAllSelected,
      nodeListAllSelectedIndeterminate: false,
      nodeListSelectionMap,
      nodeListSelectedAmount,
    });
  };

  onNodeListSelectionChanged = (nodeId, e) => {
    let me = this, selected = e.target.checked, textList = [],
      nodeListSelectedAmount = me.state.nodeListSelectedAmount,
      nodeListSelectionMap = {...me.state.nodeListSelectionMap};

    if (selected) {
      nodeListSelectedAmount++;
      nodeListSelectionMap[nodeId] = true;
    } else {
      nodeListSelectedAmount--;
      nodeListSelectionMap[nodeId] = false;
    }

    me.setState({
      nodeListAllSelected:  Object.keys(me.state.nodeListSelectionMap).length === nodeListSelectedAmount,
      nodeListAllSelectedIndeterminate: nodeListSelectedAmount > 0 &&  Object.keys(me.state.nodeListSelectionMap).length > nodeListSelectedAmount,
      nodeListSelectionMap: nodeListSelectionMap,
      nodeListSelectedAmount: nodeListSelectedAmount,
    });

    nodeListSelectedAmount>0 && this.state.dataList.forEach(item => {
      if (item) {
        if(nodeListSelectionMap[item.id]){
          textList.push(item.fname + ' ' + item.tag||'' );
          textList.push(item.description||'' );
        }
        if(item.nodes){
          item.nodes.forEach((n, i) => {
            if(nodeListSelectionMap[n.id]){
              textList.push(n.fname + ' ' + n.tag||'' );
              textList.push(n.description||'' );
            }
          })
        }
      }
    })
    PB.emit('report', 'reference.material.changer', {material_list: textList});
  };

  setStateNull = (res) => {
    this.setState({
      status: res.type,
      dataList: [],
      msg:res.msg,
      dataNum:0,
      nodeListAllSelected: false,nodeListAllSelectegetDetaildIndeterminate: false,nodeListSelectionMap:{},nodeListSelectedAmount:0
    });
  }

  setStateSuccess = (val) => {
    this.setState({
      status: 'success',
      dataList: val.list,
      dataNum:0,//parseInt(val.count||0),
      kws:val.kws||[],
      nodeListSelectionMap:val.nodeListSelectionMap,
      nodeListAllSelected: false,nodeListAllSelectedIndeterminate: false,nodeListSelectedAmount:0
    });
  }

  getNodeMatch = async () => {
    let me = this,nodeListSelectionMap={};
    let params ={
      keyword:me.state.bus_data.keyword,
      start: 0,
      limit: pageNum*6,
      ekind: me.state.activeKey,
      id_user: localStorage.getItem('userId')
    }
    API_matchNodeList(params).then((response) => {
      if(response && response.data && response.data.code === 0 && response.data.data && response.data.data.list && response.data.data.list.length>0){
        let data = response.data.data;
        let _data = [],id_list = [];
        response.data.data.list.forEach((node, idx) => {
          let item = node
          item.prob = ''
          nodeListSelectionMap[item.id] = false;
          item.nodes = [];
          id_list.push(item.id);
          _data.push(item);
        });
        me.setStateSuccess({list:_data,count:data.count,kws:data.kws,nodeListSelectionMap:nodeListSelectionMap});
      }else{
        me.setStateNull({type:'success',msg:NODATAMSG});
      }
    }).catch(({code, msg}) => {
      me.setStateNull({type:'failed',msg});
    });
  }

  matchEty = async (ekind='com') => {
    let me = this,nodeListSelectionMap={};
    let params ={
      keyword:me.state.bus_data.keyword,
      start: me.state.start*10,
      limit: pageNum*3,
      ekind: ekind||me.state.ekind
    }
    API_matchEtyList(params).then((response) => {
      if(response && response.data && response.data.code === 0 && response.data.data && response.data.data.list && response.data.data.list.length>0){
        let data = response.data.data;
        let _data = [],id_list = [];
        response.data.data.list.forEach((ety, idx) => {
          let item = {
            id:ety[0],
            fname:ety[1],
            description:ety.slice(2,14).join(' '),
            prob:((ekind=='com'?ety[19]:ety[18])*100).toFixed(2)
          }
          //item.id = 'p_' + idx;
          nodeListSelectionMap[item.id] = false;
          item.nodes = [];
          id_list.push(item.id);
          _data.push(item);
        });
        me.setStateSuccess({list:_data,count:data.count,kws:data.kws,nodeListSelectionMap:nodeListSelectionMap});
        //me.matchEventChild(params.ekind,id_list,_data,data.count,data.kws);
      }else{
        me.setStateNull({type:'success',msg:NODATAMSG});
      }
    }).catch(({code, msg}) => {
      me.setStateNull({type:'failed',msg});
    });
  }

  matchEtyFromCps = async (ekind='com') => {
    let me = this,nodeListSelectionMap={};
    let params ={
      keyword:me.state.bus_data.keyword,
      start: me.state.start,
      limit: pageNum,
      ekind: ekind||me.state.ekind,
      view_id: me.props.viewId,
      advanced: me.state.advanced
    }
    API_getEttyFromCps(params).then((response) => {
      if(response && response.data && response.data.code === 0 && response.data.data && response.data.data.list && response.data.data.list.length>0){
        let data = response.data.data;
        let _data = [],id_list = [];
        response.data.data.list.forEach((ety, idx) => {
          let item = {
            id:ety.id,
            fname:ety.ename,
            description:ety.title,
            prob:'',
          }
          if(ekind==='evnt'){
            item.id_src = ety.id_src;
            item.id_cps = ety.id_cps;
            item.id_origin = ety.id_origin||'';
          }
          //item.id = 'p_' + idx;
          nodeListSelectionMap[item.id] = false;
          item.nodes = [];
          id_list.push(item.id);
          _data.push(item);
        });
        me.setStateSuccess({list:_data,count:data.count,kws:data.kws,nodeListSelectionMap:nodeListSelectionMap});
        //me.matchEventChild(params.ekind,id_list,_data,data.count,data.kws);
      }else{
        me.setStateNull({type:'success',msg:NODATAMSG});
      }
    }).catch(({code, msg}) => {
      me.setStateNull({type:'failed',msg});
    });
  }

  matchEvent = async (ekind='event') => {
    let me = this,nodeListSelectionMap={};
    let params ={
      keyword:me.state.bus_data.keyword,
      start: me.state.start,
      limit: pageNum,
      ekind: ekind==='event'?'event':me.state.ekind
    }
    RelationDataProvider.matchEvtListParent(params).then((data) => {
      if(data && data.list && data.list.length>0){
        let _data = [],id_list = [];
        data.list.forEach((item, idx) => {
          //item.id = 'p_' + idx;
          nodeListSelectionMap[item.id] = false;
          item.nodes = [];
          id_list.push(item.id);
          _data.push(item);
        });
        me.setStateSuccess({list:_data,count:data.count,kws:data.kws,nodeListSelectionMap:nodeListSelectionMap});
        me.matchEventChild(params.ekind,id_list,_data,data.count,data.kws);
      }else{
        me.setStateNull({type:'success',msg:NODATAMSG});
      }
    }).catch(({code, msg}) => {
      me.setStateNull({type:'failed',msg});
    });
  }

  matchEventChild = (ekind,id_list,list,count,kws) => {
    let me = this,nodeListSelectionMap={};
    let params ={
      id:id_list.join(','),
      ekind: ekind
    }
    API_matchEvtListChild(params).then(response => {
      if (response && response.data && response.data.code === 0) {
        let _data=[];
        let _child = response.data.data.list;
        list.forEach((item, idx) => {
          nodeListSelectionMap[item.id] = false;
          _child.forEach((d, i) => {
            if((ekind==='event' && item.id == d.id_evnt) ||(ekind!=='event' && item.id === d.id_ety)){
              d.id = 'c_' + idx + '_' + i;
              item.nodes.push(d);
              nodeListSelectionMap[d.id] = false;
            }
          })
          _data.push(item);
        })
        me.setStateSuccess({list:_data,count:count,kws:kws,nodeListSelectionMap:nodeListSelectionMap});
      }
    }).catch(error => {
      console.log('matchEventChild:',error)
    });
  }

  getPresentation = () => {
    let me = this,nodeListSelectionMap={};
    let params ={
      keyword:me.state.bus_data.keyword,
      start: me.state.start,
      limit: pageNum,
    }
    API_getPresentationByKeyword(params).then((response) => {
      if(response && response.data && response.data.code === 0 && response.data.data && response.data.data.list && response.data.data.list.length>0){
        let data = response.data.data;
        let _data = [],id_list = [];
        response.data.data.list.forEach((item, idx) => {
          nodeListSelectionMap[item.id] = false;
          _data.push(item);
          let content_json = JSON.parse(item.content);
          let nodeIds = content_json && content_json.nodeIds||[];
          if(nodeIds && nodeIds.length>0){
            id_list = id_list.concat(nodeIds);
          }
          //item.nodes.forEach((i, index) => {
          //  nodeListSelectionMap[i.id] = false;
          //})
        });
        me.setStateSuccess({list:_data,count:data.count,kws:data.kws,nodeListSelectionMap:nodeListSelectionMap});
        if(id_list && id_list.length>0){
          me.getPresentationNodes(id_list,_data,data.count,data.kws,me.state.bus_data.keyword);
        }
      }else{
        me.setStateNull({type:'success',msg:NODATAMSG});
      }
    }).catch(({code, msg}) => {
      me.setStateNull({type:'failed',msg});
    });
  }

  getPresentationNodes = (id_list,list,count,kws,keyword) => {
    let me = this,nodeListSelectionMap={};
    let params ={
      id_list:id_list
    }
    API_getPresentationNodes(params).then(response => {
      if (response && response.data && response.data.code === 0 && me.state.activeKey==='presentation' && keyword===me.state.bus_data.keyword) {
        let _data=[];
        let _child = response.data.data.list;
        list.forEach((item, idx) => {
          item.nodes=[];
          nodeListSelectionMap[item.id] = false;
          _child.forEach((d, i) => {
            if(item.content.indexOf(d.id)!=-1){
              d.id = 'c_' + idx + '_' + i;
              item.nodes.push(d);
              nodeListSelectionMap[d.id] = false;
            }
          })
          _data.push(item);
        })
        me.setStateSuccess({list:_data,count:count,kws:kws,nodeListSelectionMap:nodeListSelectionMap});
      }
    }).catch(error => {
      console.warn('getPresentationNodes:',error)
    });
  }


  getSubgraph = () => {
    let me = this,nodeListSelectionMap={};
    let params ={
      para:me.state.bus_data.keyword,
      start: me.state.start,
      limit: pageNum,
    }
    RelationDataProvider.getSubgraphByKeyword(params).then((data) => {
      if(data && data.graphs && data.graphs.length>0){
        let _data = [],_idx_rand = 0;
        data.graphs.forEach((item, idx) => {
          if(item.nodes && item.nodes.length>0){
            let _subgraph=item.nodes[0];
            _subgraph.id = idx + '_0';
            _subgraph.view_id = item.view_id;
            nodeListSelectionMap[_subgraph.id] = false;
            let sub_nodes = [];
            item.nodes.forEach((i, index) => {
              if(index>0){
                i.id = idx + '_' + index;
                i.view_id = item.view_id;
                nodeListSelectionMap[i.id] = false;
                sub_nodes.push(i);
              }
            })
            _subgraph.nodes = sub_nodes;
            _data.push(_subgraph);
          }
        });
        me.setStateSuccess({list:_data,count:data.count,kws:data.kws,nodeListSelectionMap:nodeListSelectionMap});
      }else{
        me.setStateNull({type:'success',msg:NODATAMSG});
      }
    }).catch(({code, msg}) => {
      me.setStateNull({type:'failed',msg});
    });
  }

  getDataset = () => {
    let me = this,nodeListSelectionMap={};
    let params ={
      keyword:me.state.bus_data.keyword,
      start: me.state.start,
      limit: pageNum,
    }
    API_getDatasetByKeyword(params).then((response) => {
      if(response && response.data && response.data.code === 0 && response.data.data && response.data.data.list && response.data.data.list.length>0){
        let data = response.data.data;
        let _data = [];
        data.list.forEach((item, idx) => {
          nodeListSelectionMap[item.id] = false;
          item.fname = item.caption;
          item.description = item.note;
          _data.push(item);
        });
        me.setStateSuccess({list:_data,count:data.count,kws:data.kws,nodeListSelectionMap:nodeListSelectionMap});
      }else{
        me.setStateNull({type:'success',msg:NODATAMSG});
      }
    }).catch(({code, msg}) => {
      me.setStateNull({type:'failed',msg});
    });
  }

  getNodeByKeywork = async () => {
    let me = this,nodeListSelectionMap={};
    let params ={
      keyword:me.state.bus_data.keyword,
      start: 0,
      limit: pageNum*6,
      ekind: me.state.activeKey,
      orderBy: 'update_time',
      orderType: 'DESC',
      id_user: localStorage.getItem('userId')
    }
    API_getNodeListByCondition(me.props.viewId,params).then((response) => {
      if(response && response.data && response.data.code === 0 && response.data.data && response.data.data.length>0){
        let data = response.data.data;
        let _data = [],id_list = [];
        response.data.data.forEach((node, idx) => {
          let item = node
          item.prob = ''
          nodeListSelectionMap[item.id] = false;
          item.nodes = [];
          id_list.push(item.id);
          _data.push(item);
        });
        me.setStateSuccess({list:_data,count:data.count,kws:data.kws,nodeListSelectionMap:nodeListSelectionMap});
      }else{
        me.setStateNull({type:'success',msg:NODATAMSG});
      }
    }).catch(({code, msg}) => {
      me.setStateNull({type:'failed',msg});
    });
  }

  formatEtyTitle = (ekind,info) => {
    let me = this;
    switch (ekind) {
      case 'who':
        return info.fname||info[0].fname;
        break;
      case 'com':
          return info.ename;
          break;
      case 'evnt':
        return info.title;
        break;
      default:
        return info.ename;
    }
  }

  getEtyInfo = (node) => {
    let me = this;
    let params ={
      id:node.id,
      keyword:node.fname,
      ekind:me.state.activeKey
    }
    if(me.state.activeKey==='evnt'){
      if(node.id_origin && node.id_origin>0){
        me.setState({
          etyStatus: 'success',
          etyLoading:false,
          etyInfo: node,
          etyTitle: node.fname
        });
        return;
      }else{
        params.id_src = node.id_src;
        params.id_cps = node.id_cps;
      }
    }
    API_getEttyInfo(params).then(response => {
      if (response && response.data && response.data.code === 0) {
        me.setState({
          etyStatus: 'success',
          etyLoading:false,
          etyInfo:response.data.data.info,
          etyTitle: me.formatEtyTitle(params.ekind,response.data.data.info)
        });
      }else{
        me.setState({
          etyStatus: 'failed',
          etyLoading:false,
          etyInfo:{},
          etyTitle:node.fname,
          //etyEventList:[],
          msg:'未获取到数据'
        });
        if(me.state.activeKey==='who'){
          me.getEventByEty(params.ekind,node);
        }
      }
    }).catch(error => {
      me.setState({
        etyStatus: 'failed',
        etyLoading:false,
        etyInfo:{},
        etyTitle:node.fname,
        //etyEventList:[],
        msg:error.msg
      });
      console.warn('getEtyInfo:',error)
    });
    if(me.state.activeKey!=='who' && me.state.activeKey!=='evnt'){
      setTimeout(() => {
        me.getEventByEty(params.ekind,node);
      }, 100);
    }
  }

  getEventByEty = (ekind,node) => {
    let me = this;
    let params ={
      id:node.id,
      ename:node.fname,
      ekind
    }
    API_getEventByEty(params).then(response => {
      if (response && response.data && response.data.code === 0) {
        me.setState({
          etyStatus: 'success',
          etyEventList:response.data.data.list
        });
      }else{
        me.setState({
          etyEventList:[],
        });
      }
    }).catch(error => {
      me.setState({
        etyEventList:[]
      });
      console.warn('API_getComInfo:',error)
    });
  }

  getCpsInfo = (id_cps,id_src) => {
    let me = this;
    let params ={
      id_cps:id_cps,
      id_src:id_src
    }
    API_getCpsInfo(params).then(response => {
      if (response && response.data && response.data.code === 0) {
        me.setState({
          status: 'success',
          cpsLoading:false,
          cpsInfo:response.data.data.info,
        });
      }else{
        me.setState({
          status: 'success',
          cpsLoading:false,
          cpsInfo:{},
          msg:'未获取到数据'
        });
      }
    }).catch(error => {
      me.setState({
        status: 'failed',
        cpsLoading:false,
        cpsInfo:{},
        msg:error.msg
      });
      console.warn('getCpsInfo:',error)
    });
  }

  doDataRefreshed = () => {
    let me = this;
    //if(me.state.bus_data && me.state.bus_data.keyword===''){
    //  return ;
    //}
    let words = me.state.searchText&&me.state.searchText.length>3?[...me.state.searchText.match(/[\u4E00-\u9FA5]/g),...me.state.searchText.split(/[!?,.:;‘’“”()<>{}\[\]]|[\u4E00-\u9FA5]| |　|\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3010|\u3011|\u007e]/)]:[]; 
    //let words = me.state.words;
    me.setState({status: 'loading',words,dataList: [],dataNum:0}, () => {
      switch (me.state.activeKey) {
        case 'channel':
          me.getNodeMatch();
          break;
        case 'presentation':
          me.getPresentation();
          break;
        case 'dataset':
          me.getDataset();
          break;
        case 'com':
          me.matchEtyFromCps(me.state.activeKey);
          /*
          if(!me.state.advanced){
            me.matchEtyFromCps(me.state.activeKey);
          }else{
            me.matchEty(me.state.activeKey);
          }*/
          break;
        case 'who':
          me.matchEtyFromCps(me.state.activeKey);
          /*
          if(!me.state.advanced){
            me.matchEtyFromCps(me.state.activeKey);
          }else{
            me.matchEty(me.state.activeKey);
          }*/
          break;
        case 'currentMap':
          me.getNodeByKeywork(me.state.activeKey);
          break;
        default:
          //me.matchEtyFromCps(me.state.activeKey);
          me.getNodeMatch();
          
      }
    });
  }
  onChangeRadio = (e) => {
    let me = this;
    me.setState({status: 'loading',dataList: [],start:0,dataNum:0,ekind:e.target.value}, () => {
      me.matchEvent('com');
    });
    
  }

  onLoadPrepageData =() =>{
    let me = this,start = me.state.start - pageNum;
    me.setState({start:start<0?0:start}, () => {
      me.doDataRefreshed();
    })
  }

  onLoadNextpageData =() =>{
    let me = this,start = me.state.start + pageNum;
    me.setState({start}, () => {
      me.doDataRefreshed();
    })
  }

  getDatasetDetail = (node) => {
    let me = this;
    let params ={
      table:node.table,
      cmd:node.cmd
    }
    RelationDataProvider.getDatasetDetail(params).then((data) => {
      if(data && data.list && data.list.length>0){
        let columns = [],dataSource = [];
        data.column.forEach((item, idx) => {
          columns.push({
            title: item,
            dataIndex: 'key_'+idx,
            key: 'key_'+idx,
          });
        });
        data.list.forEach((d, idx) => {
          let _data = {};
          d.forEach((item, idx) => {
            _data['key_'+idx] = item;
          });
          dataSource.push(_data);
        });
        me.setState({
          status: 'success',
          datasetDetail:{columns:columns,dataSource:dataSource},
          modalLoading:false
        });
      }else{
        me.setState({
          status: 'success',
          datasetDetail:{columns:[],dataSource:[]},
          msg:'未获取到数据',
          modalLoading:false
        });
      }
    }).catch(({code, msg}) => {
      me.setState({
        status: 'failed',
        datasetDetail:{columns:[],dataSource:[]},
        msg:msg,
        modalLoading:false
      });
    });
  }

  onDataRefreshed = () => {
    let me = this;
    me.setState({status: 'idle',start:0,dataList: [],dataNum:0,isDeep:false,liMore:false,kws:[]}, () => {
      me.doDataRefreshed();
    });
  };

  onCloseDetail = () => {
    this.setState({
      showDrawerDetail: false,
      incidentDetail: {}
    });
  }

  onClose = () => {
    this.setState({
      showDrawer: false,
      status: 'idle',
      dataList: []
    });
  }

  onEtyClose = () => {
    this.setState({
      showEtyDrawer: false,
      etyTitle:'',
      etyInfo:{},
      etyEventList:[]
    });
  }

  onCpsClose = () => {
    this.setState({
      showCpsDrawer: false,
      cpsInfo:{}
    });
  }

  doAddSubGraph =(e) => {
    e.stopPropagation();
    let me = this, nodeIdxMap = {},nodes=[],edges=[],index = 0;
    let currentNodeId = me.props.nodeInfo && me.props.nodeInfo.id||'';
    let pushNode = (nodeInfo, nodeId, nodes, nodeIdxMap) => {
      nodeInfo = {
        ...nodeInfo,
        id: nodeId,
        type: 0,
        'delete': 0,
        userConfirmed: true,
        status: 1,
        userId: me.props.userId,
        userPreferredType: nodeInfo.userPreferredType === undefined ? nodeInfo.aiPreferredType : nodeInfo.userPreferredType,
        forceAdd: true,
        replaceNodeId: true,
        meta: {
          ...(nodeInfo.meta || {}),
          status: 1,
        },
        initialX: undefined,
        initialY: undefined,
        //fx: undefined,
        //fy: undefined,
      }
      nodeIdxMap[nodeInfo.id] = nodes.length;
      nodes.push(nodeInfo);
    };
    me.state.dataList.forEach((item, idx) => {
      if(me.state.nodeListSelectionMap[item.id]){
        pushNode(item, index, nodes, nodeIdxMap);
        if(currentNodeId){
          let edgeInfo = {
            userConfirmed: true,
            status: 1,
            meta:{
              smooth:1
            },
            from : currentNodeId,
            toIndex : idx
          };
          edges.push(edgeInfo);
        }
        index++;
      }else{
        item.nodes.forEach((n, i) => {
          if(me.state.nodeListSelectionMap[n.id]){
            pushNode(n, index, nodes, nodeIdxMap);
            let edgeInfo = {
              userConfirmed: true,
              status: 1,
              meta:{
                smooth:1
              },
              from : currentNodeId,
              toIndex : index
            };
            edges.push(edgeInfo);
            index++;
          }
        })
      }
    })
    nodes.forEach((item, idx) => {
      item.nodes && item.nodes.length>0 && item.nodes.forEach((n, i) => {
        if(me.state.nodeListSelectionMap[n.id]){
          pushNode(n, index, nodes, nodeIdxMap);
          let edgeInfo = {
            userConfirmed: true,
            status: 1,
            meta:{
              smooth:1
            },
            fromIndex : idx,
            toIndex : index
          };
          edges.push(edgeInfo);
          index++;
        }
      })
    })
  }

  gotoIncidentList  = (keyword) => {
    let me = this;
    me.props.bus.emit('searchinput', 'ety.list.show',{viewId: me.props.viewId,keyword:keyword,exTag:false});
  };

  loadMore  = () => {
    let me = this;
    me.pageNo++;
    me.onDataRefreshed();
  };

  onSearchTypeChange = activeKey => {
    let me = this;
    me.pageNo = 0;
    //if (activeKey !== me.state.activeKey || me.state.searchText!==me.state.bus_data.keyword ) {
      me.setState({activeKey,status: 'idle',dataList:[],start:0,dataNum:0,isDeep:false,liMore:false,kws:[]}, () => {
        me.props.bus.emit('searchinput', 'ety.list.show',{viewId: me.props.viewId,keyword:me.state.searchText,exTag:false,activeKey});
      });
    //}
  };

  onTabChange = activeKey => {
    let me = this;
    me.pageNo = 0;
    //if (activeKey !== me.state.activeKey) {
      me.setState({activeKey,status: 'idle',dataList:[],start:0,dataNum:0,isDeep:false,liMore:false,kws:[],ekind:'com'}, () => {
        me.doDataRefreshed();
      });
    //}
  };

  onCopyNodeInfo = item => {
    let me = this, textList = [];
    textList.push(item.fname + ' ' + item.tag||'');
    textList.push(item.description||'' );
    if (textList.length <= 0) {
      message.warn('无可复制的文本。');
      return;
    }

    let result = copy(textList.join("\r\n"), {
      message: '请按下 #{key} 复制选中文本。',
    });

    if (result) message.success('推荐的候选信息已经复制到剪贴板。');
  };

  copySelectedNodesToClipboard = () => {
    let me = this, textList = [];
    me.state.nodeListSelectedAmount>0 && this.state.dataList.forEach(item => {
      if (item) {
        if(me.state.nodeListSelectionMap[item.id]){
          textList.push(item.fname + ' ' + item.tag||'' );
          textList.push(item.description||'' );
        }
        if(item.nodes){
          item.nodes.forEach((n, i) => {
            if(me.state.nodeListSelectionMap[n.id]){
              textList.push(n.fname + ' ' + n.tag||'' );
              textList.push(n.description||'' );
            }
          })
        }
      }
    })
    if (textList.length <= 0) {
      message.warn('请先选择要复制的文本。');
      return;
    }
    let result = copy(textList.join("\r\n"), {
      message: '请按下 #{key} 复制选中文本。',
    });

    if (result) message.success('选中文本已复制到剪切板。');
  };

  doClickEty = (e,node) => {
    e.stopPropagation();
    let me = this;
    if(['com','who','rnd','evnt'].includes(me.state.activeKey)){
      this.setState({
        showEtyDrawer: true,
        etyTitle: '',
        etyLoading: true,
        etyInfo:{},
        etyEventList:[],
        etyStatus:'idle'
      }, () => {
        me.getEtyInfo(node);
      });
    }
  }

  doClickEvent = (e,item) => {
    e.stopPropagation();
    let me = this;
    this.setState({
      showCpsDrawer: true,
      cpsLoading: true,
      cpsInfo:{},
    }, () => {
      //me.getCpsInfo(item.id_cps,item.id_src);
      me.setState({
        status: 'success',
        cpsLoading:false,
        cpsInfo:item,
      });
    });
  }

  doClickDataset = (e,node) => {
    e.stopPropagation();
    let me = this;
    let txt = me.getSelectionText();
    if(txt && txt.length>0){
      me.showSelectionText(e,txt);
      return;
    }
    switch (me.state.activeKey) {
      case 'presentation':
        if(node.view_id){
          window.open(`/mainview/relation/${node.view_id}`, '_blank');
        }
        break;
      case 'channel':
          if(node.view_id){
            window.open(`/mainview/relation/${node.view_id}`, '_blank');
          }
          break;
      case 'owner':
          if(node.view_id){
            window.open(`/mainview/relation/${node.view_id}`, '_blank');
          }
          break;
      case 'member':
        if(node.view_id){
          window.open(`/mainview/relation/${node.view_id}`, '_blank');
        }
        break;
      case 'dataset':
        if(node.cmd){
          this.setState({
            showModal: true,
            modalTitle: node.fname,
            modalLoading: true,
            datasetDetail:{columns:[],dataSource:[]}
          }, () => {
            me.getDatasetDetail(node);
          });
        }else if(node.url!=='None'){
          window.open(node.url);
        }
        break;
      default:
        me.doClickEty(e,node);
    }
    /*
    if(me.state.activeKey === 'dataset'){
      if(node.cmd){
        this.setState({
          showModal: true,
          modalTitle: node.fname,
          modalLoading: true,
          datasetDetail:{columns:[],dataSource:[]}
        }, () => {
          me.getDatasetDetail(node);
        });
      }else if(node.url!=='None'){
        window.open(node.url);
      } 
    }else if(node.view_id){
      //me.onCopyNodeInfo(node);
      window.open(`/mainview/relation/${node.view_id}`, '_blank');
    }else {
      me.doClickEty(e,node);
    }
    */
  }

  doSelectionText = (e) => {
    e.stopPropagation();
    let me = this;
    let txt = me.getSelectionText();
    if(txt && txt.length>0){
      me.showSelectionText(e,txt);
      return;
    }
  }

  showSelectionText = (e,txt) => {
    let me = this;
    me.setState({
      showTextSearch: true,
      selectionText:txt,
      showTextSearchBox:{x:me.evt.getPageX(e),y:me.evt.getPageY(e)}
    });
  }

  onSelectionTextSearch = () => {
    let me = this;
    me.props.bus.emit('searchinput', 'ety.list.show',{viewId: me.props.viewId,keyword:me.state.selectionText,exTag:false});
    me.hideSelectionText();
  };

  hideSelectionText = () => {
    let me = this;
    me.setState({
      showTextSearch: false,
      selectionText:''
    });
  }

  doLiMore = (e) => {
    e.stopPropagation();
    let me = this;
    me.setState({liMore: true});
  }

  onSearchChange = e => {
    let me = this;
    me.setState({searchText: e.target.value});
    //me.props.bus.emit('report', 'article.title.changer',e.target.value);
  }

  onSearch = () => {
    let me = this;
    me.props.bus.emit('searchinput', 'ety.list.show',{viewId: me.props.viewId,keyword:me.state.searchText,exTag:false});
  };

  descriptionCut = (html, words) => {
    let _html = html.replace(/<[^>]+>/g, ""); //去掉所有的html标记
    words.some((word,i) => {
      let _index = _html.indexOf(word);
      if(_index!=-1){
        _html = _html.substring(_index>10?(_index-10):_index);
        return _html;
      }
    })
    return _html;
  }

  handleCancel = () => {
    this.setState({
      showModal: false,
      modalTitle: ''
    });
  }

  getSelectionText = () => {
    /*let txt = "";
    if (window.getSelection) {
      txt = window.getSelection();
    }else if (document.getSelection) {
        txt = document.getSelection();
    }else if (document.selection) {
        txt = document.selection.createRange().text;
    }*/
    return window.getSelection().toString();
  }

  doChapterGenerateReport = (e) => {
    e.stopPropagation();
    this.props.bus.emit('report', 'generate.report',{});
  };

  downloadFile = (e) => {
    e.stopPropagation();
    this.props.bus.emit('report', 'download.report',{});
  };
  
  
  componentDidMount() {
    let me = this;
    //const queryParams = new URLSearchParams(me.props.history.location.search);
    //const vname = queryParams.get('m');
    //const fname = queryParams.get('n');
    me.props.bus.with(me).subscribe('searchinput', 'ety.list.show', data => {
      //if (me.props.visible)
       {
        //data.keyword = (data.exTag!=false&&me.props.viewInfo.tags&&me.props.viewInfo.tags.length>0?(data.keyword+' '+me.props.viewInfo.tags.replace(/\n/g, ' ')):data.keyword)||'';
        me.pageNo = 0;
        me.setState({
          showDrawer: true,
          activeKey: data.activeKey||me.state.activeKey||'com',//'subgraph',
          status: 'idle',
          dataNum:0,
          bus_data: data,
          searchText: data.keyword||'',
          isDeep:false,
          liMore:false,
          kws:[]
        }, () => {
          me.doDataRefreshed();
        });
      }
    })
    PB.emit('searchinput', 'ety.list.show', {keyword: me.props.nodeName});
    //me.setState({ searchText:fname});
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.nodeName != this.props.nodeName){
      PB.emit('searchinput', 'ety.list.show', {keyword: prevProps.node_name});
      this.forceUpdate();
    }
  }

  componentWillUnmount() {
    let me = this;
    me.pageNo = 0;
  }

  render() {
    let me = this, tabContent,moreContent;
    let words = me.state.words;
    //let words = [me.state.bus_data.keyword,...me.state.kws];
    //let words = me.state.searchText&&me.state.searchText.length>3?[...me.state.searchText.match(/[\u4E00-\u9FA5]/g),...me.state.searchText.split(/[!?,.:;‘’“”()<>{}\[\]]|[\u4E00-\u9FA5]| |　|\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3010|\u3011|\u007e]/)]:[]; 
    // 。 ？ ！ ， 、 ； ： “ ” ‘ ’ （ ） 《 》 【 】 ~[...me.state.searchText];//me.state.searchText.split('');
      if (me.state.status === 'success') {
        tabContent = me.state.dataList && me.state.dataList.length>0 ? (
          <ul className={style['list-content-li-box']}>
            <li className={me.state.activeKey === 'dataset'?style['list']:style['tree-list']}>
              <Checkbox
                indeterminate={me.state.nodeListAllSelectedIndeterminate}
                onChange={me.onNodeListSelectAllChanged}
                checked={me.state.nodeListAllSelected}
              >
                全选
              </Checkbox>
            </li>
            {me.state.dataList.map((item, index) => (
              <li className={me.state.activeKey === 'dataset'?style['list']:style['tree-list']}>
                <div className={style['title']}>
                  {me.state.activeKey !== 'dataset' &&
                  <Checkbox className={style['node-checkbox']}
                    onClick={e => e.stopPropagation()}
                    onChange={e => me.onNodeListSelectionChanged(item.id, e)}
                    checked={me.state.nodeListSelectionMap[item.id] === true}/>}
                    {me.state.activeKey === 'dataset' && <Icon type={IconTypes.ICON_FONT} name={'icon-database'} style={{marginRight: '0.7rem',float:'left',lineHeight:'1.8rem'}}/>}
                    <span className={style['node-fname']}>
                      <p onClick={e => {me.doClickDataset(e,item);}} className={style['node-title']}>
                        <Highlighter className={`${style['highlighterBox']} ${item.prob?style['prob']:''} ${me.state.activeKey !== 'dataset'?style['link']:''}`} 
                          highlightClassName={style["key-wd"]} searchWords={words} autoEscape={true} textToHighlight={item.fname}/>
                        <Popover content={(
                          <div style={{width: '350px', fontSize: '0.85rem'}}>
                            <p><Highlighter highlightClassName={style["key-wd"]} searchWords={words} autoEscape={true} textToHighlight={item.fname}/></p>
                            {item.tag && <p style={{margin: 0}}><Highlighter highlightClassName={style["key-wd"]} searchWords={words} autoEscape={true} textToHighlight={item.tag}/></p>}
                            {item.description && <p style={{margin: 0}}><Highlighter highlightClassName={style["key-wd"]} searchWords={words} autoEscape={true} textToHighlight={item.description}/></p>}
                          </div>
                        )}
                        placement="right"
                        >
                          <span style={{marginLeft: '0.7rem',float:'right'}} className={`${me.state.activeKey !== 'dataset'?style['link']:''}`} onClick={e => {me.doClickEty(e,item);}}><Icon type={IconTypes.ICON_FONT} name={me.state.activeKey !== 'dataset'&&me.state.activeKey !== 'presentation'?'icon-right-sorting-o':'icon-more-transverse'}/></span>
                        </Popover>
                        {item.prob && <span style={{marginRightt: '0.7rem',float:'right',fontSize:'0.85rem'}}>{item.prob}%</span>}
                      </p>
                      <p className={me.state.activeKey == 'presentation'?style['description']:style['description3']} onClick={e => {me.doSelectionText(e);}}>{item.description && 
                        <Highlighter highlightClassName={style["key-wd"]} searchWords={words} autoEscape={true} textToHighlight={me.state.activeKey == 'dataset' || me.state.activeKey == 'presentation' ? me.descriptionCut(item.description,words):item.description}/>}
                        {!item.description && <span>&nbsp;</span>}
                      </p>
                      {me.state.activeKey === 'dataset' && 
                        <p className={style['list-view-box']}>
                          <a onClick={e => {me.doClickDataset(e,item);}} target='_blank' className={`${style['label']} ${item.cmd?style['label-table']:style['label-html']}`}>
                          {item.cmd?'数据表':'外链'}
                          </a>
                        </p>}
                    </span>
                 
                </div>
                {me.state.activeKey !== 'dataset' &&
                <ul>
                  {item.nodes && item.nodes.length>0 && item.nodes.map((node, i) => (
                    (me.state.liMore || i<5)?(
                    <li className={style['list-content-child']}>
                        <span className={style['ant-tree-switcher']} >
                          <span className={`${style['ant-tree-switcher-leaf-line']} ${item.nodes.length==i+1?style['ant-tree-treenode-leaf-last']:''}`}></span>
                        </span>
                        <Checkbox className={style['node-checkbox']}
                          onClick={e => e.stopPropagation()}
                          onChange={e => me.onNodeListSelectionChanged(node.id, e)}
                          checked={me.state.nodeListSelectionMap[node.id] === true}/>
                        
                          <span className={style['node-fname']}>
                            <p onClick={e => {me.doClickDataset(e,node);}} className={style['node-title']}>
                              <Highlighter className={`${style['highlighterBox']} ${me.state.activeKey === 'subgraph' || me.state.activeKey === 'dataset'?style['link']:''}`} 
                               highlightClassName={style["key-wd"]} searchWords={words} autoEscape={true} textToHighlight={node.fname}/>
                              <Popover content={(
                                <div style={{width: '350px', fontSize: '14px'}}>
                                  <p><Highlighter highlightClassName={style["key-wd"]} searchWords={words} autoEscape={true} textToHighlight={node.fname}/></p>
                                  {node.tag && <p style={{margin: 0}}><Highlighter highlightClassName={style["key-wd"]} searchWords={words} autoEscape={true} textToHighlight={node.tag}/></p>}
                                  {node.description && <p style={{margin: 0}}><Highlighter highlightClassName={style["key-wd"]} searchWords={words} autoEscape={true} textToHighlight={node.description}/></p>}
                                </div>
                              )}
                              placement="right"
                              >
                                <span style={{marginLeft: '0.7rem',float:'right'}}><Icon type={IconTypes.ICON_FONT} name={'icon-more-transverse'}/></span>
                              </Popover>
                            </p>
                            <p className={style['description']} onClick={e => {me.doSelectionText(e);}}>{node.description && <Highlighter highlightClassName={style["key-wd"]} searchWords={words} autoEscape={true} textToHighlight={me.state.activeKey == 'dataset' || me.state.activeKey == 'presentation' ? me.descriptionCut(node.description,words):node.description}/>}</p>
                        </span>
                    </li>):null
                  ))}
                  {(!me.state.liMore && item.nodes && item.nodes.length>5)?(
                    <li onClick={e => {me.doClickDataset(e,node);}} className={style['list-content-child']}>
                      <span className={style['ant-tree-switcher']} >
                        <span className={`${style['ant-tree-switcher-leaf-line']} ${style['ant-tree-treenode-leaf-last']}`}></span>
                      </span>
                      <span onClick={e => {me.doLiMore(e);}} className={style['li-more']}> <Icon name={'down'} style={{margin: '0 0.23rem 0'}}/> 展开</span>
                    </li>):null}
                </ul>}
              </li>
              ))}
          </ul>
        ) :(<div className={style['market-drawer-body-tab-content-empty']}>
              <Icon name={'exclamation-circle'} theme={'outlined'} /><br />
              <span>{NODATAMSG}</span><br />
            </div>);
        moreContent = !((me.state.activeKey == 'channel')) ? ((me.state.dataList.length == pageNum || me.state.start >= pageNum)?(
          <div className={style['market-drawer-body-tab-content-page']} id='bottom' ref={bottomRef => {me.bottomRef = bottomRef;}} >
            {me.state.start >= pageNum && (<span><a onClick={()=>{me.onLoadPrepageData()}}>上一页</a></span>)}
            {me.state.dataList.length == pageNum && (<span><a onClick={()=>{me.onLoadNextpageData()}}>下一页</a></span>)}
          </div>):null):(null)    
        /*          
         moreContent = !((me.state.activeKey == 'com' && me.state.advanced == true)||me.state.activeKey == 'who' && me.state.advanced == true) ? ((me.state.dataList.length == pageNum || me.state.start >= pageNum)?(
          <div className={style['market-drawer-body-tab-content-page']} id='bottom' ref={bottomRef => {me.bottomRef = bottomRef;}} >
            {me.state.start >= pageNum && (<span><a onClick={()=>{me.onLoadPrepageData()}}>上一页</a></span>)}
            {me.state.dataList.length == pageNum && (<span><a onClick={()=>{me.onLoadNextpageData()}}>下一页</a></span>)}
          </div>):null):(null)
        
        moreContent = me.state.activeKey == 'dataset' || me.state.activeKey == 'presentation' ? (me.state.dataList.length == pageNum ?(
          <div className={style['market-drawer-body-tab-content-page']} id='bottom' ref={bottomRef => {me.bottomRef = bottomRef;}} >
            {me.state.start >= pageNum && (<span><a onClick={()=>{me.onLoadPrepageData()}}>上一页</a></span>)}
            {me.state.dataList.length == pageNum && (<span><a onClick={()=>{me.onLoadNextpageData()}}>下一页</a></span>)}
          </div>):null):(null)
        moreContent = me.state.activeKey == 'dataset' || me.state.activeKey == 'presentation' ? (me.state.dataList.length == pageNum ?(
          <div className={style['market-drawer-body-tab-content-page']} id='bottom' ref={bottomRef => {me.bottomRef = bottomRef;}} >
            {me.state.start >= pageNum && (<span><a onClick={()=>{me.onLoadPrepageData()}}>上一页</a></span>)}
            {me.state.dataList.length == pageNum && (<span><a onClick={()=>{me.onLoadNextpageData()}}>下一页</a></span>)}
          </div>):null):(<div className={style['market-drawer-body-tab-content-page']} id='bottom' ref={bottomRef => {me.bottomRef = bottomRef;}} ><span><a onClick={()=>{me.onLoadNextpageData()}}>换一批</a></span></div>)

        moreContent = me.state.dataNum > pageNum ?(
          <div className={style['market-drawer-body-tab-content-page']} id='bottom' ref={bottomRef => {me.bottomRef = bottomRef;}} >
            {me.state.start >= pageNum && (<span><a onClick={()=>{me.onLoadPrepageData()}}>上一页</a></span>)}
            {me.state.dataNum-me.state.start > pageNum && me.state.dataList.length == pageNum && (<span><a onClick={()=>{me.onLoadNextpageData()}}>下一页</a></span>)}
          </div>):null
        */
      }else if(me.state.status === 'failed'){
        tabContent = (<div className={style['market-drawer-body-tab-content-loading']}>
          <Icon name={'exclamation-circle'} theme={'outlined'} /><br />
          <span>数据准备中，请稍后<a onClick={() => me.onDataRefreshed()}>&lt;重试&gt;</a></span>
        </div>)
      }else{
        tabContent = (<div className={style['market-drawer-body-tab-content-loading']}>
          <Icon name={'loading'} theme={'outlined'} /><br />
          数据加载中，请稍后…
        </div>)
      }

    return (
      <React.Fragment>
        { (
            
              <div style={{ height: '100%',backgroundColor:'#fff',opacity:1}}>
                {false &&
                <div className={style['market-drawer-header']}>
                  <div className={style['market-drawer-title']} style={{flex:'auto'}}>
                    <Icon name="icon-jiansuo" theme="outlined" type={IconTypes.ICON_FONT} className={style['icon-img-right']}/>搜索素材
                  </div>
                  <div className={style['market-drawer-right']} style={{color:'#1890ff',flex:'none',paddingRight:0}}>
                    <a onClick={e => {this.doChapterGenerateReport(e);}} alt='按章节生成报告'><Icon name="icon-news" theme="outlined" type={IconTypes.ICON_FONT} className={style['icon-img-right']}/>生成报告</a>
                    <a style={{marginLeft:'2rem'}} onClick={e => {this.downloadFile(e);}}><Icon name="icon-share" theme="outlined" type={IconTypes.ICON_FONT} className={style['icon-img-right']}/>导出报告</a>
                  </div>
                </div>}
                {false && 
                <div className={style['market-drawer-header']}>
                  <Row type="flex" justify="space-between" className={style['market-drawer-title']} style={{height:'3.9rem',alignItems:'center',display:'flex'}}>
                    <Col span={8}><Icon name="icon-jiansuo" theme="outlined" type={IconTypes.ICON_FONT} className={style['icon-img-right']}/>搜索素材</Col>
                    <Col span={8} style={{textAlign:'center'}}><a onClick={e => {this.doChapterGenerateReport(e);}} alt='按章节生成报告'><Icon name="icon-news" theme="outlined" type={IconTypes.ICON_FONT} className={style['icon-img-right']}/>生成报告</a></Col>
                    <Col span={8} style={{textAlign:'right'}}><a style={{marginLeft:'2rem'}} onClick={e => {this.downloadFile(e);}}><Icon name="icon-share" theme="outlined" type={IconTypes.ICON_FONT} className={style['icon-img-right']}/>导出报告</a></Col>
                  </Row>
                </div>}
                <div className={style['market-drawer-body']}>
                  <div className={style['market-drawer-input-box']}>
                    <div style={{lineHeight:'2.2rem',height:'2.2rem',overflow:'hidden'}}>
                      <span style={{fontSize:'0.95rem'}}>输入搜索信息：</span>
                        {(me.state.activeKey==='com'||me.state.activeKey==='who') && <span style={{float:'right'}} title=''><Checkbox style={{marginRight:'0.3rem'}}
                          onChange={e => {e.stopPropagation();me.setState({advanced:!me.state.advanced})}}
                          checked={!me.state.advanced}/>关键词搜索 <Checkbox style={{marginRight:'0.3rem'}}
                          onChange={e => {e.stopPropagation();me.setState({advanced:!me.state.advanced})}}
                          checked={me.state.advanced}/>语义搜索</span>}
                    </div>
                    <Input
                        placeholder={'请输入搜索内容'}
                        value={me.state.searchText}
                        onChange={me.onSearchChange}
                        onSearch={me.onSearch}
                        style={{height:'2.5rem'}}
                      /></div>
                  <div style={{marginTop:'1rem',marginBottom:'0.5rem'}} key={'div-type-key'}>
                    {me.searchTypeList.map((item,index) => (
                      <>
                        {(index===3||index===5||index===6) && <span className={style['divide']} style={{marginRight:'1rem'}}> </span>}
                        <Popover content={item.abled?item.name:'开发中...'} placement="bottom">
                          <Button onClick={e => {e.stopPropagation();me.onSearchTypeChange(item.key)}} disabled={!item.abled} type={item.key===me.state.activeKey?'primary':'default'} style={{marginRight:'1.15rem',marginBottom:'0.5rem'}}>{item.name}</Button>
                        </Popover>
                      </>
                    ))}
                  </div>
                  <div className={style['market-drawer-body-tabs']}>
                    <div className={`${style['market-drawer-body-tab-content']} scrollbar scrollbar-none`}>
                      {tabContent}
                      {moreContent}
                    </div>
                  </div>
                </div>
                {false &&
                <div className={style['action-box']}>
                  <div className={style['action-left']}>
                    
                  </div>
                  {me.state.activeKey !== 'dataset' && 
                  <div className={style['action-right']}>
                    <Button type={'default'} onClick={me.copySelectedNodesToClipboard} disabled={!me.state.nodeListSelectedAmount} block={true} style={{marginRight:'1rem'}}>复制选中内容</Button>
                    <Button type={'default'} onClick={me.doAddSubGraph} disabled={!me.state.nodeListSelectedAmount} block={true}>添加到图谱</Button>
                  </div>}
                </div>}
              </div>
          )
        }
        {
          me.state.showEtyDrawer && me.state.etyInfo ? (
            <Drawer
              visible={me.props.visible}
              placement={'right'}
              destroyOnClose={true}
              mask={true}
              maskClosable={true}
              maskStyle={{backgroundColor: 'transparent'}}
              width={'47rem'}
              className={`${style['market-drawer-wrap']} ${style['market-drawer-wrap-ety-info']}`}
              onClose={me.onEtyClose}
              style={{zIndex:2000,opacity:1}}
            >
              <div style={{ height: '100%',backgroundColor:'#fff',opacity:1}}>
                <div className={style['market-drawer-header']}>
                  <div className={style['market-drawer-title']} style={{flex:'auto'}}>
                    <Icon name={me.state.activeKey!=='evnt'?'icon-company':'icon-flash-lamp-o'} theme="outlined" type={IconTypes.ICON_FONT} className={style['icon-img-right']}/>{me.state.etyTitle}
                  </div>
                  <div className={style['market-drawer-right']}>
                  </div>
                </div>
                <div className={style['market-drawer-body']}>
                  <div className={style['market-drawer-body-tabs']}>
                    <div className={`${style['market-drawer-body-tab-content']} scrollbar scrollbar-none`}>
                    {me.state.etyStatus === 'success' && <> 
                      {me.state.activeKey==='com' &&
                      <div className={style['ety-info']}>
                        {me.state.etyInfo.ename && <p><span>企业名称：</span>{me.state.etyInfo.ename}</p>}
                        {me.state.etyInfo.leader && <p><span>法定代表人：</span>{me.state.etyInfo.leader}</p>}
                        {me.state.etyInfo.province && <p><span>行政区划：</span>{me.state.etyInfo.province}{me.state.etyInfo.city}{me.state.etyInfo.district}</p>}
                        {me.state.etyInfo.code_03 && <p><span>统一社会信用代码：</span>{me.state.etyInfo.code_03}</p>}
                        {me.state.etyInfo.code_01 && <p><span>组织机构代码：</span>{me.state.etyInfo.code_01}</p>}
                        {me.state.etyInfo.code_02 && <p><span>工商注册号：</span>{me.state.etyInfo.code_02}</p>}
                        {me.state.etyInfo.code_tax && <p><span>纳税人识别号：</span>{me.state.etyInfo.code_tax}</p>}
                        {me.state.etyInfo.old_name && <p><span>曾用名：</span>{me.state.etyInfo.old_name}</p>}
                        {me.state.etyInfo.eng_name && <p><span>英文名称：</span>{me.state.etyInfo.eng_name}</p>}
                        {me.state.etyInfo.capital && <p><span>注册资本：</span>{me.state.etyInfo.capital}</p>}
                        {me.state.etyInfo.found_day && <p><span>成立日期：</span>{me.state.etyInfo.found_day}</p>}
                        {me.state.etyInfo.scale && <p><span>企业规模：</span>{me.state.etyInfo.scale}</p>}
                        {me.state.etyInfo.staff && me.state.etyInfo.staff>0 && <p><span>参保人数：</span>{me.state.etyInfo.staff}</p>}
                        {me.state.etyInfo.label_01 && <p><span>企业类型：</span>{me.state.etyInfo.label_01}</p>}
                        {me.state.etyInfo.label_02 && <p><span>组织形式：</span>{me.state.etyInfo.label_02}</p>}
                        {me.state.etyInfo.biz_scope && <p><span>经营范围：</span>{me.state.etyInfo.biz_scope}</p>}
                        {me.state.etyInfo.kind_01 && <p><span>行业：</span>{me.state.etyInfo.kind_01} {me.state.etyInfo.kind_02} {me.state.etyInfo.kind_03}</p>}
                        {false && me.state.etyInfo.brand_name && <p><span>品牌：</span>{me.state.etyInfo.brand_name}</p>}
                        {me.state.etyInfo.tag && <p><span>公司标签：</span>{me.state.etyInfo.tag}</p>}
                        {me.state.etyInfo.phone && <p><span>电话：</span>{me.state.etyInfo.phone} {me.state.etyInfo.phone_etc}</p>}
                        {me.state.etyInfo.email && <p><span>邮箱：</span>{me.state.etyInfo.email} {me.state.etyInfo.email_etc}</p>}
                        {me.state.etyInfo.main_addr && <p><span>企业地址：</span>{me.state.etyInfo.main_addr}</p>}
                        {me.state.etyInfo.report_addr && <p><span>年报地址：</span>{me.state.etyInfo.report_addr}</p>}
                        {me.state.etyInfo.website && <p><span>网址：</span><a href={me.state.etyInfo.website.substr(0,7).toLowerCase() == "http://" || me.state.etyInfo.website.substr(0,8).toLowerCase() == "https://" ? me.state.etyInfo.website:"http://" + me.state.etyInfo.website} target="_blank">{me.state.etyInfo.website}</a></p>}
                        {me.state.etyInfo.abstr && <p><span>企业简介：</span>{me.state.etyInfo.abstr}......</p>}
                      </div>}
                      {me.state.activeKey==='rnd' &&
                      <div className={style['ety-info']}>
                        {me.state.etyInfo.full_name && <p><span>名称：</span>{me.state.etyInfo.full_name}</p>}
                        {me.state.etyInfo.offical_code && <p><span>学校标识码：</span>{me.state.etyInfo.offical_code}</p>}
                        {me.state.etyInfo.superior && <p><span>主管：</span>{me.state.etyInfo.superior}</p>}
                        {me.state.etyInfo.area && <p><span>所在地：</span>{me.state.etyInfo.area}</p>}
                        {me.state.etyInfo.web && <p><span>网址：</span><a href={me.state.etyInfo.web.substr(0,7).toLowerCase() == "http://" || me.state.etyInfo.web.substr(0,8).toLowerCase() == "https://" ? me.state.etyInfo.web:"http://" + me.state.etyInfo.web} target="_blank">{me.state.etyInfo.web}</a></p>}
                        {me.state.etyInfo.brief && <p><span>企业简介：</span>{me.state.etyInfo.brief}......</p>}
                      </div>}
                      {me.state.activeKey==='who' && me.state.etyInfo && me.state.etyInfo.length>0 && me.state.etyInfo.map((info, info_index) => (
                      <div className={style['ety-info-li']}>
                        <div className={style['ety-info']}>
                          {info.fname && <p className={style['ety-info-title']}>{info.fname} {info.title}</p>}
                          {info.reward && <p><span>荣誉：</span>{info.reward}</p>}
                          {info.trade && <p><span>领域：</span>{info.trade}</p>}
                          {info.project && <p><span>重大项目：</span>{info.project}</p>}
                          {info.kws && <p><span>关键词：</span>{info.kws}</p>}
                          {info.sex && <p><span>性别：</span>{info.sex}</p>}
                          {info.birth_area && <p><span>出生地：</span>{info.birth_area}</p>}
                          {info.edu_2||info.edu_3||info.edu_4||info.edu_5 && <p><span>毕业学校：</span>{info.edu_2||info.edu_3||info.edu_4||info.edu_5}</p>}
                          {info.degr && <p><span>学历学位：</span>{info.degr}</p>}
                          {info.workunit && <p><span>单位：</span>{info.workunit}</p>}
                          {info.web_url && <p><span>详情地址：</span>{info.web_url}</p>}
                          {info.abstr && <p><span>简介：</span>{info.abstr}......</p>}
                        </div>
                      </div>))}
                      {me.state.activeKey==='evnt' && me.state.etyInfo.id_origin==='' &&
                      <div className={style['ety-info']}>
                        {me.state.etyInfo.src && <p><span>类型：</span>{me.cpsSrcNames[me.state.etyInfo.src]}</p>}
                        {me.state.etyInfo.title && <p><span>名称：</span>{me.state.etyInfo.title}</p>}
                        {me.state.etyInfo.src===300 && me.state.etyInfo.other1 && <p><span>专利号：</span>{me.state.etyInfo.other1}</p>}
                        {me.state.etyInfo.src===500 && me.state.etyInfo.other3 && <p><span>著作权编号：</span>{me.state.etyInfo.other3}</p>}
                        {(me.state.etyInfo.entity1 || me.state.etyInfo.entity2) && <p><span>相关主体：</span>{me.state.etyInfo.entity1} {me.state.etyInfo.entity2}</p>}
                        {me.state.etyInfo.keyword && <p><span>关键词：</span>{me.state.etyInfo.keyword}</p>}
                        {me.state.etyInfo.detail && <p><span>简介：</span>{me.state.etyInfo.detail}......</p>}
                      </div>}
                      {me.state.activeKey==='evnt' && me.state.etyInfo.id_origin && me.state.etyInfo.id_origin>0 &&
                      <div className={style['ety-info']}>
                        {me.state.etyInfo.fname && <p><span>名称：</span>{me.state.etyInfo.fname}</p>}
                        {me.state.etyInfo.keyword && <p><span>关键词：</span>{me.state.etyInfo.keyword}</p>}
                        {me.state.etyInfo.description && <p><span>简介：</span>{me.state.etyInfo.description}......</p>}
                      </div>}

                      {(me.state.activeKey!=='who' || (me.state.activeKey==='who' && !(me.state.etyInfo && me.state.etyInfo.length>0))) && me.state.etyEventList && me.state.etyEventList.length>0 &&
                      <>
                        <div className={style['ety-info-title']}>相关事件：</div>
                        <div className={style['ety-info-event']}>
                        {me.state.etyEventList.map((item, index) => (
                          <p onClick={e => {me.doClickEvent(e,item);}}>{index+1}. {item.title}</p>))
                        }
                        </div>
                      </>
                      }
                    </>}
                    {me.state.etyStatus === 'failed' && <>
                      <div className={style['market-drawer-body-tab-content-empty']}>
                        <Icon name={'exclamation-circle'} theme={'outlined'} /><br />
                        <span>{NODATAMSG}</span><br />
                      </div>
                    </>}
                    {me.state.etyStatus === 'idle' && <>
                      <div className={style['market-drawer-body-tab-content-loading']}>
                        <Icon name={'loading'} theme={'outlined'} /><br />
                        数据加载中，请稍后…
                      </div>
                    </>}
                    </div>
                  </div>
                </div>
                <div className={style['action-box']}>
                  <div className={style['action-left']}>
                    <Icon name="icon-email" type={IconTypes.ICON_FONT} className={style['icon-img-right']}/>定制您的专属扩展库请联系 <a href="mailto:hi@joinmap.ai?subject=定制专属扩展库">hi@joinmap.ai</a>
                  </div>
                  <div className={style['action-right']}>
                    <Button type={'default'} onClick={me.onEtyClose}>返回</Button>
                  </div>
                </div>
              </div>
            </Drawer>
          ) : null
        }
        {
          me.state.showCpsDrawer && me.state.cpsInfo ? (
            <Drawer
              visible={me.props.visible}
              placement={'right'}
              destroyOnClose={true}
              mask={true}
              maskClosable={true}
              maskStyle={{backgroundColor: 'transparent'}}
              width={'44rem'}
              className={`${style['market-drawer-wrap']} ${style['market-drawer-wrap-cps-info']}`}
              onClose={me.onCpsClose}
              style={{zIndex:2000,opacity:1}}
            >
              <div style={{ height: '100%',backgroundColor:'#fff',opacity:1}}>
                <div className={style['market-drawer-header']}>
                  <div className={style['market-drawer-title']} style={{flex:'auto'}}>
                    <Icon name="icon-flash-lamp-o" theme="outlined" type={IconTypes.ICON_FONT} className={style['icon-img-right']}/>{me.state.cpsInfo.title}
                  </div>
                  <div className={style['market-drawer-right']}>
                  </div>
                </div>
                <div className={style['market-drawer-body']}>
                  <div className={style['market-drawer-body-tabs']}>
                    <div className={`${style['market-drawer-body-tab-content']} scrollbar scrollbar-none`}>
                      <div className={style['ety-info']}>
                        {(me.state.cpsInfo.id_publish) && <p>[{me.state.cpsInfo.id_publish}]{me.state.cpsInfo.title}</p>}
                        {(me.state.cpsInfo.pt_apply) && <p>{me.state.cpsInfo.pt_apply}</p>}
                        {(me.state.cpsInfo.pt_invent) && <p>{me.state.cpsInfo.pt_invent}</p>}
                        {me.state.cpsInfo.class_ipc && <p>{me.state.cpsInfo.class_ipc}</p>}
                        {(me.state.cpsInfo.prov_apply||me.state.cpsInfo.city_apply||me.state.cpsInfo.dist_apply) && <p>{me.state.cpsInfo.prov_apply}{me.state.cpsInfo.city_apply}{me.state.cpsInfo.dist_apply}</p>}
                        {me.state.cpsInfo.addr_apply && <p>{me.state.cpsInfo.addr_apply}</p>}
                        {me.state.cpsInfo.kws && <p>{me.state.cpsInfo.kws}</p>}
                        {me.state.cpsInfo.abstr && <p>{me.state.cpsInfo.abstr}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={style['action-box']}>
                  <div className={style['action-left']}>
                    <Icon name="icon-email" type={IconTypes.ICON_FONT} className={style['icon-img-right']}/>定制您的专属扩展库请联系 <a href="mailto:hi@joinmap.ai?subject=定制专属扩展库">hi@joinmap.ai</a>
                  </div>
                  <div className={style['action-right']}>
                    <Button type={'default'} onClick={me.onCpsClose}>返回</Button>
                  </div>
                </div>
              </div>
            </Drawer>
          ) : null
        }
        {this.state.showModal?(
          <Modal
            title={<div><div className={style['header-back']} onClick={me.handleCancel}><Icon name={'left'}/> 返回</div>
                <div className={style['header-title']}><Icon type={IconTypes.ICON_FONT} name={'icon-database'} className={style['icon-img-right']} /> {me.state.modalTitle}</div>
                </div>}
            centered={true}
            visible={true}
            //confirmLoading={this.state.modalLoading}
            wrapClassName={style['dataset-modal']}
            zIndex={2000}
            width={'100%'}
            onCancel={this.handleCancel}
            style={{opacity:1}}
            footer={null}
          >
            {!this.state.modalLoading && me.state.datasetDetail.columns && me.state.datasetDetail.columns.length>0 && 
              <Table columns={me.state.datasetDetail.columns} dataSource={me.state.datasetDetail.dataSource} scroll={{y:'max-content'}} pagination={false}/>}
            {!this.state.modalLoading && !(me.state.datasetDetail.columns && me.state.datasetDetail.columns.length>0) && <div className={style['market-drawer-body-tab-content-empty']}>
                <Icon name={'exclamation-circle'} theme={'outlined'} /><br />
                <span>没有找到相关信息,请稍后再试</span><br />
              </div>}
            {this.state.modalLoading && <div className={style['market-drawer-body-tab-content-loading']}>
                <Icon name={'loading'} theme={'outlined'} /><br />
                数据加载中，请稍后…
              </div>}
          </Modal>): null}
          {me.state.showDrawer && me.state.showTextSearch && 
            <div className={style['text-search-box']} style={{left:me.state.showTextSearchBox.x+'px',top:me.state.showTextSearchBox.y+'px'}}>
              {me.state.selectionText} <Icon name="icon-search" theme="outlined" type={IconTypes.ICON_FONT} onClick={() => me.onSelectionTextSearch()}/>
            </div>
          }
      </React.Fragment>
    );
  }
}

InputSearchFromDB.defaultProps = {
  bus: PB,
};

InputSearchFromDB.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewId: PropTypes.string,
  viewName: PropTypes.string,
  nodeName: PropTypes.string,
};

export default InputSearchFromDB;