import React from 'react';
//import Icon from "@/components/common/common.icon";
import { Menu,Icon } from 'antd';
const { SubMenu } = Menu;
class App extends React.Component {
  state = {
    current: '1',
  };

  handleClick = e => {
    console.log('click ', e);
    this.setState({
      current: e.key,
    });
  };

  render() {
    return (
      <Menu
          theme={'dark'}
          onClick={this.handleClick}
          style={{ width: 356,fontSize:'16px' }}
          defaultOpenKeys={['sub1','sub2','sub3','sub4',"sub1-1","sub1-2","sub2-1","sub2-2","sub2-3","sub2-4","sub3-1","sub3-2","sub3-3","sub3-4"]}
          selectedKeys={[this.state.current]}
          mode="inline"
          inlineCollapsed={false}
          inlineIndent={30}
        >
          <SubMenu key="sub1" title={<span style={{fontSize:'18px' }}><Icon type="pie-chart" /><span>新型数字产业集群</span></span>}>
            <SubMenu key="sub1-1" title={<span style={{fontSize:'16px' }}>数字产业化</span>}>
              <Menu.Item key="sub1-1-1"><span style={{fontSize:'14px' }}>电子信息制造业</span></Menu.Item>
              <Menu.Item key="sub1-1-2"><span style={{fontSize:'14px' }}>信息通信业</span></Menu.Item>
              <Menu.Item key="sub1-1-3"><span style={{fontSize:'14px' }}>互联网行业</span></Menu.Item>
              <Menu.Item key="sub1-1-4"><span style={{fontSize:'14px' }}>软件服务业</span></Menu.Item>
            </SubMenu>
            <SubMenu key="sub1-2" title={<span style={{fontSize:'16px' }}>产业数字化</span>}>
              <Menu.Item key="sub1-2-1">农业数字化</Menu.Item>
              <Menu.Item key="sub1-2-2">共业数字化</Menu.Item>
              <Menu.Item key="sub1-2-3">服务业数字化</Menu.Item>
            </SubMenu>
          </SubMenu>
          <SubMenu key="sub2" title={<span style={{fontSize:'18px' }}><Icon type="appstore" /><span>生物医药集群</span></span>}>
            <SubMenu key="sub2-1" title={<span style={{fontSize:'16px' }}>生物技术药物</span>}>
              <Menu.Item key="sub2-1-1">治疗药物</Menu.Item>
              <Menu.Item key="sub2-1-2">预防药物</Menu.Item>
              <Menu.Item key="sub2-1-3">诊断药物</Menu.Item>
              <Menu.Item key="sub2-1-4">其他生物医药用品</Menu.Item>
            </SubMenu>
            <SubMenu key="sub2-2" title={<span style={{fontSize:'16px' }}>生物制品</span>}>
              <Menu.Item key="sub2-2-1">天然生物药物</Menu.Item>
              <Menu.Item key="sub2-2-2">生物合成药物</Menu.Item>
              <Menu.Item key="sub2-2-3">生物仿制药</Menu.Item>
            </SubMenu>
            <SubMenu key="sub2-3" title={<span style={{fontSize:'16px' }}>医疗器械</span>}>
              <Menu.Item key="sub2-3-1">一类医疗器械</Menu.Item>
              <Menu.Item key="sub2-3-2">二类医疗器械</Menu.Item>
              <Menu.Item key="sub2-3-3">三类医疗器械</Menu.Item>
            </SubMenu>
            <SubMenu key="sub2-4" title={<span style={{fontSize:'16px' }}>生物医药服务</span>}>
              <Menu.Item key="sub2-4-1">技术服务</Menu.Item>
              <Menu.Item key="sub2-4-2">管理服务</Menu.Item>
              <Menu.Item key="sub2-4-3">信息服务</Menu.Item>
            </SubMenu>
          </SubMenu>
          <SubMenu key="sub3" title={<span style={{fontSize:'18px' }}><Icon type="pie-chart" /><span>高端新材料集群</span></span>}>
            <SubMenu key="sub3-1" title={<span style={{fontSize:'16px' }}>新型金属材料集群</span>}>
              <Menu.Item key="sub3-1-1">稀土材料集群</Menu.Item>
              <Menu.Item key="sub3-1-2">镍钴材料集群</Menu.Item>
              <Menu.Item key="sub3-1-3">钛材料集群</Menu.Item>
            </SubMenu>
            <SubMenu key="sub3-2" title={<span style={{fontSize:'16px' }}>先进无机非金属材料集群</span>}>
              <Menu.Item key="sub3-2-1">陶瓷材料集群</Menu.Item>
              <Menu.Item key="sub3-2-2">玻璃材料集群</Menu.Item>
              <Menu.Item key="sub3-2-3">半导体材料集群</Menu.Item>
            </SubMenu>
            <SubMenu key="sub3-3" title={<span style={{fontSize:'16px' }}>高性能高分子材料集群</span>}>
              <Menu.Item key="sub3-3-1">碳链高分子集群</Menu.Item>
              <Menu.Item key="sub3-3-2">杂链高聚物集群</Menu.Item>
              <Menu.Item key="sub3-3-3">元素有机高聚物集群</Menu.Item>
            </SubMenu>
            <SubMenu key="sub3-4" title={<span style={{fontSize:'16px' }}>生物医用材料集群</span>}>
              <Menu.Item key="sub3-4-1">惰性生物医用材料集群</Menu.Item>
              <Menu.Item key="sub3-4-2">活性生物医用材料集群</Menu.Item>
              <Menu.Item key="sub3-4-3">可降解和吸收的生物医用材料集群</Menu.Item>
            </SubMenu>
          </SubMenu>
        </Menu>
    );
  }
}

export default App;